import React, { useState, useRef, useEffect } from 'react';
import { IonCheckbox } from '@ionic/react';
import cx from 'classnames';

interface ContainerProps {
  onChange?: Function;
  onClick?: Function;
  checked?: boolean;
  name?: string;
  label: string;
  className?: string;
  error?: string[] | never[] | undefined;
}

const Checkbox: React.FC<ContainerProps> = ({
  onChange,
  onClick,
  checked,
  name,
  label,
  className,
  error,
}) => {
  const errors = error?.length ?? 0;

  return (
    <div
      className={className}
      onClick={(event) => (onClick ? onClick(event) : null)}
    >
      <div className="flex items-center space-x-3 custom-checkbox">
        <IonCheckbox
          mode="md"
          name={name}
          className={cx({
            fail: errors > 0,
          })}
          checked={checked}
          onIonChange={(e) =>
            onChange
              ? onChange({
                  target: {
                    name: name,
                    value: !checked,
                  },
                })
              : ''
          }
        />
        <label className="text-sm text-gray-100">{label}</label>
      </div>
    </div>
  );
};

export default Checkbox;

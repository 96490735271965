import { Api } from '../api';

export type Badge = {
  id: string;
  name: string;
  image: string;
  description: string;
  created_at?: string;
};

export const getBadges = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'badge', auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

export const attachBadge = async (id: string): Promise<any> => {
  const api = new Api();
  try {
    const response = await api.request({
      type: 'PUT',
      endpoint: `badge/${id}`,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import react, { useState } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonRouterLink,
  IonButton,
  IonAlert,
  IonToast,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/form/Input';
import { useUser, defaultUser } from '../../../context/User';
import { useAuth } from '../../../context/Auth';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import { deleteUser, saveSettings, getUser } from '../../../data/user';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const { Storage } = Plugins;
  const history = useHistory();
  const { setAuthToken } = useAuth();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [name, setName] = useState<string>(user.name);
  const [phone, setPhone] = useState<string>(user.phone);
  const [settings, setSettings] = useState<string>('');

  const handleDeleteUser = () => {
    deleteUser().then(() => {
      setUser(defaultUser);
      Storage.remove({ key: 'token' });
      setAuthToken('');
      history.push('/auth/login');
    });
  };

  const handleSaveSettings = () => {
    if ((user.demo ?? false) === true) {
      return;
    }

    saveSettings({
      name,
      phone,
    })
      .then(() => {
        return getUser();
      })
      .then((response) => {
        setUser(response);
        setSettings('success');
      })
      .catch(() => setSettings('error'));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" defaultHref="/user/academy" />
          </IonButtons>
          <IonTitle>{t('more.profile.tabTitle')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={settings === 'error' || settings === 'success'}
        onDidDismiss={() => setSettings('')}
        message={
          settings === 'error'
            ? t('more.profile.error') ?? ''
            : t('more.profile.success') ?? ''
        }
        duration={2000}
      />
      <IonContent>
        <div className="max-w-xl px-4 mx-auto mt-6">
          <h2 className="text-xl font-bold text-gray-100">
            {t('auth.login.title')}
          </h2>
          <Input
            className="mt-4"
            type="text"
            label={t('more.profile.name')}
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />
          <Input
            className="mt-4"
            type="text"
            label={t('more.profile.email')}
            value={user.email}
            disabled
          />
          <Input
            className="mt-4"
            type="text"
            label={t('more.profile.phone')}
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
          />
          <IonButton
            mode="ios"
            expand="block"
            className="mt-8"
            onClick={() => handleSaveSettings()}
          >
            {t('more.profile.save')}
          </IonButton>
          <IonAlert
            isOpen={confirmDelete}
            onDidDismiss={() => {
              setConfirmDelete(false);
            }}
            header={t('more.profile.noticeTitle') ?? ''}
            buttons={[
              {
                text: t('more.profile.noticeConfirm'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: t('more.profile.noticeReject'),
                handler: () => {
                  handleDeleteUser();
                },
              },
            ]}
          />
          <div className="flex flex-col items-center pt-6 mt-6 border-t border-brown-35">
            <div
              className="text-sm font-medium text-orange-100 cursor-pointer"
              onClick={() => setConfirmDelete(true)}
            >
              {t('more.profile.delete')}
            </div>
            <div className="mt-3 text-sm font-bold text-center text-gray-50">
              <p>{t('more.profile.deleteNotice')}</p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Profile;

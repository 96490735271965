import { Api } from '../api';

type inputParams = {
  token: string;
};

type inputParamsGet = {
  phone?: string;
  email?: string;
};

export type GetInviteResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getInvite = async (params: inputParams): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: `invite/${params.token}` });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInviteCode = async (params: inputParamsGet): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: `invite`,
      auth: true,
      body: params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

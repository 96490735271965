import { CityState } from './city.state';
import { CityActions } from './city.api';

export function cityReducer(state: CityState, action: CityActions): CityState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        status: 'loading',
      };
    case 'RESOLVE':
      return {
        ...state,
        status: 'success',
        data: action.payload,
      };
    case 'REJECT':
      return {
        ...state,
        status: 'error',
      };
    default:
      return state;
  }
}

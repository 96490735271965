import { Api } from '../api';

export const spinAvailable = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'wheel', auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

export const spinWheel = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: 'wheel',
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import classNames from 'classnames';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';

interface CardProps {
  image?: string;
  logo?: string;
  title?: string;
  value?: number;
  userPoints?: number;
  valueSale?: number | null;
  quote?: string;
  notice?: string;
  date?: string;
  dateOrder?: string;
  address?: string;
  name?: string;
  options?: {
    tar: string;
    format?: string;
  };
  price?: number | string;
  className?: string;
  type?: string;
}

const Card: React.FC<CardProps> = ({
  image,
  logo,
  title,
  value,
  userPoints,
  valueSale,
  quote,
  notice,
  options,
  price,
  className,
  date,
  dateOrder,
  name,
  address,
  type,
}) => {
  const { t } = useTranslation();
  const calculatePoints = () => {
    if (
      userPoints !== undefined &&
      valueSale !== undefined &&
      valueSale !== null &&
      valueSale > userPoints
    ) {
      return (
        t('rewards.notEnoughPoints') +
        ' ' +
        (valueSale - userPoints) +
        ' ' +
        t('general.points')
      );
    } else if (
      userPoints !== undefined &&
      value !== undefined &&
      valueSale === null &&
      value > userPoints
    ) {
      return (
        t('rewards.notEnoughPoints') +
        ' ' +
        (value - userPoints) +
        ' ' +
        t('general.points')
      );
    } else {
      return t('rewards.enoughPoints');
    }
  };
  return (
    <div
      className={classNames(
        'relative shadow rounded-lg overflow-hidden w-full h-full',
        className
      )}
    >
      {type === 'reward' && (
        <div className="flex flex-col h-full">
          <div className="w-full overflow-hidden">
            <div className="absolute h-6 p-1 overflow-hidden rounded top-1 left-1">
              <img src={logo} className="relative z-10 h-full mx-auto" />
              <div className="absolute top-0 left-0 z-0 w-full h-full bg-white opacity-60" />
            </div>
            <img
              src={image}
              className="object-contain"
              style={{ width: '100%', height: '88px' }}
            />
          </div>
          <div className="flex-grow p-2 text-left border-t rtl:text-right border-brown-20">
            <h3 className="mt-1 text-sm font-bold text-gray-100">{title}</h3>
            <div className="flex items-center mt-1">
              <IonIcon src="assets/coin.svg" class="ion hydrated text-sm" />
              <span
                className={classNames(
                  'ml-1 text-sm text-gray-50 font-medium',
                  valueSale ? ' line-through' : ''
                )}
              >
                {value} {t('general.points')}
              </span>
              {valueSale && (
                <span className="ml-1 text-sm font-medium text-orange-100">
                  {valueSale} {t('general.points')}
                </span>
              )}
            </div>
            <p className="mt-2 text-xs text-gray-75 font-slab">
              {' '}
              {calculatePoints()}
            </p>
          </div>
          {notice && (
            <div className="w-full px-3 py-1 text-xs font-medium text-center text-white bg-orange-100">
              {notice}
            </div>
          )}
        </div>
      )}

      {type === 'product' && (
        <div>
          <div className="w-full overflow-hidden">
            <img
              src={image}
              className="object-contain"
              style={{ width: '100%', height: '88px' }}
            />
          </div>
          <div className="p-2 text-left border-t rtl:text-right border-brown-20">
            <h3 className="mt-1 text-sm font-bold text-gray-100">{title}</h3>
            <div className="grid grid-cols-2 mt-2 text-sm gap-y-1">
              {typeof price === 'number' && price > 0 && (
                <span className="text-gray-50">
                  {t('products.single.price')}
                </span>
              )}
              {typeof price === 'number' && price > 0 && (
                <span className="font-bold text-left text-orange-100 rtl:text-right">
                  {price.toLocaleString('sl-SI', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + ' €'}
                </span>
              )}
              {options?.tar && (
                <span className="text-gray-50">{t('products.single.tar')}</span>
              )}
              {options?.tar && (
                <span className="font-bold text-left text-gray-100 rtl:text-right ">
                  {options.tar + ' mg'}
                </span>
              )}
              {options?.format && (
                <span className="text-gray-50">
                  {t('products.single.format')}
                </span>
              )}
              {options?.format && (
                <span className="font-bold text-gray-100 ">
                  {options.format}
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      {type === 'article' && (
        <div>
          <div className="w-full overflow-hidden">
            <img src={image} className="mx-auto" style={{ height: '88px' }} />
          </div>
          <div className="p-2 text-left border-t rtl:text-right border-brown-20">
            <p className="text-sm text-gray-50">{date}</p>
            <h3 className="mt-1 text-sm font-bold text-gray-100">{title}</h3>
          </div>
        </div>
      )}

      {type === 'brand' && (
        <div>
          <div className="w-full h-10 my-6 overflow-hidden">
            <img src={image} className="mx-auto" />
          </div>
          {/* <div className="p-2 text-left border-t border-brown-20">
            <p className="py-1 m-auto text-sm text-center text-gray-75 font-slab">
              {quote}
            </p>
          </div> */}
        </div>
      )}

      {type === 'order' && (
        <div>
          <div className="w-full overflow-hidden">
            <div className="absolute px-2 py-1 text-xs rounded top-1 left-1 bg-gray-5">
              {dateOrder}
            </div>
            <img
              src={image}
              className="mx-auto"
              style={{ width: '100%', height: '88px' }}
            />
          </div>
          <div className="p-2 text-left border-t border-brown-20">
            <h3 className="mt-1 text-sm font-bold text-gray-100">{title}</h3>
            <p className="w-full mt-1 text-sm truncate text-gray-50">
              {address}
            </p>
            <p className="text-sm text-gray-50">{name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;

import { attachBadge } from './badges.api';
import { getUser } from '../user';

// check if badge exists
export const checkBadge = async (id: string, badges: string[]) => {
  const user = await getUser();
  if (user.badges.find((badge: string) => badge === id)) return false;
  if (badges.find((badge) => badge === id)) return false;
  else return await attachBadge(id);
};

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Capacitor, Plugins } from '@capacitor/core';

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonRouterLink,
  IonIcon,
  IonHeader,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import Notice from '../../components/Notice';
import Card from '../../components/Card';
import CardChallengeBig from '../../components/CardChallengeBig';
import CardCta from '../../components/CardCta';
import BadgeToast from '../../components/BadgeToast';
import { useTranslation } from 'react-i18next';
import { notifications } from 'ionicons/icons';
import { getBrands } from '../../data/brands/brands.api';
import { Article, getArticles } from '../../data/article/article.api';
import { getRewards } from '../../data/rewards/rewards.api';
import { useUser } from '../../context/User';
import { getChallenge, Challenge } from '../../data/challenge/challenge.api';
import { formatDate } from '../../services/FormatDate';

import { getNotice, Notice as NoticeType } from '../../data/notice/notice.api';
import { Brand } from '../../data/brands/brands.api';
import { Reward } from '../../data/rewards/rewards.api';
import { getLevel, Level } from '../../data/levels/levels';
import classNames from 'classnames';
import { checkBadge } from '../../data/badges/badge';
import { Badge } from '../../data/badges/badges.api';
import { spinAvailable } from '../../data/wheel/wheel.api';
import { rtlLangs } from '../../App';

const { Storage } = Plugins;

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const { t, i18n } = useTranslation();

  const [brands, setBrands] = useState<Brand[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [notices, setNotices] = useState<NoticeType[]>([]);
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const [challenge, setChallenge] = useState<Challenge>();
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [level, setLevel] = useState<Level>();
  const [badge, setBadge] = useState<Badge>();
  const [wheel, setWheel] = useState<boolean>(false);
  const { user } = useUser();
  const noticeSlider = useRef<HTMLIonSlidesElement>(null);
  const [visited, setVisited] = useState<string[]>([]);

  useIonViewDidEnter(() => {
    getChallenge()
      .then((response) => setChallenge(response))
      .catch((error: any) => {
        setChallenge(undefined);
      });
    // spinAvailable()
    //   .then((response: any) => setWheel(true))
    //   .catch((error: any) => {
    //     setWheel(false);
    //   });
    getArticles().then((response) => setArticles(response));
    Storage.get({ key: 'visited' }).then((storage) => {
      if (storage.value) {
        setVisited(JSON.parse(storage.value) ?? []);
      }
    });
  });

  useEffect(() => {
    // TODO: Fetch read notices from storage
    // await Storage.get({ key: 'notices' });

    getBrands().then((response) => setBrands(response));
    getArticles().then((response) => setArticles(response.splice(0, 4)));
    getRewards({ filter: '' }).then((response) => {
      setRewards(response);
    });
    checkBadge('0be8f4a2-2448-466a-929e-754c51f7b375', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );

    const level = getLevel(user.points_total);
    if (level) {
      setLevel(level);
    }

    if (
      user.settings &&
      ((user.settings.push_notifications && Capacitor.isNative) ||
        user.settings.email_notifications ||
        user.settings.sms_notifications)
    ) {
      // do nothing
    } else {
      if (!notices.find((notice) => notice.id === 0)) {
        setNotices([
          ...notices,
          {
            id: 0,
            title: 'dashboard.notice.title',
            text: 'dashboard.notice.text',
            cta: 'dashboard.notice.cta',
            link: '/user/more/settings',
          },
        ]);
      }
    }

    // Get latest notice from API
    // TODO: Ion slider does not refresh when adding/removing notices, so this is commented out
    // getNotice().then((notice) => {
    //   if (
    //     Object.keys(notice).length !== 0 &&
    //     !notices.find((existing) => existing.id === notice.id)
    //   ) {
    //     setNotices((current) => [notice, ...current]);
    //   }
    // });
  }, []);

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  useEffect(() => {
    if (noticeSlider && noticeSlider.current) noticeSlider.current.update();
  }, [notices]);
  const handleRemoveNotice = (id: number, index: number) => {
    console.log(notices.filter((item) => item.id !== id));
    setNotices((current) => current.filter((item) => item.id !== id));
  };

  const data = {
    cardcta2: {
      image: '/assets/placeholders/phone.png',
      title: 'Glavne nagrade v vrednosti 700€!',
      text: 'Za koga? Za najbolj aktivne!',
    },
  };

  return (
    <IonPage>
      <BadgeToast
        badgeName={badge?.name}
        title={t('dashboard.badge.title')}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonHeader>
        <IonToolbar>
          <IonRouterLink routerLink="/user/more" slot="start">
            <div className="flex items-center px-4 pt-3 pb-2 text-gray-100">
              <IonIcon
                src="assets/coin-stroke.svg"
                class="ios hydrated text-3xl"
              ></IonIcon>
              <div className="ml-1 rtl:mr-1">
                <span className="font-bold">{user?.points}</span>
                <span className="ml-0.5 rtl:mr-0.5 text-sm text-gray-50">
                  {t('general.points')}
                </span>
              </div>
              <div className="flex items-center ml-4 rtl:mr-4">
                <IonIcon src={level?.img}></IonIcon>
                <span className="ml-1 text-sm rtl:mr-1 text-gray-50">
                  {t(level?.title ?? '')}
                </span>
              </div>
            </div>
          </IonRouterLink>
          <IonRouterLink
            slot="end"
            routerLink="/user/dashboard/notifications"
            className="relative mx-4 mt-3 mb-2"
          >
            <IonIcon
              icon={notifications}
              class="ios hydrated text-gray-75 text-2xl"
            ></IonIcon>
            <div
              className={classNames(
                'absolute top-0 mt-1 mr-1 right-0 flex items-center justify-center w-3 h-3 text-white transform translate-x-1/2 -translate-y-1/2 bg-orange-100 border border-white rounded-full text-xs',
                !articles.some((item) => visited.includes(item.id))
                  ? 'opacity-100'
                  : 'opacity-0'
              )}
            />
          </IonRouterLink>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* If there are any notices at all */}
        {notices.length > 0 && (
          <div className="px-4 pt-6 pb-4 bg-brown-20">
            <IonSlides
              options={{
                observeParents: true,
                observeSlideChildren: true,
                observer: true,
                freeMode: true,
                slidesPerView: notices.length > 1 ? 1.1 : 1,
                spaceBetween: 16,
              }}
              ref={noticeSlider}
              className="my-4 overflow-visible full-height"
            >
              {notices.map((notice, index) => (
                <IonSlide key={index} className="items-stretch h-auto">
                  <Notice
                    title={t(notice.title) ?? ''}
                    text={t(notice.text) ?? ''}
                    cta={t(notice.cta ?? '') ?? ''}
                    link={notice.link ?? ''}
                    className="w-full h-full bg-white"
                    onClose={() => handleRemoveNotice(notice.id, index)}
                  />
                </IonSlide>
              ))}
            </IonSlides>
          </div>
        )}

        {challenge && (
          <div
            className="px-4 pt-4 pb-6 bg-bottom bg-no-repeat bg-cover"
            style={{ backgroundImage: 'url(/assets/leaf-bg.svg)' }}
          >
            <div className="flex items-center justify-between mb-3 ">
              <h2 className="text-xl font-bold">
                {t('dashboard.challenge.title')}
              </h2>
              <IonRouterLink
                class="ios ion-activatable hydrated text-sm text-gray-50 font-medium"
                routerLink="/user/dashboard/challenges"
              >
                {t('dashboard.challenge.cta')}
              </IonRouterLink>
            </div>

            <IonRouterLink
              routerLink={`/user/challenges/intro/${challenge.id}`}
              className={classNames(
                !challenge.completed ? '' : 'pointer-events-none'
              )}
            >
              <CardChallengeBig
                image={challenge.campaign.image}
                quote={challenge.campaign.message ?? ''}
                points={challenge.points}
                start={formatDate(challenge.start, 'numeric')}
                end={formatDate(challenge.end, 'numeric')}
                completed={challenge.completed}
                className="bg-white"
              />
            </IonRouterLink>
          </div>
        )}
        <div className="px-4">
          {/* <CardCta
            image='/assets/placeholders/roulette.png'
            title={wheel ? t('dashboard.wheel.title') : t('dashboard.wheel.titleNA')}
            text={t('dashboard.wheel.text') }
            disabled={wheel ? false : true}
            link="/user/wheel"
            className="h-20 mt-10 bg-brown-20"
          /> */}
          <div className="flex justify-between mt-10 mb-3 ">
            <h2 className="text-xl font-bold">
              {t('dashboard.products.title')}
            </h2>
            <IonRouterLink
              class="ios ion-activatable hydrated rounded-md text-sm text-orange-100 bg-orange-5 py-1.5 px-2 font-medium"
              routerLink="/user/rewards"
            >
              {t('dashboard.products.cta')}
            </IonRouterLink>
          </div>

          <IonSlides
            options={{ freeMode: true, slidesPerView: 2.1, spaceBetween: 8 }}
            className="my-4 overflow-visible"
            dir={rtlLangs.includes(i18n.language) ? 'rtl' : 'ltr'}
          >
            {rewards.map((reward, index) => {
              return (
                <IonSlide key={reward.id}>
                  <IonRouterLink routerLink={'/user/rewards/' + reward.id}>
                    <Card
                      image={reward?.image}
                      logo={reward?.brand_image}
                      title={reward?.name}
                      value={reward?.value}
                      userPoints={user?.points}
                      notice={reward?.notice}
                      valueSale={reward?.value_sale}
                      type="reward"
                    />
                  </IonRouterLink>
                </IonSlide>
              );
            })}
          </IonSlides>

          {/* <CardCta
            image={data.cardcta2.image}
            title={data.cardcta2.title}
            text={data.cardcta2.text}
            className="h-24 mt-10 bg-brown-20"
          /> */}

          <div className="flex justify-between mt-10 mb-3 ">
            <h2 className="text-xl font-bold">{t('dashboard.brands.title')}</h2>
            <IonRouterLink
              class="ios ion-activatable hydrated rounded-md text-sm text-orange-100 bg-orange-5 py-1.5 px-2 font-medium"
              routerLink="/user/academy"
            >
              {t('dashboard.brands.cta')}
            </IonRouterLink>
          </div>
          <IonSlides
            options={{ freeMode: true, slidesPerView: 2.1, spaceBetween: 8 }}
            className="my-4 overflow-visible"
          >
            {brands.map((brand) => {
              return (
                <IonSlide key={brand.id}>
                  <IonRouterLink
                    routerLink={`/user/academy/brand/${brand.id}`}
                    className="w-full text-gray-100"
                  >
                    <Card image={brand.logo} quote={brand.name} type="brand" />
                  </IonRouterLink>
                </IonSlide>
              );
            })}
          </IonSlides>

          <div className="flex justify-between mt-10 mb-3 ">
            <h2 className="text-xl font-bold">{t('dashboard.news.title')}</h2>
            <IonRouterLink
              class="ios ion-activatable hydrated rounded-md text-sm text-orange-100 bg-orange-5 py-1.5 px-2 font-medium"
              routerLink="/user/academy/news"
            >
              {t('dashboard.news.cta')}
            </IonRouterLink>
          </div>
          <IonSlides
            options={{ freeMode: true, slidesPerView: 2.1, spaceBetween: 8 }}
            className="my-4 overflow-visible full-height"
          >
            {articles?.map((article) => {
              return (
                <IonSlide key={article.id} className="items-stretch h-auto">
                  <Link to={`/user/academy/news/${article.id}`}>
                    <Card
                      image={article.image}
                      title={article.title}
                      date={new Date(article.date).toLocaleDateString(
                        i18n.language,
                        {
                          month: 'long',
                          day: 'numeric',
                        }
                      )}
                      className="h-full"
                      type="article"
                    />
                  </Link>
                </IonSlide>
              );
            })}
          </IonSlides>
        </div>
        <div
          style={{
            background:
              'linear-gradient(0deg, #F7F5F3 0%, rgba(247, 245, 243, 0) 100%)',
          }}
        >
          <img src="/assets/imperial-logo.svg" className="pb-3 mx-auto mt-16" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;

import cx from 'classnames';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import { useState } from 'react';

interface QuizProp {
  question?: string;
  answers?: {
    correct: boolean;
    text: string;
    image?: string;
  }[];
  current: number;
  total: number;
  className?: string;
  onAnswer: Function;
}

const Quiz: React.FC<QuizProp> = ({
  question,
  answers,
  current,
  total,
  className,
  onAnswer,
}) => {
  const { t } = useTranslation();
  const [showAnswer, setShowAnswer] = useState<number>();
  const [disableClick, setDisableClick] = useState<boolean>(false);

  const handleAnswer = (correct: boolean, index: number) => {
    if (correct) setDisableClick(true);
    setShowAnswer(index);
    onAnswer(correct, showAnswer !== undefined);
  };

  return (
    <div
      className={cx(
        'px-4 py-2.5 bg-no-repeat bg-cover bg-top mx-2 grid',
        className,
        { 'pointer-events-none': disableClick }
      )}
      style={{ backgroundImage: 'url(/assets/leaf-quiz.svg)' }}
    >
      <div className="mx-auto rounded-lg bg-brown-35 px-2 py-0.5 text-base font-bold text-brown-100">
        {current}/{total}
      </div>
      <h3 className="mt-4 text-xl font-medium">{question}</h3>
      <div
        className={cx('mt-6', {
          'pointer-events-none':
            showAnswer && answers && answers[showAnswer].correct,
        })}
      >
        {answers?.map((answer, index) => {
          return (
            <div
              key={`answer${answer.text + index}`}
              className={cx(
                'flex items-center shadow rounded-lg px-4 py-3 text-base my-1.5 transition duration-200',
                {
                  'border border-green-100':
                    showAnswer === index && answer.correct,
                },
                {
                  'border border-red-100':
                    showAnswer === index && !answer.correct,
                }
              )}
              onClick={() => handleAnswer(answer.correct, index)}
            >
              {answer.image && <img className="h-12 mr-5" src={answer.image} />}
              <span className="flex-grow">{answer.text}</span>
              {showAnswer === index && answer.correct && (
                <IonIcon icon={checkmarkCircle} className="text-green-100" />
              )}
              {showAnswer === index && !answer.correct && (
                <IonIcon icon={closeCircle} className="text-red-100" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Quiz;

import { Api } from '../api';

export type Brand = {
  id: number;
  name: string;
  logo: string;
  lead: string;
  background: string;
  list?: {
    icon?: string;
    label?: string;
  }[];
  content?: string;
};

export const getBrands = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'brand', auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBrand = async (id: string): Promise<any> => {
  const api = new Api();
  try {
    const response = await api.request({ endpoint: `brand/${id}`, auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

import classNames from 'classnames';
import { IonIcon } from '@ionic/react';
import { caretDown, caretUp } from 'ionicons/icons';
import { useState, useEffect } from 'react';

interface SortingProps {
  title?: string;
  className?: string;
  onChange?: Function;
}

const Sorting: React.FC<SortingProps> = ({ title, onChange, className }) => {
  const [order, setOrder] = useState<string>('none');
  const changeOrder = () => {
    if (order === 'none') setOrder('asc');
    else if (order === 'asc') setOrder('desc');
    else if (order === 'desc') setOrder('none');
  };
  useEffect(() => {
    if (onChange) onChange(order);
  }, [order]);
  return (
    <div
      className={classNames('flex relative', className)}
      onClick={() => changeOrder()}
    >
      <span className="mr-2 font-medium font-sm text-gray-75">{title}</span>
      <div className="flex flex-col h-full text-xs">
        <IonIcon
          icon={caretUp}
          class={classNames('ion hydrated text-gray-75 -mb-px', {
            'text-orange-100': order === 'asc',
          })}
        ></IonIcon>
        <IonIcon
          icon={caretDown}
          class={classNames('ion hydrated text-gray-75 -mt-px', {
            'text-orange-100': order === 'desc',
          })}
        ></IonIcon>
      </div>
    </div>
  );
};

export default Sorting;

import { SalesPointState } from './salesPoint.state';
import { SalesPointActions } from './salesPoint.api';

export function salesPointReducer(
  state: SalesPointState,
  action: SalesPointActions
): SalesPointState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        status: 'loading',
      };
    case 'RESOLVE':
      return {
        ...state,
        status: 'success',
        data: action.payload,
      };
    case 'REJECT':
      return {
        ...state,
        status: 'error',
      };
    default:
      return state;
  }
}

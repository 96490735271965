import classNames from 'classnames';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { arrowForwardCircle, flaskOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

interface CardChallengeBigProps {
  image: string | null;
  quote?: string;
  start?: string;
  end?: string;
  points?: number;
  text?: string;
  completed?: boolean;
  className?: string;
}

const CardChallengeBig: React.FC<CardChallengeBigProps> = ({
  image,
  quote,
  start,
  end,
  points,
  text,
  completed = flaskOutline,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        ' shadow-lg rounded-lg border border-gray-10',
        className
      )}
    >
      <div className="flex py-3 pl-2 pr-4 border-b rtl:pr-2 rtl:pl-4 border-brown-20">
        {image !== null && (
          <img
            src={image}
            className="w-32 h-full border rounded-md border-gray-10 max-h-36"
          />
        )}

        <div className="flex flex-col justify-center flex-grow pl-2 rtl:pr-2">
          <IonIcon
            src="assets/laurel.svg"
            class="ion hydrated text-3xl text-center block mx-auto mt-3"
          />
          <p
            className={classNames(
              'mt-1 text-base text-center text-brown-100 font-slab',
              { 'flex-grow': !completed }
            )}
          >
            {quote}
          </p>
          {!completed ? (
            <div className="flex items-center justify-center mt-10">
              <span className="ml-1 text-sm rtl:mr-1 text-gray-50">
                {start} - {end}
              </span>
              <span className="mx-2 text-sm text-gray-50">·</span>
              <IonIcon src="assets/coin.svg" />
              <span className="ml-1 text-sm rtl:mr-1 text-gray-50">
                {points + ' ' + t('general.points')}
              </span>
            </div>
          ) : (
            <p className="mt-4 text-sm text-center text-gray-50">{text}</p>
          )}
        </div>
      </div>
      <div
        className={classNames(
          'py-2.5 border border-white rounded-b-lg flex items-center justify-center',
          !completed ? 'bg-orange-5' : ' bg-gray-5'
        )}
      >
        <span
          className={classNames(
            'text-sm',
            !completed ? 'text-orange-100' : 'text-gray-75'
          )}
        >
          {!completed
            ? t('dashboard.challenges.cta') +
              ' ' +
              points +
              ' ' +
              t('general.points')
            : t('dashboard.challenges.coming')}
        </span>
        {!completed && (
          <IonIcon
            icon={arrowForwardCircle}
            class="ios hydrated my-auto ml-2 rtl:mr-2 transform rtl:rotate-180 text-orange-100"
          />
        )}
      </div>
    </div>
  );
};

export default CardChallengeBig;

import react, { useEffect, useState } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonRouterLink,
  IonButton,
  IonAlert,
  IonToast,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/form/Input';
import { getCities, City } from '../../../data/city/city.api';
import Modal from '../../../components/Modal';
import InputSearch from '../../../components/form/InputSearch';
import { useUser, defaultUser } from '../../../context/User';
import { useAuth } from '../../../context/Auth';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import {
  getSalesPoints,
  getSalesPoint,
  SalesPoint,
} from '../../../data/salesPoint/salesPoint.api';
import {
  getKeyAccounts,
  KeyAccount,
} from '../../../data/keyAccount/keyAccount.api';
import { checkmarkCircle } from 'ionicons/icons';
import { deleteUser, saveSettings, getUser } from '../../../data/user';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const { Storage } = Plugins;
  const history = useHistory();
  const { setAuthToken } = useAuth();
  const [keyAccounts, setKeyAccounts] = useState<KeyAccount[]>([]);
  const [selectedKeyAccount, setSelectedKeyAccount] = useState<KeyAccount>();
  const [salesPoints, setSalesPoints] = useState<SalesPoint[]>([]);
  const [salesPoint, setSalesPoint] = useState<SalesPoint>();
  const [cities, setCities] = useState<City[]>([]);
  const [city, setCity] = useState<City>();
  const [showModal, setShowModal] = useState(false);
  const [showSalesPointModal, setShowSalesPointModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [name, setName] = useState<string>(user.name);
  const [settings, setSettings] = useState<string>('');

  const setSalesPointText = (sp?: SalesPoint) => {
    if (sp) {
      return `${sp.name} (${sp.address})`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    getKeyAccounts().then(async (response) => {
      setKeyAccounts(response);
      const currentSalespoint = await getSalesPoint(user.sales_point_id);
      const currentKeyAcc = response.find(
        (keyaccount: KeyAccount) =>
          keyaccount.id === currentSalespoint.key_account_id
      );
      setSelectedKeyAccount(currentKeyAcc);
      setCity(currentSalespoint.city);
      setSalesPoint(currentSalespoint);
    });
  }, []);

  useEffect(() => {
    if (city) setCity(undefined);

    if (selectedKeyAccount) {
      getCities({
        filter: { key_account_id: selectedKeyAccount.id },
        auth: false,
      }).then((response) => setCities(response));
    }
  }, [selectedKeyAccount]);

  useEffect(() => {
    if (salesPoint) setSalesPoint(undefined);
    if (city) {
      getSalesPoints({
        filter: {
          key_account_id: selectedKeyAccount?.id,
          city_id: city.id,
        },
      }).then((response) => {
        setSalesPoints(response);
      });
    }
  }, [city]);

  const handleDeleteUser = () => {
    if ((user.demo ?? false) === true) {
      return;
    }

    deleteUser().then(() => {
      setUser(defaultUser);
      Storage.remove({ key: 'token' });
      setAuthToken('');
      history.push('/auth/login');
    });
  };

  const handleSaveSettings = () => {
    if ((user.demo ?? false) === true) {
      return;
    }

    saveSettings({
      name,
      sales_point_id: salesPoint?.id,
    })
      .then(() => {
        return getUser();
      })
      .then((response) => {
        setUser(response);
        setSettings('success');
      })
      .catch(() => setSettings('error'));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/academy"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle>{t('more.profile.tabTitle')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={settings === 'error' || settings === 'success'}
        onDidDismiss={() => setSettings('')}
        message={
          settings === 'error'
            ? t('more.profile.error') ?? ''
            : t('more.profile.success') ?? ''
        }
        duration={2000}
      />
      <IonContent>
        <div className="px-4 mt-6">
          <div>
            <h2 className="text-xl font-bold text-gray-100">
              {t('auth.login.title')}
            </h2>
            <Input
              className="mt-4"
              type="text"
              label={t('more.profile.name')}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            <Input
              className="mt-4"
              type="text"
              label={t('more.profile.email')}
              value={user.email}
              disabled
            />
            <div className="mt-1 text-xs text-gray-50">
              <span>{t('more.profile.changeEmail')} </span>
              <IonRouterLink
                routerLink="/user/more/contact"
                className="underline text-gray-50"
              >
                {t('more.profile.support')}
              </IonRouterLink>
            </div>
            <Input
              className="mt-4"
              type="text"
              label={t('more.profile.phone')}
              value={user.phone ?? '041 812 123'}
              disabled
            />
            <h2 className="mt-8 text-xl font-bold text-gray-100">
              {t('more.profile.salespoint')}
            </h2>
            {selectedKeyAccount === undefined && (
              <div className="grid grid-cols-4 mt-4 gap-x-2 gap-y-3">
                {keyAccounts.map((item) => (
                  <div
                    key={item.id}
                    className="relative flex items-center justify-center p-3 transition-all duration-300 rounded-lg cursor-pointer bg-brown-20"
                    onClick={() => {
                      setSelectedKeyAccount(item);
                    }}
                  >
                    {item.name !== 'OSTALA PRODAJNA MESTA' && (
                      <img src={item.logo} className="w-full h-full" />
                    )}

                    {item.name === 'OSTALA PRODAJNA MESTA' && (
                      <div className="text-xs font-bold text-center text-brown-100">
                        {item.name}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {selectedKeyAccount !== undefined && (
              <div className="pb-4 border-b border-brown-20">
                <div className="grid grid-cols-4 mt-4 gap-x-2 gap-y-3">
                  <div className="relative flex items-center justify-center p-3 transition-all duration-300 border border-orange-100 rounded-lg">
                    {selectedKeyAccount?.name !== 'OSTALA PRODAJNA MESTA' && (
                      <img
                        src={selectedKeyAccount?.logo}
                        className="w-full h-full"
                      />
                    )}

                    {selectedKeyAccount?.name === 'OSTALA PRODAJNA MESTA' && (
                      <div className="text-xs font-bold text-center text-brown-100">
                        {selectedKeyAccount?.name}
                      </div>
                    )}
                    <IonIcon
                      icon={checkmarkCircle}
                      class="absolute top-0 right-0 mt-0.5 mr-0.5 text-orange-100 ios hydrated"
                    ></IonIcon>
                  </div>
                  <div
                    className="relative flex items-center justify-center p-3 text-xs text-center transition-all duration-300 rounded-lg cursor-pointer bg-brown-20"
                    onClick={() => setSelectedKeyAccount(undefined)}
                  >
                    {t('more.profile.showMore')}
                  </div>
                </div>
                <h2 className="mt-8 text-xl font-bold text-gray-100">
                  {t('more.profile.location')}
                </h2>

                <div
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <Input
                    value={city?.name || ''}
                    type="text"
                    label={t('auth.register.location.cityLabel')}
                    className="mt-4"
                  />
                </div>

                {city && (
                  <div
                    className="mt-4"
                    onClick={() => {
                      setShowSalesPointModal(true);
                    }}
                  >
                    <Input
                      value={salesPoint?.name || ''}
                      type="text"
                      label={t('auth.register.location.salesPointLabel')}
                      className="mt-4"
                    />
                  </div>
                )}
              </div>
            )}
            <IonButton
              mode="ios"
              expand="block"
              className="mt-8"
              onClick={() => handleSaveSettings()}
            >
              {t('more.profile.save')}
            </IonButton>
          </div>
          <IonAlert
            isOpen={confirmDelete}
            onDidDismiss={() => {
              setConfirmDelete(false);
            }}
            header={t('more.profile.noticeTitle') ?? ''}
            message={t('more.profile.noticeText') ?? ''}
            buttons={[
              {
                text: t('more.profile.noticeConfirm'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: t('more.profile.noticeReject'),
                handler: () => {
                  handleDeleteUser();
                },
              },
            ]}
          />
          <div className="flex flex-col items-center pt-6 pb-10 mt-16 border-t border-brown-35">
            <div
              className="text-sm font-medium text-orange-100 cursor-pointer"
              onClick={() => setConfirmDelete(true)}
            >
              {t('more.profile.delete')}
            </div>
            <div className="mt-3 text-sm font-bold text-center text-gray-50">
              <p>{t('more.profile.deleteNotice')}</p>
            </div>
          </div>
        </div>
      </IonContent>
      <Modal
        title={t('auth.register.location.cityLabel')}
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        onSelection={(selected) => {
          setCity({ id: Number(selected?.key), name: selected?.value });
          setShowModal(false);
        }}
        items={cities.map((city) => ({
          key: city.id,
          value: `${city.name} (${city.id})`,
        }))}
      />
      <Modal
        title={t('auth.register.location.salesPointLabel')}
        isOpen={showSalesPointModal}
        onDidDismiss={() => setShowSalesPointModal(false)}
        onSelection={(selected) => {
          if (selected?.key) {
            const _salespoint = salesPoints.find(
              (item) => item.id === selected.key
            );
            setSalesPoint(_salespoint);
            setShowSalesPointModal(false);
          }
        }}
        items={salesPoints.map((point) => ({
          key: point.id,
          value: setSalesPointText(point),
        }))}
      />
    </IonPage>
  );
};

export default Profile;

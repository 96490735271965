import { Api } from '../api';
import qs from 'qs';

export type Product = {
  id: number;
  name: string;
  description: string;
  image: string;
  price: number;
  options: {
    filter: string;
    format: string;
    tar: string;
    co: string;
    nicotine: string;
  };
  brand: {
    id: number;
    name: string;
    logo: string;
  };
};

interface Props {
  filter?: any;
}

export const getProducts = async ({ filter }: Props): Promise<any> => {
  const api = new Api();

  if (filter) {
    filter = '?' + qs.stringify({ filter }, { encode: false });
  }

  try {
    const response = await api.request({
      endpoint: 'product' + filter,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProduct = async (id: string): Promise<any> => {
  const api = new Api();
  try {
    const response = await api.request({
      endpoint: `product/${id}`,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FilterProps {
  title?: string;
  items: {
    title?: string;
    text?: string;
    image?: string;
    id?: string | number;
    logo?: string;
  }[];
  selected: object[];
  className?: string;
  onChange: Function;
}

const Filter: React.FC<FilterProps> = ({
  title,
  items,
  selected,
  onChange,
  className,
}) => {
  const toggleFilter = (item: { id?: string | number }) => {
    const index = selected.findIndex(
      (el: { id?: string | number }) => el.id === item.id
    );
    const selectedCopy = [...selected];
    if (index < 0) {
      selectedCopy.push(item);
    } else {
      selectedCopy.splice(index, 1);
    }
    onChange(selectedCopy);
  };
  const { t } = useTranslation();

  return (
    <div className={classNames(className)}>
      <div className="text-sm text-gray-50">{title}</div>
      <div
        className={classNames(
          'grid mt-1 gap-2 space-x-4',
          items.length < 4 ? 'grid-cols-3' : 'grid-cols-4'
        )}
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(
                'relative cursor-pointer flex flex-col items-center justify-center w-full border h-12  rounded-lg transition duration-200 ',
                selected.findIndex(
                  (el: { id?: number | string }) => el.id === item.id
                ) < 0
                  ? 'bg-brown-20 border-transparent'
                  : 'bg-white  border-orange-100'
              )}
              onClick={() => toggleFilter(item)}
            >
              {item.image && <img src={item.image} className="h-3 mb-2"></img>}

              {item.logo && <img src={item.logo} className="h-6"></img>}

              {item.title && (
                <span className="text-sm font-bold text-center text-gray-75">
                  {t(item.title)}
                </span>
              )}
              {item.text && (
                <span className="text-xs text-center text-gray-75">
                  {t(item.text)}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Filter;

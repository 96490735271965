import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonFooter,
} from '@ionic/react';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { passwordReset } from '../../data/passwordReset';
import qs from 'qs';

const PasswordReset: React.FC = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = useState<any>('');
  const [token, setToken] = useState<any>('');

  const PasswordResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t('validation.shortPassword'))
      .max(250, t('validation.long'))
      .required(t('validation.required')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation.passwordMatch'))
      .required(t('validation.required')),
  });

  useEffect(() => {
    const { email, token } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    setEmail(email ?? '');
    setToken(token ?? '');
  }, []);

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={PasswordResetSchema}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        setSubmitting(false);

        passwordReset({
          email,
          token,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
        })
          .then(() => {
            history.push('/auth/password-reset-completed');
          })
          .catch((error: any) => {
            setErrors(error.response.errors);
          });
      }}
    >
      {(formik) => (
        <Form>
          <IonPage>
            <IonHeader>
              <IonToolbar mode="ios">
                <IonButtons slot="start"></IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="flex flex-col h-full max-w-xl px-4 py-6 mx-auto">
                <div className="flex flex-col">
                  <h1 className="mt-4 text-3xl font-bold text-gray-100">
                    {t('auth.reset.title')}
                  </h1>
                  <p className="mt-2 text-base text-gray-75 font-slab">
                    {t('auth.reset.copy')}
                  </p>
                  <Input
                    type="password"
                    label={t('auth.reset.passwordLabel')}
                    className="mt-4"
                    error={
                      formik.touched.password && formik.errors.password
                        ? [formik.errors.password]
                        : []
                    }
                    {...formik.getFieldProps('password')}
                    onChange={formik.handleChange}
                  />
                  <Input
                    type="password"
                    label={t('auth.reset.passwordConfirmationLabel')}
                    className="mt-4"
                    error={
                      formik.touched.passwordConfirmation &&
                      formik.errors.passwordConfirmation
                        ? [formik.errors.passwordConfirmation]
                        : []
                    }
                    {...formik.getFieldProps('passwordConfirmation')}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="pb-4" />
              </div>
            </IonContent>
            <IonFooter mode="ios" className="max-w-xl mx-auto">
              <div className="flex w-full px-4 py-6 bg-white">
                <IonButton
                  mode="ios"
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="w-full"
                >
                  {t('auth.reset.cta')}
                </IonButton>
              </div>
            </IonFooter>
          </IonPage>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordReset;

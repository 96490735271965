import { Api } from '../api';
import qs from 'qs';

export type CityActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'RESOLVE'; payload: any };

export type City = {
  id: number;
  name?: string;
};

interface Props {
  auth: boolean;
  filter?: any;
}

export const getCities = async ({
  filter,
  auth = false,
}: Props): Promise<any> => {
  const api = new Api();

  if (filter) {
    filter = '?' + qs.stringify({ filter }, { encode: false });
  }

  try {
    const response = await api.request({ endpoint: 'city' + filter, auth });
    return response;
  } catch (error) {
    throw error;
  }
};

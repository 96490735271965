import { InviteState } from './invite.state';
import { InviteActions } from './invite.actions';

export function inviteReducer(
  state: InviteState,
  action: InviteActions
): InviteState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        status: 'loading',
      };
    case 'RESOLVE':
      return {
        ...state,
        status: 'success',
        ...(action.payload && { data: action.payload }),
      };
    case 'REJECT':
      return {
        ...state,
        status: 'error',
      };
    case 'CANCEL':
      return {
        ...state,
        status: 'idle',
      };
    default:
      return state;
  }
}

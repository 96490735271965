import { SupportActions, SupportState } from './support.api';

export function SupportReducer(
  state: SupportState,
  action: SupportActions
): SupportState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        status: 'loading',
      };
    case 'RESOLVE':
      return {
        ...state,
        status: 'success',
      };
    case 'REJECT':
      return {
        ...state,
        status: 'error',
      };
    case 'CANCEL':
      return {
        ...state,
        status: 'idle',
      };
    default:
      return state;
  }
}

import { Api } from '../api';

export type KeyAccountActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'RESOLVE'; payload: any };

export type KeyAccount = {
  id: number;
  name: string;
  logo: string;
};

export const getKeyAccounts = async (auth: boolean = false): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'key-account', auth });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getKeyAccount = async (id: number): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      endpoint: `key-account/${id.toString()}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import { useHistory } from 'react-router-dom';
import { useEffect, useReducer } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
  IonTitle,
  IonSkeletonText,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { checkmarkCircle } from 'ionicons/icons';
import { useStore } from '../../store/Registration';
import { getKeyAccounts } from '../../data/keyAccount/keyAccount.api';
import { keyAccountReducer } from '../../data/keyAccount/keyAccount.reducer';
import { defaultKeyAccountState } from '../../data/keyAccount/keyAccount.state';

const RegisterUserInformation: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [{ status, data }, dispatch] = useReducer(
    keyAccountReducer,
    defaultKeyAccountState
  );

  const { keyAccount, set, clear } = useStore();

  const handleSetKeyAccount = (id: number) => {
    if (id !== keyAccount) {
      set('keyAccount', id);
      clear('city');
      clear('salesPoint');
    }
  };

  useEffect(() => {
    dispatch({ type: 'FETCH' });

    getKeyAccounts()
      .then((response) => dispatch({ type: 'RESOLVE', payload: response }))
      .catch((e) => {
        dispatch({ type: 'REJECT' });
      });
  }, []);

  const handleNext = () => {
    history.push('/auth/register-location');
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <IonBackButton mode="md" defaultHref="/auth/invite-code" />
            </IonButtons>
            <IonTitle>{t('auth.register.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="flex flex-col h-full px-4 py-6">
            <h2 className="text-xl font-bold">
              {t('auth.register.location.keyAccountTitle')}
            </h2>
            <div className="grid grid-cols-4 mt-4 gap-x-2 gap-y-3">
              {status === 'loading' && (
                <>
                  <div className="overflow-hidden rounded-lg aspect-w-1 aspect-h-1">
                    <IonSkeletonText animated />
                  </div>
                  <div className="overflow-hidden rounded-lg aspect-w-1 aspect-h-1">
                    <IonSkeletonText animated />
                  </div>
                </>
              )}

              {status === 'success' && (
                <>
                  {data?.map((item) => (
                    <div
                      key={item.id}
                      className={cx(
                        'relative rounded-lg flex p-3 justify-center items-center border transition-all duration-300',
                        {
                          'border-orange-100': item.id === keyAccount,
                          'bg-brown-20 border-transparent':
                            item.id !== keyAccount,
                        }
                      )}
                      onClick={() => {
                        handleSetKeyAccount(item.id);
                      }}
                    >
                      {item.name !== 'OSTALA PRODAJNA MESTA' && (
                        <img src={item.logo} className="w-full h-full" />
                      )}

                      {item.name === 'OSTALA PRODAJNA MESTA' && (
                        <div className="font-bold text-center text-xs text-brown-100">
                          {item.name}
                        </div>
                      )}

                      {item.id === keyAccount && (
                        <IonIcon
                          icon={checkmarkCircle}
                          class="ios hydrated absolute top-0 right-0 mt-0.5 mr-0.5 text-orange-100"
                        ></IonIcon>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="pb-4" />
          </div>
        </IonContent>
        <IonFooter mode="ios">
          <div className="flex w-full px-4 py-6 bg-white">
            <IonButton
              disabled={!keyAccount}
              onClick={handleNext}
              mode="ios"
              className="w-full"
            >
              {t('general.nextCta')}
            </IonButton>
          </div>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default RegisterUserInformation;

import React, { useEffect, useReducer } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
} from '@ionic/react';
import Card from '../../../components/Card';
import { useTranslation } from 'react-i18next';
import { getArticles } from '../../../data/article/article.api';
import { articleReducer } from '../../../data/article/article.reducer';
import { defaultArticleState } from '../../../data/article/article.state';
import { Link } from 'react-router-dom';

const News: React.FC = () => {
  const [{ status, data }, dispatch] = useReducer(
    articleReducer,
    defaultArticleState
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch({ type: 'FETCH' });

    getArticles()
      .then((response) => dispatch({ type: 'RESOLVE', payload: response }))
      .catch((e) => {
        dispatch({ type: 'REJECT' });
      });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/academy"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle>{t('academy.news.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="px-4">
        <div className="max-w-xl px-4 mx-auto mt-6">
          <div className="grid grid-cols-2 gap-x-2 gap-y-6">
            {status === 'loading' && (
              <>
                <div className="h-32 overflow-hidden rounded-lg">
                  <IonSkeletonText animated style={{ width: '100%' }} />
                </div>
                <div className="h-32 overflow-hidden rounded-lg">
                  <IonSkeletonText animated style={{ width: '100%' }} />
                </div>
              </>
            )}

            {status === 'success' &&
              data?.map((article) => {
                return (
                  <Link
                    key={article.id}
                    to={`/user/academy/news/${article.id}`}
                    className=""
                  >
                    <Card
                      image={article.image}
                      title={article.title}
                      date={new Date(article.date).toLocaleDateString(
                        i18n.language,
                        {
                          month: 'long',
                          day: 'numeric',
                        }
                      )}
                      type="article"
                    />
                  </Link>
                );
              })}
          </div>
        </div>

        <div className="mb-10"></div>
      </IonContent>
    </IonPage>
  );
};

export default News;

import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonModal,
  IonIcon,
  IonFooter,
  IonButton,
  IonRouterLink,
  useIonViewWillLeave,
} from '@ionic/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card';
import InputSearch from '../../components/form/InputSearch';
import Filter from '../../components/Filter';
import { funnel, arrowBack } from 'ionicons/icons';
import { getProducts } from '../../data/products/products.api';
import { getBrands } from '../../data/brands/brands.api';
import classNames from 'classnames';
import filters from '../../data/products/filters';
import { useUser } from '../../context/User';
import { checkBadge } from '../../data/badges/badge';
import { Badge } from '../../data/badges/badges.api';
import BadgeToast from '../../components/BadgeToast';
import Sorting from '../../components/Sorting';

import type { Brand } from '../../data/brands/brands.api';
import type { Product } from '../../data/products/products.api';

const Products = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
  const [filterTar, setFilterTar] = useState<{ value?: string[] }[]>([]);
  const [filterTip, setFilterTip] = useState<{ id?: string }[]>([]);
  const [filterFormat, setFilterFormat] = useState<{ id?: string }[]>([]);
  const [filterBrand, setFilterBrand] = useState<{ id?: number }[]>([]);
  const [activeFilters, setActiveFilters] = useState<number>(0);
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [order, setOrder] = useState<string>('');
  const { t } = useTranslation();
  const { user } = useUser();

  useEffect(() => {
    getProducts({ filter: { show_on_products: true } }).then((response) => {
      setProducts(response);
      setFilteredProducts(response);
    });
    getBrands().then((response) => {
      setBrands(response);
      filters.brand.items = response;
    });
  }, []);

  useEffect(() => {
    let newArray = [...products];
    if (filterTar.length > 0) {
      newArray = newArray.filter((product) => {
        return filterTar
          .map((filter) => {
            return filter.value;
          })
          .flat()
          .includes(product.options.tar);
      });
    }
    if (filterFormat.length > 0) {
      let filters = [...filterFormat].map((filter) => {
        return filter.id;
      });
      if (filterFormat.find((filter) => filter.id === '3')) filters.push('4');
      newArray = newArray.filter((product) => {
        return filters.includes(product.options.format);
      });
    }
    if (filterTip.length > 0) {
      newArray = newArray.filter((product) => {
        return filterTip
          .map((filter) => {
            return filter.id;
          })
          .includes(product.options.filter);
      });
    }
    if (filterBrand.length > 0) {
      newArray = newArray.filter((product) => {
        return filterBrand
          .map((filter) => {
            return filter.id;
          })
          .includes(product.brand.id);
      });
    }
    if (query.length > 0) {
      newArray = newArray.filter((product) => {
        return product.name.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (order === 'asc') {
      newArray.sort((a, b) => a.price - b.price);
    }
    if (order === 'desc') {
      newArray.sort((a, b) => b.price - a.price);
    }
    setFilteredProducts(newArray);

    setActiveFilters(
      [...filterBrand, ...filterFormat, ...filterTip, ...filterTar].length
    );
  }, [filterTar, filterFormat, filterBrand, filterTip, query, order]);

  const removeFilters = () => {
    setFilterTar([]);
    setFilterTip([]);
    setFilterBrand([]);
    setFilterFormat([]);
  };

  const getFormat = (id: string) => {
    return filters.format.items.find((item) => item.id === id)?.title;
  };

  const triggerBadge = () => {
    checkBadge('509bad82-0d07-4a77-85ef-d4de08beded3', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );
  };

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonHeader>
        <IonToolbar mode="ios" style={{ '--border-width': 0 }}>
          <div className="max-w-xl mx-auto">
            <div className="flex items-center py-3">
              <h1 className="flex-grow pl-4 text-3xl font-bold rtl:pr-4">
                {t('products.tabTitle')}
              </h1>
              <div
                className="relative p-2 mr-4 -mt-2 cursor-pointer rtl:ml-4"
                onClick={() => {
                  setShowModal(true);
                  triggerBadge();
                }}
              >
                <IonIcon
                  icon={funnel}
                  slot="end"
                  class="ion hydrated text-gray-75 text-2xl"
                ></IonIcon>
                <div
                  className={classNames(
                    'absolute top-0 mt-2 mr-2 rtl:ml-2 right-0 flex items-center justify-center w-4 h-4 text-white transform translate-x-1/2 -translate-y-1/2 bg-orange-100 border border-white rounded-full text-xs',
                    activeFilters > 0 ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  {activeFilters}
                </div>
              </div>
            </div>
            <div className="px-4 pb-3">
              <InputSearch
                value={query}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setQuery(e.currentTarget.value)
                }
              />
            </div>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="max-w-xl mx-auto">
          <div className="px-4 pb-4 mt-1 transition-all duration-200">
            <Filter
              title={t(filters.tar.title)}
              items={filters.tar.items}
              selected={filterTar}
              onChange={(item: object[]) => setFilterTar(item)}
            />
            <Filter
              className="mt-4"
              title={t(filters.format.title)}
              items={filters.format.items.slice(0, 4)}
              selected={filterFormat}
              onChange={(item: object[]) => {
                setFilterFormat(item);
              }}
            />
          </div>
          <div className="sticky top-0 z-10 border-b"></div>
          <Sorting
            title={t('products.sorting')}
            className="pl-4 mt-6"
            onChange={(e: string) => setOrder(e)}
          />
          <div className="px-4 pt-4 pb-6">
            <div className="grid grid-cols-2 gap-x-2 gap-y-6">
              {filteredProducts.map((product, index) => {
                return (
                  <IonRouterLink
                    key={product.id}
                    routerLink={`/${user.type}/products/${product.id}`}
                  >
                    <Card
                      image={product.image}
                      title={product.name}
                      price={product.price}
                      options={{
                        tar: product.options.tar,
                        format: getFormat(product.options.format),
                      }}
                      type="product"
                    />
                  </IonRouterLink>
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>

      <IonModal
        isOpen={showModal}
        onWillDismiss={() => setShowModal(false)}
        cssClass="searchModal"
      >
        <IonHeader>
          <IonToolbar>
            <div className="flex items-center px-4 pt-6 pb-3 ">
              <IonIcon
                icon={arrowBack}
                size="large"
                className="h-6 mr-2 transform rtl:ml-2 rtl:rotate-180"
                onClick={() => setShowModal(false)}
              ></IonIcon>
              <span>{t('products.filterTitle')}</span>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="px-4 pt-4 pb-8 space-y-4">
            <Filter
              title={t(filters.tar.title)}
              items={filters.tar.items}
              selected={filterTar}
              onChange={(item: object[]) => setFilterTar(item)}
            />
            <Filter
              title={t(filters.format.title)}
              items={filters.format.items.slice(0, 4)}
              selected={filterFormat}
              onChange={(item: object[]) => setFilterFormat(item)}
            />
            <Filter
              title={t(filters.brand.title)}
              items={filters.brand.items}
              selected={filterBrand}
              onChange={(item: object[]) => setFilterBrand(item)}
            />
            <Filter
              title={t(filters.tip.title)}
              items={filters.tip.items}
              selected={filterTip}
              onChange={(item: object[]) => setFilterTip(item)}
            />
          </div>
        </IonContent>
        <IonFooter className="flex items-center justify-between px-4 py-4 border-t border-gray-10">
          <span
            className="pl-4 text-base text-gray-50"
            onClick={() => removeFilters()}
          >
            {t('products.clearFilter')}
          </span>
          <IonButton
            className="flex-grow m-0 ml-12"
            onClick={() => setShowModal(false)}
          >
            {t('products.confirm')}{' '}
            {activeFilters > 0 ? '(' + activeFilters + ')' : ''}
          </IonButton>
        </IonFooter>
      </IonModal>
    </IonPage>
  );
};

export default Products;

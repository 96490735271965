import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface PuzzleProp {
  pieces: string[];
  className?: string;
  onAnswer?: Function;
}

const Puzzle: React.FC<PuzzleProp> = ({ pieces, className, onAnswer }) => {
  const { t } = useTranslation();
  const [shuffled, setShuffled] = useState<string[]>([]);
  const [solved, setSolved] = useState<string[]>([]);
  const board = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  const itemEls = useRef<HTMLDivElement[]>([]);

  const positions = [
    { top: '5%', left: '10%' },
    { top: '45%', left: '5%' },
    { top: '10%', right: '15%' },
    { bottom: '10%', left: '40%' },
    { top: '15%', right: '35%' },
    { top: '35%', right: '5%' },
  ];

  const targets = [
    { top: '0', left: '0' },
    { top: '0', left: '50%', transform: 'translateX(-50%)' },
    { top: '0', right: '0' },
    { bottom: '0', left: '0' },
    { bottom: '0', left: '50%', transform: 'translateX(-50%)' },
    { bottom: '0', right: '0' },
  ];

  const findCollision = (item1: any, item2: any) => {
    const rect1 = item1.getBoundingClientRect();
    const rect2 = item2.getBoundingClientRect();

    if (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    ) {
      return true;
    }
    return false;
  };

  function shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    if (board && board.current) setHeight(board.current.offsetWidth / 3);
    setShuffled(shuffleArray([...pieces]));
  }, []);

  const handleDragEnd = (e: any, current: any) => {
    let found = false;
    itemEls.current.forEach((item, index) => {
      if (findCollision(item, e.target) && index === pieces.indexOf(current)) {
        found = true;
      }
    });
    if (found) {
      setSolved((prev) => [...prev, current]);
    }
  };

  return (
    <div
      className={cx(
        ' py-2.5 bg-no-repeat bg-cover bg-top grid mx-2 px-2 rounded-lg my-4',
        className
      )}
      style={{
        background:
          'linear-gradient(180deg, #F7F5F3 0%, rgba(247, 245, 243, 0) 100%)',
      }}
    >
      <div
        style={{ backgroundImage: 'url(/assets/leaf-puzzle.svg)' }}
        className="p-10 -mx-2 bg-no-repeat"
      ></div>
      <div className="w-full rounded-lg bg-brown-35">
        <div
          ref={board}
          className="relative grid w-full h-56 grid-cols-3 grid-rows-2 overflow-hidden bg-white border-2 rounded-lg border-brown-35"
          style={{ height: `${height * 2}px` }}
        >
          {[...pieces].map((item, index) => {
            return (
              <div key={item} className="relative z-10 h-full">
                <div
                  ref={(el) => {
                    el !== null ? (itemEls.current[index] = el) : '';
                  }}
                  className="absolute w-1/4 h-1/4"
                  style={targets[index]}
                ></div>
                {solved.includes(item) && <img src={item} />}
              </div>
            );
          })}
          <div className="absolute z-0 w-full text-sm font-medium text-center transform -translate-y-1/2 top-1/2 text-gray-25">
            Primi kos sestavljanke in ga povleci v to polje
          </div>
        </div>

        <motion.div
          className="relative z-20 h-56"
          animate={shuffled.length === solved.length ? 'closed' : 'open'}
          transition={{ ease: 'circOut', duration: 0.75 }}
          onAnimationComplete={() => {
            if (onAnswer && shuffled.length === solved.length) onAnswer();
          }}
          initial={false}
          variants={{
            open: { height: height * 2.5 },
            closed: { height: 0 },
          }}
        >
          {shuffled.map((item, index) => {
            return (
              <motion.div
                drag
                dragMomentum={false}
                key={item}
                className={cx(
                  'absolute w-1/3 overflow-hidden shadow',
                  { 'rounded-tl-lg': pieces.indexOf(item) === 0 },
                  { 'rounded-tr-lg': pieces.indexOf(item) === 2 },
                  { 'rounded-bl-lg': pieces.indexOf(item) === 3 },
                  { 'rounded-br-lg': pieces.indexOf(item) === 5 },
                  { hidden: solved.includes(item) }
                )}
                style={positions[index]}
                dragConstraints={{ left: 0, right: 0, top: 300, bottom: 0 }}
                dragElastic={1}
                onDragEnd={(e) => handleDragEnd(e, item)}
              >
                <img src={item} className="pointer-events-none"></img>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default Puzzle;

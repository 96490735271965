import cx from 'classnames';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface WordFillProp {
  words: {
    besedilo: string;
    hidden: string;
  }[];
  current?: number;
  total?: number;
  className?: string;
  onAnswer?: Function;
}

const WordFill: React.FC<WordFillProp> = ({
  words,
  current,
  total,
  className,
  onAnswer,
}) => {
  const { t } = useTranslation();
  const [shuffled, setShuffled] = useState<{ id: number; besedilo: string }[]>(
    []
  );
  const [found, setFound] = useState<number[]>([]);

  const itemEls = useRef<HTMLSpanElement[]>([]);

  const handleAnswer = (correct: boolean, index: number) => {
    if (onAnswer) onAnswer(correct, false);
  };

  const findCollision = (item1: any, item2: any) => {
    const rect1 = item1.getBoundingClientRect();
    const rect2 = item2.getBoundingClientRect();

    if (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    ) {
      return true;
    }
    return false;
  };

  function shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    setShuffled(
      shuffleArray(
        words
          .map((item, i) => ({ ...item, id: i }))
          .filter((item) => item.hidden)
      )
    );
  }, []);

  const handleDragEnd = (e: any, id: any) => {
    let collision = false;
    itemEls.current.forEach((item, index) => {
      if (findCollision(item, e.target) && index === id) {
        collision = true;
      }
    });
    if (collision) {
      if (window.navigator.vibrate) window.navigator.vibrate(25);
      setFound((prev) => [...prev, id]);
      if (found.length + 1 === shuffled.length && onAnswer) onAnswer();
    }
  };

  return (
    <div
      className={cx(
        ' py-2.5 bg-no-repeat bg-cover bg-top mx-2 px-2 rounded-lg'
      )}
      style={{
        background:
          'linear-gradient(180deg, #F7F5F3 0%, rgba(247, 245, 243, 0) 100%)',
      }}
    >
      <div
        style={{ backgroundImage: 'url(/assets/leaf-word-fill.svg)' }}
        className="grid bg-no-repeat "
      >
        <div className="mx-auto rounded-lg bg-brown-35 px-2 py-0.5 text-base font-bold text-brown-100">
          {current}/{total}
        </div>
        <div className="mt-6 space-y-3 text-lg text-center">
          {words.map((item, index) => {
            if (item.hidden) {
              return (
                <span
                  key={index}
                  className={cx(
                    'inline-block p-2 mx-2 text-base bg-white border rounded-lg border-brown-35',
                    found.includes(index)
                      ? 'text-orange-100 shadow'
                      : 'text-transparent'
                  )}
                  ref={(el) => {
                    el !== null ? (itemEls.current[index] = el) : {};
                  }}
                >
                  {item.besedilo}
                </span>
              );
            } else {
              return <span key={index}>{item.besedilo}</span>;
            }
          })}
        </div>
        <div className="flex flex-wrap justify-center p-4 mt-10 rounded-lg bg-brown-35">
          <p className="w-full mb-4 text-sm font-medium text-center text-gray-50">
            Besede povleci v prazna polja zgoraj
          </p>

          {shuffled.map((item) => {
            return (
              <div key={item.id} className="mx-2 rounded-lg bg-brown-80">
                <motion.div
                  drag
                  dragMomentum={false}
                  dragConstraints={{ left: 0, right: 0, top: 300, bottom: 0 }}
                  dragElastic={1}
                  className={cx(
                    'p-2 bg-white border rounded-lg shadow border-brown-35',
                    { 'opacity-0': found.includes(item.id) }
                  )}
                  onDragEnd={(e) => handleDragEnd(e, item.id)}
                >
                  {item.besedilo}
                </motion.div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WordFill;

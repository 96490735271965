import classNames from 'classnames';
import { IonIcon, IonRouterLink } from '@ionic/react';

interface NoticeProps {
  title?: string;
  text?: string;
  link?: string;
  cta?: string | null;
  className?: string;
  onClose?: Function;
}

const Notice: React.FC<NoticeProps> = ({
  title,
  text,
  cta,
  link,
  className,
  onClose,
}) => (
  <div
    className={classNames(
      'p-4 relative shadow rounded-lg text-center',
      className
    )}
  >
    <IonIcon
      name="close"
      class="ion hydrated absolute top-0 right-0 p-3 text-xl"
      onClick={() => (onClose ? onClose() : '')}
    ></IonIcon>
    <h3 className="font-bold ">{title}</h3>
    <p className="mt-1 text-sm text-gray-75 font-slab">{text}</p>

    {cta && link && (
      <IonRouterLink
        class="ios mt-4 ion-activatable hydrated text-sm text-orange-100"
        routerLink={link}
      >
        {cta}
      </IonRouterLink>
    )}
  </div>
);

export default Notice;

import {
  IonModal,
  IonList,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonContent,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import InputSearch from './form/InputSearch';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  title?: string;
  items: Item[] | undefined;
  onDidDismiss: () => void;
  onSelection: (selected: Item | null) => void;
}

interface Item {
  key: string | number;
  value: string;
}

const Modal: React.FC<Props> = ({
  isOpen,
  title,
  items,
  onDidDismiss,
  onSelection,
}) => {
  const { t } = useTranslation();
  const [filteredItems, setFilteredItems] = useState<Item[] | undefined>(items);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  // filter items based on search input on cities/sales points
  const handleSearch = (searchString: string) => {
    searchString = searchString.toLowerCase();

    if (searchString === '') {
      setFilteredItems(items);
    } else {
      setFilteredItems(
        items?.filter(
          (item) =>
            item.value.toLowerCase().includes(searchString) ||
            String(item.key).includes(searchString)
        )
      );
    }
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => onDidDismiss()}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonButton color="secondary" onClick={() => onDidDismiss()}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <InputSearch
          className="m-4"
          placeholder={t('general.searchLabel')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearch(e.currentTarget.value)
          }
        />
        <IonList>
          {filteredItems?.map((item) => (
            <div
              key={item.key}
              className="px-4 my-3"
              onClick={() => {
                onSelection(item);
              }}
            >
              <span>{item.value}</span>
            </div>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default Modal;

import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonFooter,
  IonButtons,
  IonBackButton,
  IonButton,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { getChallenge, Challenge } from '../../../data/challenge/challenge.api';
import { formatDate } from '../../../services/FormatDate';

type TParams = { id: string };

const challengeTypes = ['quiz', 'puzzle', 'words'];

const Intro: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const id = match.params.id;
  const { t, i18n } = useTranslation();
  const [challenge, setChallenge] = useState<Challenge>();

  useEffect(() => {
    getChallenge(id).then((response) => setChallenge(response));
  }, [match.params.id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/dashboard"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle slot="start"></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="flex flex-col h-full">
          <div
            className="flex-grow px-4 py-6 bg-no-repeat bg-contain"
            style={{ backgroundImage: 'url(/assets/leaf-challenge.svg)' }}
          >
            {challenge?.campaign.brand.logo && (
              <img
                src={challenge.campaign.brand.logo}
                className="h-12 mx-auto"
              />
            )}
            <IonIcon
              src="assets/laurel.svg"
              class="ion hydrated text-4xl text-center block mx-auto mt-8"
            />
            <p className="px-4 mt-3 text-xl text-center text-brown-100 font-slab">
              {challenge?.campaign.message}
            </p>
          </div>

          <h2 className="mx-auto text-xl font-bold text-center">
            {challenge?.id === 1
              ? t('challenges.intro.titleStart')
              : t('challenges.intro.title')}
          </h2>
          <div className="flex items-center justify-center mt-3 mb-8">
            {challenge && (
              <span className="text-sm text-gray-50">
                {formatDate(challenge.start, 'short', i18n.language)} -{' '}
                {formatDate(challenge.end, 'short', i18n.language)}
              </span>
            )}
            <span className="mx-2 text-sm text-gray-50">·</span>
            <IonIcon src="assets/coin.svg" />
            <span className="ml-1 text-sm text-gray-50">
              {challenge?.points + ' ' + t('general.points')}
            </span>
          </div>
          {challenge && !challengeTypes.includes(challenge?.type ?? '') && (
            <a
              href="https://play.google.com/store/apps/details?id=si.imb.imperij"
              target="_blank"
              rel="noreferrer"
              className="px-4 py-3 mx-4 mb-2 text-base text-center rounded-lg bg-red-50"
            >
              <div className="font-bold text-red-100 ">
                {t('challenges.updateWarning.title')}
              </div>
              <div className="mt-1 text-sm text-red-100">
                {t('challenges.updateWarning.copy')}

                <div className="mt-2 underline">
                  {t('challenges.updateWarning.cta')}
                </div>
              </div>
            </a>
          )}
        </div>
      </IonContent>
      <IonFooter mode="ios">
        {challenge && challengeTypes.includes(challenge?.type ?? '') && (
          <IonButton
            disabled={!challengeTypes.includes(challenge?.type ?? '')}
            mode="ios"
            expand="block"
            className="mx-4"
            routerLink={`/user/challenges/challenge/${challenge?.id}`}
          >
            {t('challenges.intro.cta')}
          </IonButton>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default Intro;

import { Api } from './api';

export type User = {
  id: number;
  points: number;
  points_total: number;
  type: string;
  name: string;
  email: string;
  phone: string;
  sales_point_id: number;
  challenges_completed: number;
  challenges_total: number;
  demo: boolean;
  badges: string[];
  settings: {
    push_notifications: boolean;
    email_notifications: boolean;
    sms_notifications: boolean;
  };
  salesman: {
    name: string;
    phone: string;
  };
};

type userStoreParams = {
  token: string | undefined;
  email: string | null;
  name: string | undefined;
  phone: string | null;
  password: string | undefined;
  password_confirmation: string | undefined;
  gender: string | undefined;
  date_of_birth: string | undefined;
  sales_point_id: number | undefined;
  push_notifications: boolean | undefined;
  notifications: boolean | undefined;
};

type userSettings = {
  settings?: {
    push_notifications?: boolean;
    email_notifications?: boolean;
    sms_notifications?: boolean;
  };
  name?: string;
  phone?: string;
  sales_point_id?: number;
};

export const getUser = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'user', auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (params: userStoreParams): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: 'user',
      auth: true,
      body: params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'DELETE',
      endpoint: 'user',
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveSettings = async (params: userSettings): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'PUT',
      endpoint: 'user',
      auth: true,
      body: params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

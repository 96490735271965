import { OrderState } from './order.state';
import { OrderActions } from './order.api';

export function orderReducer(
  state: OrderState,
  action: OrderActions
): OrderState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        status: 'loading',
      };
    case 'RESOLVE':
      return {
        ...state,
        status: 'success',
      };
    case 'REJECT':
      return {
        ...state,
        status: 'error',
      };
    case 'CANCEL':
      return {
        ...state,
        status: 'idle',
      };
    default:
      return state;
  }
}

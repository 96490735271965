import { createContext, useContext } from 'react';
import type { User } from '../data/user';

interface Props {
  setUser: Function;
  toggleDemo: Function;
  user: User;
}

export const defaultUser = {
  points: 0,
  points_total: 0,
  type: 'user',
  name: '',
  id: -1,
  sales_point_id: -1,
  demo: false,
  challenges_completed: 0,
  challenges_total: 0,
  badges: [''],
  email: '',
  phone: '',
  salesman: {
    name: 'Example',
    phone: '0123123',
  },
  settings: {
    push_notifications: false,
    email_notifications: false,
    sms_notifications: false,
  },
};

export const UserContext = createContext<Props>({
  user: defaultUser,
  setUser: () => {},
  toggleDemo: () => {},
});

export function useUser() {
  return useContext(UserContext);
}

import { Api } from '../api';
import type { Brand } from '../brands/brands.api';

export type Campaign = {
  id: number;
  name: string;
  start: string;
  end: string;
  message: string;
  brand: Brand;
  image: string;
  challenges?: Challenge[];
};

export type Challenge = {
  id: number;
  type: string;
  name: string;
  start: string;
  end: string;
  image: string | null;
  message: string | null;
  points: number;
  campaign_id: number;
  campaign: Campaign;
  completed: boolean;
  options?: {
    // TODO: tukaj bo verjetno treba dat any, oz definirat tipe kvizov kot locen type
    failure_responses: {
      text: string;
    }[];
    instructions: {
      text: string;
    }[];
    sentences: {
      words: {
        besedilo: string;
        hidden: string;
      }[];
    }[];
    pieces: string[];
    questions: {
      title: string;
      after: string;
      answers: {
        text: string;
        image?: string | undefined;
        correct: boolean;
      }[];
    }[];
    success_responses: {
      text: string;
    }[];
  };
};

export const getCampaign = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'campaign', auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getChallenge = async (id?: string): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      endpoint: id ? `challenge/${id}` : 'challenge',
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const completeChallenge = async (id: string): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'PUT',
      endpoint: `challenge/${id}`,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

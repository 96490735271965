import classNames from 'classnames';
import { IonIcon } from '@ionic/react';
import { trendingUp, trendingDown } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

interface StatisticProps {
  className?: string;
  stats:
    | {
        current: { users: number; points: number } | null;
        previous: { users: number; points: number } | null;
      }
    | undefined;
}

const Statistic: React.FC<StatisticProps> = ({
  className,
  stats = undefined,
}) => {
  const usersCurrent =
    stats && stats.current && stats.current.users ? stats.current.users : 0;
  const usersPrev =
    stats && stats.previous && stats.previous.users ? stats.previous.users : 0;

  const pointsCurrent =
    stats && stats.current && stats.current.points ? stats.current.points : 0;
  const pointsPrev =
    stats && stats.previous && stats.previous.points
      ? stats.previous.points
      : 0;

  const averageCurrent =
    pointsCurrent && usersCurrent
      ? Math.round(pointsCurrent / usersCurrent)
      : 0;
  const averagePrev =
    pointsPrev && usersPrev ? Math.round(pointsPrev / usersPrev) : 0;

  const { t } = useTranslation();

  return (
    <div className={classNames(className, 'rounded-lg p-3 shadow bg-white')}>
      <h3 className="text-sm font-bold">{t('more.statistic.title')}</h3>
      <div className="grid grid-cols-3 mt-2 gap-x-8">
        <div className="text-xs text-gray-50">{t('more.statistic.users')}</div>
        <div className="text-xs text-gray-50">{t('more.statistic.points')}</div>
        <div className="text-xs text-gray-50">
          {t('more.statistic.average')}
        </div>
        <div className="">
          <div className="mt-1 text-2xl font-bold">{usersCurrent}</div>
          {usersCurrent - usersPrev > 0 && (
            <span className="flex items-center text-xs font-medium text-green-100">
              <IonIcon
                icon={trendingUp}
                className="mr-1 text-base ios hydrated"
              />
              +{usersCurrent - usersPrev}
            </span>
          )}
          {usersCurrent - usersPrev < 0 && (
            <span className="flex items-center text-xs font-medium text-red-100">
              <IonIcon
                icon={trendingDown}
                className="mr-1 text-base ios hydrated"
              />
              -{usersCurrent - usersPrev}
            </span>
          )}
        </div>
        <div className="">
          <div className="mt-1 text-2xl font-bold">{pointsCurrent}</div>
          {pointsCurrent - pointsPrev > 0 && (
            <span className="flex items-center text-xs font-medium text-green-100">
              <IonIcon
                icon={trendingUp}
                className="mr-1 text-base ios hydrated"
              />
              +{pointsCurrent - pointsPrev}
            </span>
          )}
          {pointsCurrent - pointsPrev < 0 && (
            <span className="flex items-center text-xs font-medium text-red-100">
              <IonIcon
                icon={trendingDown}
                className="mr-1 text-base ios hydrated"
              />
              -{pointsCurrent - pointsPrev}
            </span>
          )}
        </div>
        <div className="">
          <div className="mt-1 text-2xl font-bold">{averageCurrent}</div>
          {averageCurrent - averagePrev > 0 && (
            <span className="flex items-center text-xs font-medium text-green-100">
              <IonIcon
                icon={trendingUp}
                className="mr-1 text-base ios hydrated"
              />
              +{averageCurrent - averagePrev}
            </span>
          )}
          {averageCurrent - averagePrev < 0 && (
            <span className="flex items-center text-xs font-medium text-red-100">
              <IonIcon
                icon={trendingDown}
                className="mr-1 text-base ios hydrated"
              />
              -{averageCurrent - averagePrev}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statistic;

import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonRouterLink,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import Statistic from '../../components/Statistic';
import { logout } from '../../data/token';
import { useHistory } from 'react-router-dom';
import {
  chevronForward,
  personCircleOutline,
  settingsOutline,
  mailOutline,
  informationCircleOutline,
  logOutOutline,
  mapOutline,
} from 'ionicons/icons';
import { useUser, defaultUser } from '../../context/User';
import { Plugins } from '@capacitor/core';
import { useAuth } from '../../context/Auth';
import { getStats } from '../../data/stats/stats.api';
import { useEffect, useState } from 'react';

const More = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, setUser, toggleDemo } = useUser();
  const { setAuthToken } = useAuth();
  const { Storage } = Plugins;
  const [stats, setStats] = useState();

  useEffect(() => {
    getStats().then((response) => setStats(response));
  }, []);

  const handleLogout = () => {
    logout().then(() => {
      setUser(defaultUser);
      Storage.remove({ key: 'token' });
      setAuthToken('');
      history.push('/auth/login');
    });
  };

  const handleDemo = async () => {
    const toggled = await toggleDemo();

    if (toggled) {
      history.push('/user/dashboard');
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">
            {t('more.hello')}, {user.name.split(' ')[0]}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="px-4 pt-6 pb-8 bg-brown-20">
          <Statistic stats={stats} className="mt-6" />
        </div>
        <div className="max-w-xl mx-auto">
          <div className="px-4 py-8 space-y-6">
            <IonRouterLink
              routerLink="/salesman/more/profile"
              className="block text-gray-100 "
            >
              <IonIcon
                icon={personCircleOutline}
                class="ios hydrated text-gray-75 align-middle text-2xl"
              ></IonIcon>
              <span className="ml-2 text-base align-middle rtl:mr-2">
                {t('more.profile.title')}
              </span>
            </IonRouterLink>

            <IonRouterLink
              className="block text-gray-100"
              routerLink="/salesman/more/settings"
            >
              <IonIcon
                icon={settingsOutline}
                class="ios hydrated text-gray-75 align-middle text-2xl"
              ></IonIcon>
              <span className="ml-2 text-base align-middle rtl:mr-2">
                {t('more.settings.title')}
              </span>
            </IonRouterLink>
            <div onClick={() => handleDemo()} className="block text-gray-100">
              <IonIcon
                icon={mapOutline}
                class="ios hydrated text-gray-75 align-middle text-2xl"
              ></IonIcon>
              <span className="ml-2 text-base align-middle rtl:mr-2">
                {t('more.userDemo')}
              </span>
            </div>
          </div>
          <div className="px-4 pt-8 pb-10 space-y-6 border-t border-brown-20">
            <a
              href="https://imb-statamic.stage.cnj.digital/assets/documents/TobacnaLjubljana-PogojiPoslovanja.pdf"
              className="block text-gray-50"
              target="_blank"
            >
              <IonIcon
                icon={informationCircleOutline}
                class="ios hydrated align-middle text-2xl"
              ></IonIcon>
              <span className="ml-2 text-base align-middle rtl:mr-2">
                {t('more.terms')}
              </span>
            </a>
            <div className="text-gray-50" onClick={() => handleLogout()}>
              <IonIcon
                icon={logOutOutline}
                class="ios hydrated align-middle text-2xl"
              ></IonIcon>
              <span className="ml-2 text-base align-middle rtl:mr-2">
                {t('more.logout')}
              </span>
            </div>

            <div className="text-gray-50">
              <span className="text-xs align-middle">
                {t('more.version')}: 1.0.3
              </span>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default More;

import React, { useEffect, useReducer, useState } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
  useIonViewWillLeave,
} from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { getArticle, getPage } from '../../../data/article/article.api';
import { articleReducer } from '../../../data/article/article.reducer';
import { defaultArticleState } from '../../../data/article/article.state';
import { checkBadge } from '../../../data/badges/badge';
import { Badge } from '../../../data/badges/badges.api';
import BadgeToast from '../../../components/BadgeToast';
import { useUser } from '../../../context/User';
import { useTranslation } from 'react-i18next';
// import './Single.css';

type TParams = { id: string };

const Single: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const id = match.params.id;
  const [{ status, data }, dispatch] = useReducer(
    articleReducer,
    defaultArticleState
  );
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const { user } = useUser();
  const { i18n } = useTranslation();
  useEffect(() => {
    dispatch({ type: 'FETCH' });

    if (id !== 'e783b187-2a33-4aed-87c5-4f85c3349413' && id !== 'home') {
      getArticle(id)
        .then((response) => dispatch({ type: 'RESOLVE', payload: response }))
        .catch((e) => {
          dispatch({ type: 'REJECT' });
        });
      checkBadge('654b05a3-a7f8-41cf-811e-ae371fac7be3', user.badges).then(
        (response) => {
          if (response) {
            setBadge(response);
            setBadgeToastVisible(true);
          }
        }
      );
    } else {
      getPage(id)
        .then((response) => dispatch({ type: 'RESOLVE', payload: response }))
        .catch((e) => {
          dispatch({ type: 'REJECT' });
        });
    }
  }, []);

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  useEffect(() => {
    let visited: string[] = [];
    Storage.get({ key: 'visited' }).then((storage) => {
      if (storage.value) {
        visited = JSON.parse(storage.value);
      }

      if (data && !visited.includes(data.id)) {
        visited.push(data.id);
        Storage.set({
          key: 'visited',
          value: JSON.stringify(visited),
        });
      }
    });
  }, [data]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" defaultHref="/user/academy" />
          </IonButtons>
          <IonTitle>{data?.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonContent className="px-4">
        <div className="max-w-xl px-4 mx-auto mt-6">
          {status === 'loading' && (
            <div className="">
              <IonSkeletonText animated style={{ width: '20%' }} />
              <div className="h-8 mt-2">
                <IonSkeletonText animated style={{ width: '60%' }} />
              </div>
              <div className="mt-2">
                <IonSkeletonText animated style={{ width: '88%' }} />
                <IonSkeletonText animated style={{ width: '60%' }} />
              </div>

              <div className="h-32 mt-4 overflow-hidden rounded-lg">
                <IonSkeletonText animated style={{ width: '100%' }} />
              </div>

              <div className="mt-8">
                <IonSkeletonText animated style={{ width: '88%' }} />
                <IonSkeletonText animated style={{ width: '70%' }} />
                <IonSkeletonText animated style={{ width: '60%' }} />
              </div>
            </div>
          )}

          {status === 'success' && (
            <>
              <span className="text-sm text-gray-50">
                {data &&
                  data.date &&
                  new Date(data.date).toLocaleDateString(i18n.language, {
                    month: 'long',
                    day: 'numeric',
                  })}
              </span>
              <h1 className="mt-1 text-3xl font-medium text-gray-100">
                {data?.title}
              </h1>
              <div
                className="mt-2 text-gray-75"
                dangerouslySetInnerHTML={{ __html: data?.lead || '' }}
              ></div>

              <div className="relative mt-4 overflow-hidden rounded-lg">
                <img src={data?.image} alt="" />
              </div>

              <div
                className="mt-8 prose-sm prose font-slab"
                dangerouslySetInnerHTML={{ __html: data?.content || '' }}
              ></div>
            </>
          )}
        </div>
        <div className="mb-10"></div>
      </IonContent>
    </IonPage>
  );
};

export default Single;

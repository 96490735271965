import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonButton,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import { call } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import Heading from '../../../components/Heading';
import Textarea from '../../../components/form/Textarea';
import { useReducer, useState, useEffect } from 'react';
import {
  sendMessage,
  defaultSupportState,
} from '../../../data/support/support.api';
import { SupportReducer } from '../../../data/support/support.reducer';
import { useUser } from '../../../context/User';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [{ status }, dispatch] = useReducer(
    SupportReducer,
    defaultSupportState
  );
  const { user } = useUser();

  const sumbitMessage = () => {
    if ((user.demo ?? false) === true) {
      dispatch({ type: 'RESOLVE' });
      return;
    }

    sendMessage({ message })
      .then(() => {
        dispatch({ type: 'RESOLVE' });
      })
      .catch((error) => {
        dispatch({ type: 'REJECT' });
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/academy"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle>{t('more.contact.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="px-4 mt-6">
          <div className="flex flex-col items-center">
            <img
              className="object-cover w-16 h-16 rounded-full"
              src="/assets/placeholders/abbie-bernet-medium.jpg"
            />
            <div className="mt-2 text-xl font-bold text-gray-100">
              {user.salesman.name}
            </div>
            <div className="mt-2 text-base font-medium text-center text-gray-50">
              {t('more.contact.text')}
            </div>
            <IonButton
              href={`tel:${user.salesman.phone}`}
              mode="ios"
              className="w-full mt-3"
            >
              <IonIcon slot="start" icon={call} />

              {t('more.contact.cta')}
            </IonButton>
          </div>

          <div className="mt-12">
            <Heading>{t('more.contact.support')}</Heading>
            <div className="mt-2 text-sm font-medium text-gray-50">
              {t('more.contact.form')}
            </div>
            <Textarea
              value={message}
              className="mt-4"
              label={t('more.contact.formPlaceholder')}
              onChange={(e: any) => setMessage(e.target.value)}
            />
            <IonButton
              mode="ios"
              className="w-full mt-3"
              onClick={() => sumbitMessage()}
            >
              {' '}
              {status === 'loading' ? (
                <IonSpinner name="lines" />
              ) : (
                t('more.contact.sendCta')
              )}
            </IonButton>
          </div>
        </div>
        <IonToast
          isOpen={status === 'error' || status === 'success'}
          onDidDismiss={() => dispatch({ type: 'CANCEL' })}
          message={
            status === 'error'
              ? t('more.contact.error') ?? ''
              : t('more.contact.success') ?? ''
          }
          duration={2000}
        />
        <div className="mt-10"></div>
      </IonContent>
    </IonPage>
  );
};

export default Contact;

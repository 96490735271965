import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import {
  IonContent,
  IonPage,
  IonSpinner,
  IonFooter,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButton,
} from '@ionic/react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkmarkCircle } from 'ionicons/icons';
import { useAuth } from '../../context/Auth';
import { useUser } from '../../context/User';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

// object is type any so we can dynamically get object properties
export const langNames: any = {
  sl: 'Slovenščina',
  en: 'English',
  sr: 'Srpski',
  tr: 'Türk',
  mk_MK: 'Македонски',
  bs: 'Bosanski',
  hi: 'हिंदी',
  ar: 'العربية',
  ur: 'الأردية',
};

const Language: React.FC = () => {
  const [langs, setLangs] = useState<string[]>();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    setLangs(i18n.languages);
    Storage.get({ key: 'lang' }).then((storage) => {
      if (storage.value) {
        const currentLang = JSON.parse(storage.value) ?? [];
        handleLangChange(currentLang);
      }
    });
  }, []);

  useEffect(() => {
    if (auth.authToken.length > 0 && user.id !== -1) {
      setLoggedIn(true);
    }
  }, [user, auth]);

  const redirect = () => {
    history.push('/auth/invite-code');
  };

  if (loggedIn) {
    if (user.type === 'salesman') {
      return <Redirect to="/salesman/dashboard" />;
    } else if (user.type === 'user') {
      return <Redirect to="/user/dashboard" />;
    }
  }

  const handleLangChange = (lang: string) => {
    i18n.changeLanguage(lang);
    Storage.set({
      key: 'lang',
      value: JSON.stringify(lang),
    });
  };

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios"></IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full px-8 py-6">
          <h1 className="mb-8 text-2xl font-bold">Language</h1>
          <div className="space-y-6 ">
            {langs?.map((lang) => {
              return (
                <button
                  className={cx('flex items-center w-full focus:outline-none', {
                    'text-orange-100': i18n.language === lang,
                  })}
                  key={lang}
                  onClick={() => handleLangChange(lang)}
                >
                  {langNames[lang]}
                  {i18n.language === lang && (
                    <IonIcon
                      icon={checkmarkCircle}
                      class="text-orange-100 ios hydrated ml-auto rtl:mr-auto rtl:ml-0"
                    ></IonIcon>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <IonButton
          mode="ios"
          expand="block"
          className="mx-4"
          routerLink="/home"
        >
          {t('general.nextCta')}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Language;

import classNames from 'classnames';
import { IonIcon, IonRouterLink } from '@ionic/react';

interface BadgeToastProps {
  id?: string;
  image?: string;
  title?: string;
  text?: string;
  badgeName?: string;
  className?: string;
  visible: Boolean;
  onClose?: Function;
}

const BadgeToast: React.FC<BadgeToastProps> = ({
  image,
  title,
  text,
  id,
  visible,
  badgeName,
  onClose,
  className,
}) => {
  return (
    <div
      className={classNames(
        ' fixed top-0 z-20 px-4 transition transform duration-200',
        visible ? '' : '-translate-y-full'
      )}
      style={{ paddingTop: 'env(safe-area-inset-top)' }}
    >
      <div className="relative flex px-8 py-6 mt-4 bg-gray-100 rounded-lg">
        <IonRouterLink
          routerLink={`/user/more/badges/${id}`}
          className="flex-shrink-0 block pr-8 "
          onClick={() => (onClose ? onClose() : '')}
        >
          <img src={image} className="w-12 mx-auto" />
          <h3 className="text-center text-xs text-gray-10">{badgeName}</h3>
        </IonRouterLink>
        <div>
          <h3 className="font-bold text-gray-5">{title}</h3>
          <p className="mt-1 text-sm font-slab text-gray-25">{text}</p>
        </div>
        <IonIcon
          name="close"
          class="ion hydrated absolute top-0 right-0 p-4 text-gray-25"
          onClick={() => (onClose ? onClose() : '')}
        ></IonIcon>
      </div>
    </div>
  );
};

export default BadgeToast;

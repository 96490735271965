import { Api } from '../api';
import qs from 'qs';

export type ArticleActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'RESOLVE'; payload: any };

export type Article = {
  title: string;
  content: string;
  image: string;
  date: string;
  slug: string;
  lead: string;
  id: string;
};

interface Props {
  filter?: any;
}

export const getArticles = async (
  filter: Props | string = ''
): Promise<any> => {
  const api = new Api();

  if (filter) {
    filter = '?' + qs.stringify({ filter }, { encode: false });
  }

  try {
    const response = await api.request({
      endpoint: 'article' + filter,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getArticle = async (id: string): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      endpoint: `article/${id}`,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPage = async (id: string): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: `page/${id}`, auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getLevel = (points: number) => {
  return levels.filter((level) => level.points <= points).pop();
};

export type Level = {
  id: number;
  img: string;
  title: string;
  points: number;
};

export const levels = [
  {
    id: 0,
    img: '/assets/levels/level1.svg',
    title: 'more.levels.level1',
    points: 0,
  },
  {
    id: 1,
    img: '/assets/levels/level2.svg',
    title: 'more.levels.level2',
    points: 6500,
  },
  {
    id: 2,
    img: '/assets/levels/level3.svg',
    title: 'more.levels.level3',
    points: 145000000,
  },
  {
    id: 3,
    img: '/assets/levels/level4.svg',
    title: 'more.levels.level4',
    points: 2250000000,
  },
  {
    id: 4,
    img: '/assets/levels/level5.svg',
    title: 'more.levels.level5',
    points: 30500000000,
  },
  {
    id: 5,
    img: '/assets/levels/level6.svg',
    title: 'more.levels.level6',
    points: 38500000000,
  },
];

import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonRouterLink,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBadges, Badge } from '../../../data/badges/badges.api';
import { RouteComponentProps } from 'react-router-dom';

type TParams = { id: string };

const SingleBadge: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const id = match.params.id;
  const { t } = useTranslation();
  const [badge, setBadge] = useState<Badge>();

  useEffect(() => {
    getBadges().then((response) => {
      const current = response.find((badge: Badge) => badge.id === id);
      setBadge(current);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" />
          </IonButtons>
          <IonTitle>{t('more.badges')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen>
        <div className="flex-col items-center justify-center px-6">
          <img src={badge?.image} className="h-32 mx-auto mt-8" />
          <h2 className="mt-5 text-2xl font-bold text-center">{badge?.name}</h2>
          <p className="mt-2 mb-10 text-base text-center font-slab text-gray-75">
            {badge?.description}
          </p>
          {/* <div className="mx-auto text-center">
            <span className="text-sm text-gray-50">Značka prislužena:</span>
            <span className="ml-1 text-sm">
              {new Date(badge?.created_at ?? 0).toLocaleDateString('sl-SI')}
            </span>
          </div> */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SingleBadge;

import React from 'react';
import cx from 'classnames';

interface ContainerProps {
  onChange?: Function;
  value?: string;
  label: string;
  className?: string;
  error?: string[];
}

const Input: React.FC<ContainerProps> = ({
  onChange,
  value,
  label,
  className,
  error,
}) => {
  const errors = error?.length ?? 0;

  return (
    <div className={className}>
      <div
        className={cx(
          'relative px-3 pt-5 pb-1 text-base transition duration-300 border rounded-lg imb-input group border-gray-10 text-gray-50 focus-within:border-orange-100',
          {
            'border-gray-10': errors === 0,
            'border-red-100': errors > 0,
          }
        )}
      >
        <textarea
          placeholder=" "
          className="block w-full appearance-none focus:outline-none"
          value={value}
          rows={5}
          onChange={(e) => (onChange ? onChange(e) : '')}
        ></textarea>
        <label className="absolute inset-0 px-3 py-3 transition-all duration-300 pointer-events-none">
          {label}
        </label>
        {errors > 0 && (
          <div className="absolute top-0 bottom-0 right-0 flex items-center h-full mr-3">
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0335 10.4712L7.70754 0.580625C7.33004 -0.120625 6.32441 -0.120625 5.9466 0.580625L0.620974 10.4712C0.539009 10.6235 0.497916 10.7944 0.501705 10.9672C0.505494 11.1401 0.554036 11.309 0.642593 11.4575C0.73115 11.606 0.856697 11.729 1.00698 11.8145C1.15726 11.9 1.32714 11.9451 1.50004 11.9453H12.1529C12.3259 11.9453 12.496 11.9005 12.6465 11.8151C12.7969 11.7297 12.9227 11.6067 13.0115 11.4582C13.1002 11.3096 13.1489 11.1406 13.1527 10.9676C13.1566 10.7946 13.1155 10.6236 13.0335 10.4712ZM6.82722 10.4141C6.70361 10.4141 6.58277 10.3774 6.47999 10.3087C6.37721 10.2401 6.2971 10.1424 6.2498 10.0282C6.2025 9.91404 6.19012 9.78837 6.21423 9.66713C6.23835 9.54589 6.29787 9.43453 6.38528 9.34712C6.47269 9.25971 6.58405 9.20019 6.70529 9.17607C6.82653 9.15196 6.9522 9.16433 7.0664 9.21164C7.1806 9.25894 7.27822 9.33905 7.34689 9.44183C7.41557 9.54461 7.45222 9.66545 7.45222 9.78906C7.45222 9.95482 7.38638 10.1138 7.26917 10.231C7.15196 10.3482 6.99298 10.4141 6.82722 10.4141ZM7.50597 4.12813L7.3266 7.94063C7.3266 8.07323 7.27392 8.20041 7.18015 8.29418C7.08638 8.38795 6.95921 8.44063 6.8266 8.44063C6.69399 8.44063 6.56681 8.38795 6.47305 8.29418C6.37928 8.20041 6.3266 8.07323 6.3266 7.94063L6.14722 4.12969C6.14319 4.03862 6.15754 3.94768 6.1894 3.86227C6.22127 3.77687 6.27 3.69875 6.3327 3.63259C6.3954 3.56642 6.47078 3.51355 6.55434 3.47713C6.63791 3.44072 6.72795 3.4215 6.8191 3.42063H6.82566C6.91743 3.42058 7.00826 3.43912 7.09267 3.47515C7.17708 3.51117 7.25331 3.56392 7.31677 3.63021C7.38023 3.6965 7.42961 3.77497 7.46191 3.86087C7.49421 3.94676 7.50878 4.03832 7.50472 4.13L7.50597 4.12813Z"
                fill="#F00000"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;

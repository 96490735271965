import React, { useEffect, useRef, useState } from 'react';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { Route, Redirect, useLocation } from 'react-router';
import {
  home,
  storefront,
  search,
  book,
  grid,
  homeOutline,
  storefrontOutline,
  searchOutline,
  bookOutline,
  gridOutline,
} from 'ionicons/icons';
import Dashboard from './Dashboard';
import Notifications from '../user/dashboard/Notifications';
import SalesPoints from './SalesPoints';
import SalesPoint from './salesPoints/Single';
import Academy from '../user/Academy';
import Brand from '../user/academy/Brand';
import News from '../user/academy/News';
import Single from '../user/academy/Single';
import Products from '../user/Products';
import ProductSingle from '../user/product/Single';
import More from './More';
import Profile from './more/Profile';
import Orders from './dashboard/Orders';
import Settings from './more/Settings';
import { useTranslation } from 'react-i18next';

interface SalesmanProps {}

const Salesman: React.FC<SalesmanProps> = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('dashboard');
  const location = useLocation();
  const tabs = useRef<IonTabs>(null);

  useEffect(() => {
    setSelectedTab(tabs.current?.ionTabContextState.activeTab ?? '');
  }, [location]);
  return (
    <IonTabs ref={tabs}>
      <IonRouterOutlet>
        <Redirect exact={true} path="/salesman" to="/salesman/dashboard" />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        <Route
          path="/salesman/dashboard"
          render={() => <Dashboard />}
          exact={true}
        />
        <Route
          path="/salesman/dashboard/orders"
          render={() => <Orders />}
          exact={true}
        />
        <Route
          path="/salesman/notifications"
          render={() => <Notifications />}
          exact={true}
        />
        <Route
          path="/salesman/salespoints"
          render={() => <SalesPoints />}
          exact={true}
        />
        <Route
          path="/salesman/salespoints/:id"
          component={SalesPoint}
          exact={true}
        />
        <Route
          path="/salesman/academy"
          render={() => <Academy />}
          exact={true}
        />
        <Route
          path="/salesman/academy/brand/:id"
          component={Brand}
          exact={true}
        />
        <Route
          path="/salesman/academy/news/:id"
          component={Single}
          exact={true}
        />
        <Route
          path="/salesman/academy/news"
          render={() => <News />}
          exact={true}
        />
        <Route
          path="/salesman/products"
          render={() => <Products />}
          exact={true}
        />
        <Route
          path="/salesman/products/:id"
          component={ProductSingle}
          exact={true}
        />
        <Route path="/salesman/more" render={() => <More />} exact={true} />
        <Route
          path="/salesman/more/profile"
          render={() => <Profile />}
          exact={true}
        />
        <Route
          path="/salesman/more/settings"
          render={() => <Settings />}
          exact={true}
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="shadow-sm">
        <IonTabButton tab="dashboard" href="/salesman/dashboard">
          <IonIcon icon={selectedTab === 'dashboard' ? home : homeOutline} />
          <IonLabel>{t('dashboard.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="salespoints" href="/salesman/salespoints">
          <IonIcon
            icon={
              selectedTab === 'salespoints' ? storefront : storefrontOutline
            }
          />
          <IonLabel>{t('salesPoints.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="academy" href="/salesman/academy">
          <IonIcon icon={selectedTab === 'academy' ? book : bookOutline} />
          <IonLabel>{t('academy.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="products" href="/salesman/products">
          <IonIcon icon={selectedTab === 'products' ? search : searchOutline} />
          <IonLabel>{t('products.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="more" href="/salesman/more">
          <IonIcon icon={selectedTab === 'more' ? grid : gridOutline} />
          <IonLabel>{t('more.tabTitle')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default Salesman;

import React from 'react';
import classNames from 'classnames';
import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';

interface InputSearchProps {
  onChange?: Function;
  value?: string;
  className?: string;
  placeholder?: string;
}

const InputSearch: React.FC<InputSearchProps> = ({
  value,
  onChange,
  className,
  placeholder,
}) => {
  return (
    <div
      className={classNames(
        'flex border rounded-lg border-gray-10 py-3.5 px-3',
        className
      )}
    >
      <IonIcon
        icon={search}
        class="iom hydrated text-gray-50 text-2xl"
      ></IonIcon>
      <input
        value={value}
        placeholder={placeholder}
        className="flex-grow ml-2 rtl:mr-2 text-base focus:outline-none"
        type="search"
        onChange={(e) => (onChange ? onChange(e) : '')}
      />
    </div>
  );
};

export default InputSearch;

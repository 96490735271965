import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonRouterLink,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../context/User';
import { Order, getOrders } from '../../../data/order/order.api';
import { formatDate } from '../../../services/FormatDate';

const Orders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<Order[]>([]);
  const { user } = useUser();

  useEffect(() => {
    getOrders({ filter: { salesman_id: user?.id } }).then((response: Order[]) =>
      setOrders(response)
    );
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" />
          </IonButtons>
          <IonTitle>Vsa naročila</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen>
        <div className="px-4 py-6 space-y-4">
          {orders.map((order) => {
            return (
              <div key={order.id} className="flex w-full rounded-lg shadow">
                <div className="w-1/2 border-r border-brown-20">
                  <div className="relative w-full overflow-hidden">
                    <div className="absolute px-2 py-1 text-xs rounded top-1 left-1 bg-gray-5">
                      {formatDate(order.created_at, 'short')}
                    </div>
                    <img
                      src={order.reward.image}
                      className="mx-auto"
                      style={{ width: '100%', height: '88px' }}
                    />
                  </div>
                  <div className="p-2 text-left border-t border-brown-20">
                    <h3 className="mt-1 text-sm font-bold text-gray-100">
                      {order.reward.name}
                    </h3>
                    <div className="flex items-center mt-1">
                      <IonIcon
                        src="assets/coin.svg"
                        class="ion hydrated text-sm"
                      />
                      <p className="w-full ml-1 text-sm truncate text-gray-50">
                        {order.reward.value} {t('general.points')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 px-4">
                  <p className="mt-4 text-sm font-bold text-gray-100">
                    {order.user.name}
                  </p>
                  <p className="w-full mt-2 text-sm text-gray-50">
                    {order.sales_point.name}
                  </p>
                  <p className="mt-1 text-sm text-gray-50">
                    {order.sales_point.address}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Orders;

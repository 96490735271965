import {
  IonContent,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

const PasswordResetSent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" defaultHref="/auth/password-reset-link" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full max-w-xl px-4 py-6 mx-auto">
          <div className="flex flex-col items-center mt-8">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="40" fill="#FEF8F2" />
              <path
                d="M44 30H29.5C28.837 30 28.2011 30.2634 27.7322 30.7322C27.2634 31.2011 27 31.837 27 32.5V47.5C27 48.163 27.2634 48.7989 27.7322 49.2678C28.2011 49.7366 28.837 50 29.5 50H50.4206C51.0837 50 51.7196 49.7366 52.1884 49.2678C52.6572 48.7989 52.9206 48.163 52.9206 47.5V38.9375"
                stroke="#F07300"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M31 34L40 41L45.4375 36.8956"
                stroke="#F07300"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M50.9969 35C52.652 35 53.9937 33.6582 53.9937 32.0031C53.9937 30.348 52.652 29.0062 50.9969 29.0062C49.3417 29.0062 48 30.348 48 32.0031C48 33.6582 49.3417 35 50.9969 35Z"
                fill="#F07300"
              />
              <path
                d="M50.9998 36C50.2093 36 49.4365 35.7656 48.7793 35.3264C48.122 34.8872 47.6097 34.263 47.3072 33.5326C47.0047 32.8023 46.9255 31.9987 47.0797 31.2234C47.234 30.448 47.6146 29.7359 48.1736 29.1769C48.7326 28.6179 49.4447 28.2372 50.2201 28.083C50.9954 27.9288 51.799 28.008 52.5293 28.3105C53.2597 28.613 53.8839 29.1253 54.3231 29.7826C54.7623 30.4398 54.9967 31.2126 54.9967 32.0031C54.9959 33.0629 54.5745 34.079 53.8251 34.8284C53.0757 35.5778 52.0596 35.9991 50.9998 36ZM50.9998 30.0062C50.6041 30.0056 50.2171 30.1224 49.8878 30.3418C49.5585 30.5612 49.3017 30.8733 49.1498 31.2387C48.998 31.6041 48.9579 32.0063 49.0347 32.3945C49.1115 32.7827 49.3018 33.1393 49.5814 33.4193C49.8609 33.6994 50.2173 33.8901 50.6053 33.9676C50.9934 34.045 51.3957 34.0056 51.7613 33.8543C52.127 33.703 52.4395 33.4467 52.6594 33.1177C52.8793 32.7887 52.9967 32.4019 52.9967 32.0062C52.9967 31.4763 52.7864 30.9681 52.412 30.5931C52.0376 30.2181 51.5297 30.0071 50.9998 30.0062Z"
                fill="#F07300"
              />
            </svg>

            <h1 className="mt-4 text-3xl font-bold text-center text-gray-100">
              {t('auth.resetSent.title')}
            </h1>
            <p className="mt-2 text-base text-center text-gray-75 font-slab">
              {t('auth.resetSent.copy')}
            </p>
          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="max-w-xl mx-auto">
        <div className="flex justify-center w-full px-4 py-6 text-center">
          <p className="text-sm text-gray-50">{t('auth.resetSent.footer')}</p>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default PasswordResetSent;

import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
  IonTitle,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/Registration';
import Input from '../../components/form/Input';
import { checkmarkCircle } from 'ionicons/icons';
import DateTime from '../../components/form/DateTime';

const RegisterUserInformation: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const UserInformationSchema = Yup.object().shape({
    name: Yup.string()
      .max(250, t('validation.long'))
      .required(t('validation.required')),
    phone: Yup.string()
      .max(250, t('validation.long'))
      .required(t('validation.required')),
    birthDate: Yup.date().test(
      'age',
      t('validation.adult'),
      (inputDate: any) => {
        if (inputDate !== undefined) {
          const cutoff = new Date();
          cutoff.setFullYear(cutoff.getFullYear() - 18);
          return inputDate <= cutoff;
        }

        return true;
      }
    ),
  });

  const { birthDate, phone, name, gender, set, clear } = useStore();

  const genderValues = [
    {
      key: 'female',
      label: t('auth.register.user.genderFemale'),
    },
    {
      key: 'male',
      label: t('auth.register.user.genderMale'),
    },
  ];

  return (
    <Formik
      initialValues={{
        name: name ?? '',
        phone: phone ?? '',
        birthDate,
        gender,
      }}
      validationSchema={UserInformationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        history.push('/auth/register-login-information');
      }}
    >
      {(formik) => (
        <Form>
          <IonPage>
            <IonHeader>
              <IonToolbar mode="ios">
                <IonButtons slot="start">
                  <IonBackButton
                    mode="md"
                    defaultHref="/auth/register-location"
                  />
                </IonButtons>
                <IonTitle>{t('auth.register.title')}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="flex flex-col h-full px-4 py-6">
                <h2 className="text-xl font-bold">
                  {t('auth.register.user.title')}
                </h2>
                <div>
                  <Input
                    type="text"
                    label={t('auth.register.user.nameLabel')}
                    className="mt-4"
                    error={
                      formik.touched.name && formik.errors.name
                        ? [formik.errors.name]
                        : []
                    }
                    {...formik.getFieldProps('name')}
                    onChange={(event: any) => {
                      set('name', event.target.value);
                      formik.handleChange(event);
                    }}
                  />

                  <Input
                    type="text"
                    label={t('auth.register.user.phoneLabel')}
                    className="mt-4"
                    error={
                      formik.touched.phone && formik.errors.phone
                        ? [formik.errors.phone]
                        : []
                    }
                    {...formik.getFieldProps('phone')}
                    onChange={(event: any) => {
                      set('phone', event.target.value);
                      formik.handleChange(event);
                    }}
                  />

                  <div className="mt-6">
                    <h3 className="text-sm font-medium text-gray-100">
                      {t('auth.register.user.birthDateLabel')}
                    </h3>
                    <span className="text-xs text-gray-50">
                      {t('auth.register.user.birthDateDescription')}
                    </span>

                    <DateTime
                      className="mt-3"
                      label={t('auth.register.user.birthDateLabel')}
                      {...formik.getFieldProps('birthDate')}
                      onChange={(event: any) => {
                        set('birthDate', event.target.value || '');
                        formik.handleChange(event);
                      }}
                      error={
                        formik.touched.birthDate && formik.errors.birthDate
                          ? [formik.errors.birthDate]
                          : []
                      }
                    />
                  </div>

                  <div className="mt-6">
                    <h3 className="text-sm font-medium text-gray-100">
                      {t('auth.register.user.genderLabel')}
                    </h3>
                    <div className="flex flex-wrap mt-2 space-x-2">
                      {genderValues.map((item) => (
                        <div
                          key={item.key}
                          className={cx(
                            'relative flex items-center justify-center px-5 py-3 text-base rounded-lg text-gray-75 border transition-all duration-300 overflow-hidden',
                            gender === item.key
                              ? 'border-orange-100 bg-white'
                              : 'bg-brown-20 border-transparent'
                          )}
                          onClick={() => {
                            formik.handleChange({
                              target: {
                                name: 'gender',
                                value: item.key,
                              },
                            });
                            set('gender', item.key);
                          }}
                        >
                          {item.key === gender && (
                            <IonIcon
                              icon={checkmarkCircle}
                              class="ios hydrated absolute top-0 right-0 mt-0.5 mr-0.5 text-orange-100"
                            ></IonIcon>
                          )}
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="pb-4" />
              </div>
            </IonContent>
            <IonFooter mode="ios">
              <div className="flex w-full px-4 py-6 bg-white">
                <IonButton
                  disabled={formik.isSubmitting}
                  onClick={() => formik.submitForm()}
                  mode="ios"
                  className="w-full"
                >
                  {t('general.nextCta')}
                </IonButton>
              </div>
            </IonFooter>
          </IonPage>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterUserInformation;

import classNames from 'classnames';

interface NotificationProps {
  title?: string;
  text?: string;
  read?: Boolean;
  date?: string;
  className?: string;
}

const Notification: React.FC<NotificationProps> = ({
  title,
  text,
  date,
  read = false,
  className,
}) => (
  <div className={classNames(className)}>
    <span className="text-sm text-gray-50">{date}</span>
    <div
      className={classNames(
        'flex p-3 relative rounded-lg mt-2',
        read ? 'shadow' : ' bg-brown-20'
      )}
    >
      <div
        className={classNames(
          'rounded-full flex-shrink-0 mt-1.5 w-1.5 h-1.5 mr-2',
          read ? 'bg-gray-10' : 'bg-orange-100'
        )}
      ></div>
      <div>
        <h3 className="text-base font-bold ">{title}</h3>
        <p
          className="mt-1 text-sm text-gray-75"
          dangerouslySetInnerHTML={{ __html: text || '' }}
        ></p>
      </div>
    </div>
  </div>
);

export default Notification;

import { Api } from '../api';

export const getStats = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({ endpoint: 'stats', auth: true });
    return response;
  } catch (error) {
    throw error;
  }
};

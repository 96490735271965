import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonRouterLink,
  IonSlides,
  IonIcon,
  IonTitle,
  IonSlide,
} from '@ionic/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card';
import Heading from '../../components/Heading';
import { getBrands } from '../../data/brands/brands.api';
import { Article, getArticles } from '../../data/article/article.api';
import { useUser } from '../../context/User';
import { chevronForward } from 'ionicons/icons';

type Brand = {
  id: number;
  name: string;
  logo: string;
};

const Academy = () => {
  const { t, i18n } = useTranslation();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const { user } = useUser();

  useEffect(() => {
    getBrands().then((response) => setBrands(response));
    getArticles().then((response) => setArticles(response.splice(0, 4)));
  }, []);

  return (
    <IonPage className="pb-6">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('academy.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen>
        <div className="max-w-xl px-4 mx-auto mt-6">
          <Heading>{t('academy.brands')}</Heading>

          <div className="grid grid-cols-2 mt-4 gap-y-3 gap-x-2">
            {brands.map((brand) => {
              return (
                <IonRouterLink
                  key={brand.id + brand.name}
                  routerLink={`/${user.type}/academy/brand/${brand.id}`}
                  className="px-10 py-4 rounded-lg shadow"
                >
                  <img src={brand.logo} />
                </IonRouterLink>
              );
            })}
          </div>

          {user.type === 'user' && (
            <IonRouterLink routerLink="/user/dashboard/challenges">
              <div className="mt-10 ">
                <div className="flex overflow-hidden rounded-lg bg-brown-20">
                  <img
                    className="w-1/4 h-full"
                    src="/assets/placeholders/nagrajujmo_znanje.png"
                  />
                  <div className="py-5 pl-6 pr-4">
                    <h3 className="font-bold text-brown-100">
                      {t('academy.brand.challangeTitle')}
                    </h3>
                    <p className="text-sm text-gray-75 font-slab">
                      {t('academy.brand.challangeText')}
                    </p>
                  </div>
                  <div className="flex items-center pr-3 text-gray-25">
                    <IonIcon
                      icon={chevronForward}
                      class="ios hydrated text-gray-25 flex-shrink-0 rtl:rotate-180 transform"
                    ></IonIcon>
                  </div>
                </div>
              </div>
            </IonRouterLink>
          )}

          <div className="flex justify-between mt-10 ">
            <Heading>{t('academy.news.title')}</Heading>
            <IonRouterLink
              routerLink={`/${user.type}/academy/news`}
              class="ios ion-activatable hydrated rounded-md text-sm text-orange-100 bg-orange-5 py-1.5 px-2"
            >
              {t('academy.news.cta')}
            </IonRouterLink>
          </div>
          <IonSlides
            options={{ freeMode: true, slidesPerView: 2.1, spaceBetween: 8 }}
            className="mt-3 overflow-visible"
          >
            {articles?.map((article) => {
              return (
                <IonSlide key={article.id} className="items-stretch h-auto">
                  <IonRouterLink
                    routerLink={`/${user.type}/academy/news/${article.id}`}
                  >
                    <Card
                      image={article.image}
                      title={article.title}
                      date={new Date(article.date).toLocaleDateString(
                        i18n.language,
                        {
                          month: 'long',
                          day: 'numeric',
                        }
                      )}
                      type="article"
                    />
                  </IonRouterLink>
                </IonSlide>
              );
            })}
          </IonSlides>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Academy;

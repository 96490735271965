import {
  IonPage,
  IonSlides,
  IonContent,
  IonSlide,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  useIonViewDidEnter,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const Onboarding = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const slider = useRef<HTMLIonSlidesElement>(null);
  const [type, setType] = useState();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [slides, setSlides] = useState<
    { img: string; title: string; text: string }[]
  >([]);

  const userSlides = [
    {
      img: '/assets/onboarding/osvajaj-tocke.png',
      title: t('onboarding.user.slide1.title'),
      text: t('onboarding.user.slide1.text'),
    },
    {
      img: '/assets/onboarding/narocaj-nagrade.png',
      title: t('onboarding.user.slide2.title'),
      text: t('onboarding.user.slide2.text'),
    },
    {
      img: '/assets/onboarding/najdi-hitro.png',
      title: t('onboarding.user.slide3.title'),
      text: t('onboarding.user.slide3.text'),
    },
    {
      img: '/assets/onboarding/znanje.png',
      title: t('onboarding.user.slide4.title'),
      text: t('onboarding.user.slide4.text'),
    },
  ];

  const salesmanSlides = [
    {
      img: '/assets/onboarding/rekrutiraj.png',
      title: t('onboarding.salesman.slide1.title'),
      text: t('onboarding.salesman.slide1.text'),
    },
    {
      img: '/assets/onboarding/spremljaj.png',
      title: t('onboarding.salesman.slide2.title'),
      text: t('onboarding.salesman.slide2.text'),
    },
    {
      img: '/assets/onboarding/predstavi.png',
      title: t('onboarding.salesman.slide3.title'),
      text: t('onboarding.salesman.slide3.text'),
    },
    {
      img: '/assets/onboarding/zmaga.png',
      title: t('onboarding.salesman.slide4.title'),
      text: t('onboarding.salesman.slide4.text'),
    },
  ];

  useEffect(() => {
    Storage.get({ key: 'user' }).then((storage) => {
      if (storage.value) {
        const user = JSON.parse(storage.value);
        setType(user.type);
        if (user.type === 'salesman') setSlides(salesmanSlides);
        else setSlides(userSlides);
        Storage.set({
          key: 'onboarded',
          value: 'true',
        });
      }
    });
  }, []);

  const onSlideChange = async () => {
    const index = await slider.current?.getActiveIndex();
    setCurrentSlide(index ?? 0);
  };

  const nextSlide = () => {
    if (currentSlide + 1 === slides.length) history.push(`/${type}/dashboard`);
    else slider.current?.slideNext();
  };

  return (
    <IonPage>
      <IonHeader mode="ios" translucent>
        <IonToolbar mode="ios"></IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen>
        <div className="flex flex-col h-full">
          <div className="flex-grow onboarding">
            <IonSlides
              ref={slider}
              pager={true}
              onIonSlideDidChange={(e) => onSlideChange()}
            >
              {slides.map((slide, index) => {
                return (
                  <IonSlide key={index} className="h-full">
                    <div className="h-full pb-12 slide">
                      {slide.img && <img src={slide.img} className="" />}
                      <h2 className="mt-10 text-3xl font-bold ">
                        {slide.title}
                      </h2>
                      <p className="px-10 mt-1 text-base text-center text-gray-75">
                        {slide.text}
                      </p>
                    </div>
                  </IonSlide>
                );
              })}
            </IonSlides>
          </div>
          <div className="relative flex items-center px-4 pb-8">
            {currentSlide > 0 && (
              <div
                className="text-base text-gray-50"
                onClick={() => slider.current?.slidePrev()}
              >
                {t('general.prevCta')}
              </div>
            )}
            <div
              className="px-2 py-1 ml-auto text-base text-orange-100 rounded-md bg-orange-5"
              onClick={() => nextSlide()}
            >
              {t('general.nextCta')}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Onboarding;

import {
  IonPage,
  IonContent,
  IonHeader,
  IonButton,
  IonButtons,
  IonBackButton,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Message from '../../../components/challenge/Message';
import Quiz from '../../../components/challenge/Quiz';
import { useEffect, useState, useReducer, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getChallenge } from '../../../data/challenge/challenge.api';
import Puzzle from '../../../components/challenge/Puzzle';
import Words from '../../../components/challenge/Words';
import {
  challengeReducer,
  defaultChallengeState,
} from '../../../data/challenge/challenge.reducer';

type TParams = { id: string };

const ChallengeComponent: React.FC<RouteComponentProps<TParams>> = ({
  match,
}) => {
  const id = match.params.id;
  const { t } = useTranslation();
  const [chat, setChat] = useState<JSX.Element[]>([]);
  const content = useRef<HTMLIonContentElement>(null);
  const [{ status, currentQuestion, challenge, answer }, dispatch] = useReducer(
    challengeReducer,
    defaultChallengeState
  );

  useEffect(() => {
    getChallenge(id).then((response) => {
      dispatch({ type: 'SHOW_INSTRUCTION', payload: response });
    });
    setChat([]);
  }, [match.params.id]);

  // answerShown indicates if an answer is already displayed and we need to remove it
  const answerQuestion = (answer: boolean, answerShown: boolean) => {
    dispatch({
      type: 'HANDLE_ANSWER',
      payload: { correct: answer, answerShown: answerShown },
    });
  };

  const addToChat = (item: JSX.Element) => {
    setChat((state) => [...state, item]);
  };
  const removeLastChat = () => {
    setChat((state) => {
      const newArr = [...state];
      newArr.pop();
      return newArr;
    });
  };

  // The maximum is exclusive and the minimum is inclusive, min is 0
  const getRandomInt = (max: number) => {
    const min = 0;
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  };

  const showQuestion = (index: number) => {
    if (challenge?.type === 'quiz') {
      addToChat(
        <Quiz
          key={'question' + chat.length}
          question={challenge?.options?.questions[index].title}
          answers={challenge?.options?.questions[index].answers}
          current={index + 1}
          total={challenge?.options?.questions.length ?? 0}
          className="mt-10"
          onAnswer={(answer: boolean, answerShown: boolean) =>
            answerQuestion(answer, answerShown)
          }
        ></Quiz>
      );
    } else if (challenge?.type === 'puzzle') {
      addToChat(
        <Puzzle
          key={'puzzle' + chat.length}
          pieces={
            challenge && challenge.options ? challenge.options.pieces : []
          }
          onAnswer={() => answerQuestion(true, false)}
        ></Puzzle>
      );
    } else if (challenge?.type === 'words') {
      addToChat(
        <Words
          key={'words' + chat.length}
          words={challenge?.options?.sentences[index].words ?? []}
          current={index + 1}
          total={challenge?.options?.sentences.length ?? 0}
          onAnswer={() => answerQuestion(true, false)}
        />
      );
    }
  };

  const showMessage = (type: string, index: number) => {
    addToChat(
      <Message
        key={'instruction' + chat.length}
        text={challenge?.options?.instructions[0].text}
      />
    );
  };

  useEffect(() => {
    if (status === 'instruction') {
      challenge?.options?.instructions.forEach((item, index) => {
        addToChat(
          <Message key={'instruction' + chat.length + index} text={item.text} />
        );
      });
      dispatch({ type: 'SHOW_QUESTION' });
    } else if (status === 'question') {
      setTimeout(() => {
        showQuestion(currentQuestion);
      }, 2500);
    } else if (status === 'answer') {
      if (answer?.answerShown) removeLastChat();
      if (answer?.correct) dispatch({ type: 'ANSWER_CORRECT' });
      else dispatch({ type: 'ANSWER_WRONG' });
    } else if (status === 'success') {
      addToChat(
        <Message
          key={'success' + chat.length}
          text={
            challenge?.options?.success_responses[
              getRandomInt(challenge?.options?.success_responses.length)
            ].text
          }
        />
      );
      if (challenge?.type === 'quiz')
        addToChat(
          <Message
            key={'next' + chat.length}
            text={challenge?.options?.questions[currentQuestion - 1].after}
          />
        );
      challenge?.type === 'puzzle' ||
      (challenge?.type === 'words' &&
        currentQuestion === challenge?.options?.sentences.length) ||
      (challenge?.type === 'quiz' &&
        currentQuestion === challenge?.options?.questions.length)
        ? dispatch({ type: 'END_CHALLENGE' })
        : dispatch({ type: 'SHOW_QUESTION' });
    } else if (status === 'fail') {
      addToChat(
        <Message
          key={'failure' + chat.length}
          text={
            challenge?.options?.failure_responses[
              getRandomInt(challenge?.options?.failure_responses.length)
            ].text
          }
        />
      );
    } else if (status === 'end') {
      setTimeout(() => {
        addToChat(
          <IonButton
            key="end"
            mode="ios"
            expand="block"
            className="mx-4"
            routerLink={`/user/challenges/end/${id}`}
          >
            {t('challenges.cta')}
          </IonButton>
        );
      }, 2500);
    }
  }, [status]);

  useEffect(() => {
    content?.current?.scrollToBottom(500);
  }, [chat]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" className="transform rtl:rotate-180" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen ref={content}>
        <div className="mb-8">
          <img
            src={`/assets/challenge/${challenge?.type}.svg`}
            className="object-cover w-full"
          />
          {chat.map((item) => item)}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChallengeComponent;

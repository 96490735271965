import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonRouterLink,
  IonIcon,
  IonHeader,
} from '@ionic/react';
import Card from '../../components/Card';
import InputSearch from '../../components/form/InputSearch';
import { useTranslation } from 'react-i18next';
import { storefrontOutline, chevronForward } from 'ionicons/icons';
import {
  getSalesPoints,
  SalesPoint,
} from '../../data/salesPoint/salesPoint.api';
import {
  getKeyAccounts,
  KeyAccount,
} from '../../data/keyAccount/keyAccount.api';
import { useUser } from '../../context/User';
import { checkmarkCircle } from 'ionicons/icons';
import Modal from '../../components/Modal';
import { getCities, City } from '../../data/city/city.api';

const SalesPoints: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [keyAccounts, setKeyAccounts] = useState<KeyAccount[]>([]);
  const [selectedKeyAccount, setSelectedKeyAccount] = useState<KeyAccount>();
  const [salesPoints, setSalesPoints] = useState<SalesPoint[]>([]);
  const [filteredItems, setFilteredItems] = useState<SalesPoint[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [city, setCity] = useState<City>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getKeyAccounts(true).then((response) => setKeyAccounts(response));
  }, []);
  useEffect(() => {
    setCity(undefined);

    if (selectedKeyAccount) {
      getCities({
        filter: { key_account_id: selectedKeyAccount.id },
        auth: true,
      }).then((response) => setCities(response));
    }
  }, [selectedKeyAccount]);
  useEffect(() => {
    if (city) {
      getSalesPoints({
        filter: {
          key_account_id: selectedKeyAccount?.id,
          city_id: city.id,
          salesman_id: user.id,
        },
      }).then((response) => {
        setSalesPoints(response);
        setFilteredItems(response);
      });
    }
  }, [city]);

  const handleSearch = (searchString: string) => {
    searchString = searchString.toLowerCase();

    if (searchString === '') {
      setFilteredItems(salesPoints);
    } else {
      setFilteredItems(
        salesPoints.filter(
          (item) =>
            item.name.toLowerCase().includes(searchString) ||
            item.address.toLowerCase().includes(searchString)
        )
      );
    }
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('salesPoints.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div className="max-w-xl mx-auto">
            {selectedKeyAccount === undefined && (
              <div className="grid grid-cols-4 px-4 mt-6 gap-x-2 gap-y-3">
                {keyAccounts.map((item) => (
                  <div
                    key={item.id}
                    className="relative flex items-center justify-center p-3 transition-all duration-300 rounded-lg cursor-pointer bg-brown-20"
                    onClick={() => {
                      setSelectedKeyAccount(item);
                    }}
                  >
                    {item.name !== 'OSTALA PRODAJNA MESTA' && (
                      <img src={item.logo} className="w-full h-full" />
                    )}

                    {item.name === 'OSTALA PRODAJNA MESTA' && (
                      <div className="text-xs font-bold text-center text-brown-100">
                        {item.name}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {selectedKeyAccount !== undefined && (
              <div className="px-4 pb-4 border-b border-brown-20">
                <div className="grid grid-cols-4 mt-6 gap-x-2 gap-y-3">
                  <div className="relative flex items-center justify-center p-3 transition-all duration-300 border border-orange-100 rounded-lg">
                    {selectedKeyAccount?.name !== 'OSTALA PRODAJNA MESTA' && (
                      <img
                        src={selectedKeyAccount?.logo}
                        className="w-full h-full"
                      />
                    )}

                    {selectedKeyAccount?.name === 'OSTALA PRODAJNA MESTA' && (
                      <div className="text-xs font-bold text-center text-brown-100">
                        {selectedKeyAccount?.name}
                      </div>
                    )}
                    <IonIcon
                      icon={checkmarkCircle}
                      class="absolute top-0 right-0 mt-0.5 mr-0.5 text-orange-100 ios hydrated"
                    ></IonIcon>
                  </div>
                  <div
                    className="relative flex items-center justify-center p-3 text-xs text-center transition-all duration-300 rounded-lg cursor-pointer bg-brown-20"
                    onClick={() => setSelectedKeyAccount(undefined)}
                  >
                    {t('salesPoints.showMore')}
                  </div>
                </div>
                <div
                  className={cx(
                    'px-3 py-3.5 text-base mt-6 border-gray-10 border rounded-lg',
                    city === undefined ? 'text-gray-50' : ''
                  )}
                  onClick={() => setShowModal(true)}
                >
                  {city?.name ?? t('salesPoints.city')}
                </div>
                <InputSearch
                  className="mt-4"
                  placeholder={t('salesPoints.search') ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSearch(e.currentTarget.value)
                  }
                ></InputSearch>
              </div>
            )}

            {selectedKeyAccount !== undefined && (
              <div className="px-4 mt-6">
                {city === undefined ? (
                  <div className="flex flex-col items-center mt-10">
                    <IonIcon
                      icon={storefrontOutline}
                      class="ion hydrated text-4xl mx-auto text-gray-25"
                      style={{ fontSize: '2.5rem' }}
                    />
                    <div className="mt-2 text-base text-gray-25">
                      {t('salesPoints.placeholder')}
                    </div>
                  </div>
                ) : (
                  filteredItems.map((point, index) => {
                    return (
                      <IonRouterLink
                        key={point.id}
                        routerLink={`/salesman/salespoints/${point.id}`}
                        className="block my-3 text-gray-100"
                      >
                        <div
                          key={index}
                          className="flex items-center p-3 rounded-lg shadow"
                        >
                          <div className="flex-grow">
                            <p className="text-sm font-bold">{point.name}</p>
                            <p className="mt-1 text-sm text-gray-50">
                              {point.address}
                            </p>
                          </div>
                          <IonIcon
                            icon={chevronForward}
                            class="ios hydrated text-gray-25 pl-3"
                          ></IonIcon>
                        </div>
                      </IonRouterLink>
                    );
                  })
                )}
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
      <Modal
        title={t('auth.register.location.cityLabel')}
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        onSelection={(selected) => {
          setCity({ id: Number(selected?.key), name: selected?.value });
          setShowModal(false);
        }}
        items={cities.map((city) => ({
          key: city.id,
          value: `${city.name} (${city.id})`,
        }))}
      />
    </>
  );
};

export default SalesPoints;

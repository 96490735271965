import { useHistory } from 'react-router-dom';
import { useEffect, useState, useReducer } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
  IonTitle,
  IonSkeletonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { getKeyAccount } from '../../data/keyAccount/keyAccount.api';
import { keyAccountReducer } from '../../data/keyAccount/keyAccount.reducer';
import { defaultKeyAccountState } from '../../data/keyAccount/keyAccount.state';
import { getCities, City } from '../../data/city/city.api';
import { cityReducer } from '../../data/city/city.reducer';
import { defaultCityState } from '../../data/city/city.state';
import {
  getSalesPoints,
  SalesPoint,
} from '../../data/salesPoint/salesPoint.api';
import { salesPointReducer } from '../../data/salesPoint/salesPoint.reducer';
import { defaultSalesPointState } from '../../data/salesPoint/salesPoint.state';
import { useStore } from '../../store/Registration';
import Modal from '../../components/Modal';
import Input from '../../components/form/Input';
import { checkmarkCircle } from 'ionicons/icons';

const RegisterLocation: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [keyAccountState, keyAccountDispatch] = useReducer(
    keyAccountReducer,
    defaultKeyAccountState
  );

  const [cityState, cityDispatch] = useReducer(cityReducer, defaultCityState);
  const [salesPointState, salesPointDispatch] = useReducer(
    salesPointReducer,
    defaultSalesPointState
  );

  const [showModal, setShowModal] = useState(false);
  const [showSalesPointModal, setShowSalesPointModal] = useState(false);

  const { keyAccount, city, salesPoint, set, clear } = useStore();

  const setSalesPointText = (sp?: SalesPoint) => {
    if (sp) {
      return `${sp.name} (${sp.address})`;
    } else {
      return '';
    }
  };

  const setCity = (c?: City) => {
    if (c) {
      return `${c.name} (${c.id})`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    salesPointDispatch({ type: 'FETCH' });

    getSalesPoints({ filter: { key_account_id: keyAccount, city_id: city } })
      .then((response) =>
        salesPointDispatch({ type: 'RESOLVE', payload: response })
      )
      .catch((e) => {
        salesPointDispatch({ type: 'REJECT' });
      });
  }, [city]);

  useEffect(() => {
    if (!keyAccount) {
      history.push('/auth/register-key-account');
      return;
    }

    cityDispatch({ type: 'FETCH' });
    keyAccountDispatch({ type: 'FETCH' });

    getCities({ filter: { key_account_id: keyAccount }, auth: false })
      .then((response) => cityDispatch({ type: 'RESOLVE', payload: response }))
      .catch((e) => {
        cityDispatch({ type: 'REJECT' });
      });

    getKeyAccount(keyAccount)
      .then((response) =>
        keyAccountDispatch({ type: 'RESOLVE', payload: response })
      )
      .catch((e) => {
        keyAccountDispatch({ type: 'REJECT' });
      });
  }, []);

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <IonBackButton
                mode="md"
                defaultHref="/auth/register-key-account"
              />
            </IonButtons>
            <IonTitle>{t('auth.register.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="flex flex-col h-full px-4 py-6">
            <h2 className="text-xl font-bold">
              {t('auth.register.location.keyAccountTitle')}
            </h2>
            {keyAccountState.status === 'loading' && (
              <div className="grid grid-cols-4 mt-4 gap-x-2 gap-y-3">
                <div className="overflow-hidden rounded-lg aspect-w-1 aspect-h-1">
                  <IonSkeletonText animated />
                </div>
                <div className="overflow-hidden rounded-lg aspect-w-1 aspect-h-1">
                  <IonSkeletonText animated />
                </div>
              </div>
            )}
            {keyAccountState.status === 'success' && (
              <>
                <div className="grid grid-cols-4 mt-4 gap-x-2 gap-y-3">
                  <div className="relative flex items-center justify-center p-3 transition-all duration-300 border border-orange-100 rounded-lg">
                    {keyAccountState.data?.name !== 'OSTALA PRODAJNA MESTA' && (
                      <img
                        src={keyAccountState.data?.logo}
                        className="w-full h-full"
                      />
                    )}

                    {keyAccountState.data?.name === 'OSTALA PRODAJNA MESTA' && (
                      <div className="text-xs font-bold text-center text-brown-100">
                        {keyAccountState.data?.name}
                      </div>
                    )}
                    <IonIcon
                      icon={checkmarkCircle}
                      class="ios hydrated absolute top-0 right-0 mt-0.5 mr-0.5 text-orange-100"
                    ></IonIcon>
                  </div>

                  <div
                    className="relative flex items-center justify-center p-3 transition-all duration-300 border border-transparent rounded-lg bg-brown-20"
                    onClick={() => history.push('/auth/register-key-account')}
                  >
                    <div className="text-xs font-medium text-center text-gray-75">
                      {t('auth.register.location.showAll')}
                    </div>
                  </div>
                </div>
                <div className="mt-8">
                  <h2 className="text-xl font-bold">
                    {t('auth.register.location.locationTitle')}
                  </h2>
                  <div
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <Input
                      value={city?.label || ''}
                      type="text"
                      label={t('auth.register.location.cityLabel')}
                      className="mt-4"
                    />
                  </div>

                  {city && (
                    <div
                      className="mt-4"
                      onClick={() => {
                        setShowSalesPointModal(true);
                      }}
                    >
                      <Input
                        value={salesPoint?.label || ''}
                        type="text"
                        label={t('auth.register.location.salesPointLabel')}
                        className="mt-4"
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="pb-4" />
          </div>
        </IonContent>
        <IonFooter mode="ios">
          <div className="flex w-full px-4 py-6 bg-white">
            <IonButton
              disabled={!salesPoint}
              onClick={() => history.push('/auth/register-user-information')}
              mode="ios"
              className="w-full"
            >
              {t('general.nextCta')}
            </IonButton>
          </div>
        </IonFooter>
      </IonPage>
      <Modal
        title={t('auth.register.location.cityLabel')}
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        onSelection={(selected) => {
          if (selected?.key && Number(selected.key) !== city?.id) {
            clear('salesPoint');

            const _city = cityState.data?.find(
              (item) => item.id === Number(selected.key)
            );

            set('city', {
              id: Number(selected.key),
              label: setCity(_city),
            });
          }
          setShowModal(false);
        }}
        items={cityState.data?.map((city) => ({
          key: city.id,
          value: setCity(city),
        }))}
      />
      <Modal
        title={t('auth.register.location.salesPointLabel')}
        isOpen={showSalesPointModal}
        onDidDismiss={() => setShowSalesPointModal(false)}
        onSelection={(selected) => {
          if (selected?.key) {
            const _salesPoint = salesPointState.data?.find(
              (item) => item.id === Number(selected.key)
            );

            set('salesPoint', {
              id: Number(selected.key),
              label: setSalesPointText(_salesPoint),
            });
          }
          setShowSalesPointModal(false);
        }}
        items={salesPointState.data?.map((point) => ({
          key: point.id,
          value: setSalesPointText(point),
        }))}
      />
    </>
  );
};

export default RegisterLocation;

import React, { useState, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonRouterLink,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonFooter,
  IonButton,
  IonIcon,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Wheel } from 'react-custom-roulette';
import { spinWheel } from '../../../data/wheel/wheel.api';
import { motion } from 'framer-motion';
import { transpileModule } from 'typescript';
import { getUser } from '../../../data/user';
import { useUser } from '../../../context/User';
import { checkBadge } from '../../../data/badges/badge';
import { Badge } from '../../../data/badges/badges.api';
import BadgeToast from '../../../components/BadgeToast';

const WheelPage: React.FC = () => {
  const { t } = useTranslation();

  const data = [
    { option: '50' },
    { option: '5' },
    { option: '20' },
    { option: '10' },
    { option: '5000' },
    { option: '0' },
    { option: '10' },
    { option: '100' },
    { option: '20' },
    { option: '5' },
    { option: '1000' },
    { option: '10' },
  ];

  const [mustSpin, setMustSpin] = useState(false);
  const [pulsate, setPulsate] = useState(true);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeIndex, setPrizeIndex] = useState(0);
  const [showPoints, setShowPoints] = useState(false);
  const { user, setUser } = useUser();
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);

  const handleSpinClick = async () => {
    if (mustSpin) return;
    const prize = await spinWheel();
    const option = data.find(
      (item) => item.option === prize.points.toString()
    ) ?? {
      option: '0',
    };
    setPrizeIndex(data.indexOf(option));
    setPrizeNumber(prize.points);
    setMustSpin(true);
    setPulsate(false);

    getUser().then((response) => setUser(response));
    checkBadge('1deaccdc-3493-44d0-8514-de6b4da3dc34', user.badges).then(
      (response: any) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton mode="md" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonContent>
        <div
          className="flex flex-col h-full overflow-hidden"
          style={{
            background:
              'linear-gradient(180deg, #F7F5F3 0%, rgba(247, 245, 243, 0) 100%)',
          }}
        >
          <div
            className="absolute w-full h-full -top-12"
            style={{
              backgroundImage: 'url(/assets/leaf-wheel.svg)',
            }}
          >
            {' '}
          </div>
          <div
            className={cx(
              { 'flex-grow px-10': !showPoints },
              ' pt-8 text-center bg-no-repeat'
            )}
          >
            {!showPoints && (
              <motion.div
                initial={{ y: -200 }}
                animate={{ y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.5 }}
              >
                <h1 className="text-4xl font-black leading-tight text-orange-100 ">
                  {t('wheel.title')}
                </h1>
                <p className="mt-4 text-lg">{t('wheel.subtitle')}</p>
              </motion.div>
            )}
            {showPoints && (
              <motion.div
                initial={{ y: -200 }}
                animate={{ y: 0 }}
                transition={{ ease: 'easeOut', duration: 0.5 }}
              >
                <h2 className="mx-auto text-2xl font-bold text-center">
                  {prizeNumber === 0
                    ? t('wheel.failTitle')
                    : t('wheel.prizeTitle')}
                </h2>
                <p className="px-16 mt-2 text-center text-gray-75">
                  {prizeNumber === 0
                    ? t('wheel.failSubtitle')
                    : t('wheel.prizeSubtitle')}
                </p>
                <div className="flex items-center justify-center mt-6">
                  <IonIcon
                    src="assets/coin-stroke.svg"
                    class="ios hydrated text-5xl"
                  />
                  <div className="flex items-baseline">
                    <span className="ml-1 text-3xl font-bold">
                      {prizeNumber + ' '}
                    </span>
                    <span className="text-gray-50 ml-0.5">
                      {t('general.points')}
                    </span>
                  </div>
                </div>
              </motion.div>
            )}
          </div>
          <motion.div
            animate={showPoints ? { scale: 0.5, y: -50 } : { scale: 1 }}
            transition={{
              duration: 1,
            }}
            className="relative flex flex-wrap justify-center w-full pt-8"
          >
            <img
              src="/assets/wheel-indicator.svg"
              className="absolute top-0 z-10 -mb-12 "
            />
            <div
              className="relative flex justify-center -mb-40 transform -rotate-45 wheel"
              style={{
                filter:
                  'drop-shadow(0px -24px 40px rgba(75, 60, 50, 0.08)) drop-shadow(0px -8px 24px rgba(240, 115, 0, 0.08))',
              }}
              onClick={() => handleSpinClick()}
            >
              <div
                className="absolute z-20 transform -translate-y-1/2 rounded-full top-1/2 p-1.5 bg-opacity-25 bg-brown-100"
                style={{ boxShadow: '0px 0px 40px rgba(75, 60, 50, 0.32)' }}
              >
                <div className="flex transform  rotate-45  bg-white rounded-full p-2.5">
                  <motion.div
                    className="flex"
                    animate={pulsate ? { scale: 0.9 } : {}}
                    transition={
                      pulsate
                        ? {
                            repeat: Infinity,
                            duration: 1,
                            repeatType: 'mirror',
                          }
                        : {}
                    }
                  >
                    <IonIcon
                      src="/assets/coin-stroke.svg"
                      class="ios hydrated text-7xl"
                    />
                  </motion.div>
                </div>
              </div>

              <div
                className="absolute inset-0 z-10 m-3.5 rounded-full "
                style={{
                  boxShadow: 'inset 0px 4px 32px rgba(31, 24, 20, 0.24)',
                }}
              ></div>

              <div
                className="absolute inset-0 m-1 rounded-full "
                style={{ background: '#BAACA3' }}
              ></div>

              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeIndex}
                data={data}
                outerBorderColor="#F7F5F3"
                radiusLineColor="white"
                backgroundColors={['#7E604B', '#F4D0AF']}
                textColors={['#ffffff']}
                fontSize={32}
                textDistance={75}
                perpendicularText={true}
                onStopSpinning={() => {
                  setMustSpin(false);
                  setShowPoints(true);
                }}
              />
            </div>
          </motion.div>
        </div>
      </IonContent>
      <IonFooter>
        {showPoints && (
          <IonButton
            mode="ios"
            expand="block"
            className="mx-4 my-2"
            routerLink="/user/dashboard"
          >
            {t('wheel.back')}
          </IonButton>
        )}
        {!showPoints && (
          <IonButton
            mode="ios"
            expand="block"
            className="mx-4 my-2"
            onClick={() => handleSpinClick()}
          >
            {t('wheel.cta')}
          </IonButton>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default WheelPage;

import React, { useState, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonRouterLink,
  IonHeader,
  IonButtons,
  IonBackButton,
  useIonViewWillLeave,
} from '@ionic/react';
import Notification from '../../../components/Notification';
import { useTranslation } from 'react-i18next';
import { Article, getArticles } from '../../../data/article/article.api';
import { Link } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const Notifications: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [visited, setVisited] = useState<string[]>([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getArticles().then((response) => setArticles(response));
    Storage.get({ key: 'visited' }).then((storage) => {
      if (storage.value) {
        setVisited(JSON.parse(storage.value) ?? []);
      }
    });
  }, []);

  useIonViewWillLeave(() => {
    const newVisited = [...articles].map((article: Article) => article.id);
    Storage.set({
      key: 'visited',
      value: JSON.stringify(newVisited),
    });
  }, [articles]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton mode="md" className="transform rtl:rotate-180" />
          </IonButtons>
          <IonTitle>{t('dashboard.notifications.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-xl px-4 mx-auto">
          {articles.map((article) => {
            return (
              <Link
                key={article.id}
                to={`/user/academy/news/${article.id}`}
                className=""
              >
                <Notification
                  date={new Date(article.date).toLocaleDateString(
                    i18n.language,
                    {
                      month: 'long',
                      day: 'numeric',
                    }
                  )}
                  title={article.title}
                  text={article.lead}
                  read={visited.includes(article.id)}
                  className="mt-6"
                />
              </Link>
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;

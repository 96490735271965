import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
} from '@ionic/react';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { sendPasswordResetLink } from '../../data/passwordReset';

const PasswordResetLink: React.FC = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({ email: [] });
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const postPasswordReset = async () => {
    sendPasswordResetLink({
      email,
    })
      .then(() => {
        history.push('/auth/password-reset-sent');
      })
      .catch((error: any) => {
        setErrors(error.response.errors);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" defaultHref="/auth/login" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full max-w-xl px-4 py-6 mx-auto">
          <div className="flex flex-col">
            <h1 className="mt-4 text-3xl font-bold text-gray-100">
              {t('auth.resetLink.title')}
            </h1>
            <p className="mt-2 text-base text-gray-75 font-slab">
              {t('auth.resetLink.copy')}
            </p>
            <Input
              type="email"
              label={t('auth.login.emailLabel')}
              className="mt-4"
              value={email}
              error={errors.email}
              onChange={(e: any) => {
                setErrors({ email: [] });
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="max-w-xl mx-auto">
        <div className="flex flex-col w-full px-4 py-6 bg-white">
          <IonButton
            onClick={postPasswordReset}
            mode="ios"
            className="w-full mt-4"
          >
            {t('auth.resetLink.cta')}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default PasswordResetLink;

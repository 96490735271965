import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/Auth';

interface Props {
  location?: any;
  component?: any;
  render?: any;
  children?: any;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

const AuthRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.authToken.length ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default AuthRoute;

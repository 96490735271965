import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonRouterLink,
  IonToolbar,
  IonTitle,
  useIonViewWillLeave,
} from '@ionic/react';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBadges, Badge } from '../../../data/badges/badges.api';
import { checkBadge } from '../../../data/badges/badge';
import BadgeToast from '../../../components/BadgeToast';
import { useUser } from '../../../context/User';
import { getUser } from '../../../data/user';

const Badges = () => {
  const { t } = useTranslation();
  const [badges, setBadges] = useState<Badge[]>([]);
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const { user, setUser } = useUser();

  useEffect(() => {
    getBadges().then((response) => {
      setBadges(response);
    });

    if ((user.demo ?? false) === true) {
      return;
    }

    checkBadge('8c51bf71-027b-4f31-9208-c5d6b1fcc3f8', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
          getUser().then((response) => setUser(response));
        }
      }
    );

    getUser().then((response) => setUser(response));
  }, []);

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" />
          </IonButtons>
          <IonTitle>{t('more.badges')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen>
        <div className="grid w-full grid-cols-3 px-4 py-8 gap-y-6">
          {badges.map((badge, index) => {
            if (user.badges.includes(badge.id)) {
              return (
                <IonRouterLink
                  key={index}
                  routerLink={`/user/more/badges/${badge.id}`}
                  className="flex-col items-center justify-center text-gray-100"
                >
                  <img src={badge.image} className="w-12 mx-auto" />

                  <h2 className="text-sm text-center">{badge.name}</h2>
                </IonRouterLink>
              );
            } else
              return (
                <div
                  key={index}
                  className="flex-col items-center justify-center text-gray-100"
                >
                  <img
                    src="/assets/placeholders/badge-empty.png"
                    className="w-12 mx-auto"
                  />
                </div>
              );
          })}
        </div>
        <div
          className="sticky w-full h-16 -bottom-6"
          style={{
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.95) 50%)',
          }}
        ></div>
      </IonContent>
    </IonPage>
  );
};

export default Badges;

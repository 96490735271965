import { Redirect, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonToast,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
  useIonViewWillEnter,
} from '@ionic/react';
import Input from '../../components/form/Input';
import { useAuth } from '../../context/Auth';
import { login, loginResult } from '../../data/token';
import { Plugins } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../context/User';
import { getUser } from '../../data/user';
const { Storage } = Plugins;

const Login: React.FC = () => {
  const { Device } = Plugins;
  const history = useHistory();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [toastShown, setToastShown] = useState(false);
  const [errors, setErrors] = useState({ email: [], password: [] });
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('user');
  const [password, setPassword] = useState('');
  const [onboarded, setOnboarded] = useState(false);
  const { authToken, setAuthToken } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    Storage.get({ key: 'onboarded' }).then((storage) => {
      if (storage.value) setOnboarded(true);
    });
  });

  const redirectToPasswordReset = () => {
    history.push('/auth/password-reset-link');
  };

  const postLogin = async () => {
    const info = await Device.getInfo();

    login({
      email,
      password,
      device_name: info.model,
    })
      .then((response: loginResult) => {
        setAuthToken(response.token);
        return getUser();
      })
      .then((response) => {
        setUserType(response.type);
        setUser(response);
        setLoggedIn(true);
      })
      .catch((error: any) => {
        if (error.response) {
          setErrors(error.response.errors);
        } else {
          // Probably no internet connection
          setToastShown(true);
        }
      });
  };

  if (authToken && user.id !== -1) {
    if (!onboarded) return <Redirect to="/auth/onboarding" />;
    if (userType === 'salesman') return <Redirect to="/salesman/dashboard" />;
    else return <Redirect to="/user/dashboard" />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full max-w-xl px-4 py-6 mx-auto">
          <div className="flex flex-col">
            <img src="/assets/imperij_logo.svg" className="h-16" />
            <h1 className="mt-12 text-xl font-bold text-gray-100">
              {t('auth.login.title')}
            </h1>
            <Input
              type="email"
              label={t('auth.login.emailLabel')}
              className="mt-4"
              value={email}
              error={errors.email}
              onChange={(e: any) => {
                setErrors({ email: [], password: [] });
                setEmail(e.target.value);
              }}
            ></Input>
            <Input
              type="password"
              label={t('auth.login.passwordLabel')}
              className="mt-4"
              value={password}
              error={errors.password}
              onChange={(e: any) => {
                setErrors({ email: [], password: [] });
                setPassword(e.target.value);
              }}
            ></Input>
            <div className="mt-4">
              <span
                className="text-sm font-medium cursor-pointer text-gray-75"
                onClick={redirectToPasswordReset}
              >
                {t('auth.login.forgottenPassword')}
              </span>
            </div>
          </div>
          <div className="pt-4 mt-10 border-t">
            <img src="/assets/imperial-logo.svg" className="mx-auto h-14" />
          </div>

          <div className="pb-4" />
        </div>
        <IonToast
          isOpen={toastShown}
          onDidDismiss={() => setToastShown(false)}
          message="Prišlo je do napake. Preverite vašo povezljivost z internetom. V primeru, da se težava ponavlja kontaktirajte podporo."
          duration={6000}
        />
      </IonContent>
      <IonFooter mode="ios" className="max-w-xl mx-auto">
        <div className="flex w-full px-4 py-6 bg-white">
          <IonButton onClick={postLogin} mode="ios" className="w-full">
            {t('auth.login.cta')}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Login;

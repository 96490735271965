import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonBackButton,
  IonButtons,
  IonRouterLink,
  IonToolbar,
  IonTitle,
  useIonViewWillLeave,
} from '@ionic/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../context/User';
import { levels, getLevel, Level } from '../../../data/levels/levels';
import { checkBadge } from '../../../data/badges/badge';
import { Badge } from '../../../data/badges/badges.api';
import BadgeToast from '../../../components/BadgeToast';

const Levels = () => {
  const [activeLevel, setActive] = useState(1);
  const { t } = useTranslation();
  const { user } = useUser();
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);

  useEffect(() => {
    const level = getLevel(user.points_total);
    setActive(level?.id ?? 1);
    checkBadge('84a6621d-97d7-4d99-955d-c511ee34a992', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );
  }, []);

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" className="transform rtl:rotate-180" />
          </IonButtons>
          <IonTitle>{t('more.levels.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonContent className="ion-padding" fullscreen>
        <div className="px-4 py-8">
          {levels.map((level, index) => {
            return (
              <div key={index} className="">
                <div
                  className={classNames(
                    'rounded-full flex items-center justify-center mx-auto',
                    index === activeLevel
                      ? 'bg-orange-5 h-20 w-20'
                      : 'bg-brown-20 h-16 w-16'
                  )}
                >
                  <IonIcon
                    src={
                      index <= activeLevel
                        ? level.img
                        : '/assets/levels/unknown.svg'
                    }
                    class={classNames(
                      'ios hydrated',
                      index === activeLevel ? 'text-6xl' : 'text-4xl'
                    )}
                  ></IonIcon>
                </div>
                {index <= activeLevel && (
                  <h2
                    className={classNames(
                      'text-base text-center mt-1',
                      index === activeLevel ? ' ' : 'text-gray-25'
                    )}
                  >
                    {t(level.title)}
                  </h2>
                )}
                {index > activeLevel && index === 1 && (
                  <div className="mt-1 text-center font-slab">
                    <span className="text-xs">{t('more.levels.locked')}</span>
                    <div className="text-xs font-bold">
                      <span>{level.points}</span>
                      <span>{t('general.points')}</span>
                    </div>
                  </div>
                )}
                {index < levels.length - 1 && (
                  <div className="w-px h-10 mx-auto my-4 bg-gray-25"></div>
                )}
              </div>
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Levels;

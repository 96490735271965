import React, { useState, useRef, useEffect } from 'react';

interface Props {
  fields: number;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
}

const CodeVerification: React.FC<Props> = ({
  fields,
  onChange,
  onComplete,
}) => {
  const [value, setValue] = useState<string>('');
  const elements = useRef([...Array(fields)]);

  return (
    <div className="relative space-x-2">
      <input
        type="tel"
        autoComplete="one-time-code"
        className="absolute z-10 w-full h-full px-4 text-transparent bg-transparent outline-none focus:outline-none"
        maxLength={6}
        value={value}
        style={{ caretColor: 'transparent' }}
        onChange={(event) => {
          setValue(event.target.value);
          if (event.target.value.length === 6 && onComplete)
            onComplete(event.target.value);
        }}
      />

      {[...Array(fields)].map((e, index) => (
        <input
          ref={(element) => (elements.current[index] = element)}
          key={index}
          value={value[index] ?? ''}
          readOnly
          className="w-8 py-px text-3xl font-bold text-center rounded-lg pointer-events-none bg-brown-20 focus:outline-none"
        />
      ))}
    </div>
  );
};

export default CodeVerification;

import { Api } from './api';

type inputParams = {
  email: string;
  password: string;
  device_name: string;
};

export type loginResult = {
  token: string;
};

export const login = async (params: inputParams): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: 'token',
      body: params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const logout = async (): Promise<any> => {
  const api = new Api();
  try {
    const response = await api.request({
      type: 'DELETE',
      endpoint: 'token',
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import { Challenge } from './challenge.api';

export const defaultChallengeState = {
  status: 'idle',
  currentQuestion: 0,
};

export type ChallengeActions =
  | { type: 'SHOW_INSTRUCTION'; payload: Challenge }
  | { type: 'SHOW_QUESTION' }
  | {
      type: 'HANDLE_ANSWER';
      payload: { correct: boolean; answerShown: boolean };
    }
  | { type: 'ANSWER_CORRECT' }
  | { type: 'ANSWER_WRONG' }
  | { type: 'END_CHALLENGE' };

export type ChallengeState = {
  status: string;
  currentQuestion: number;
  challenge?: Challenge;
  answer?: { correct: boolean; answerShown: boolean };
};

export function challengeReducer(
  state: ChallengeState,
  action: ChallengeActions
): ChallengeState {
  switch (action.type) {
    case 'SHOW_INSTRUCTION':
      return {
        ...state,
        status: 'instruction',
        challenge: action.payload,
      };
    case 'SHOW_QUESTION':
      return {
        ...state,
        status: 'question',
      };
    case 'ANSWER_CORRECT':
      return {
        ...state,
        status: 'success',
        currentQuestion: state.currentQuestion + 1,
      };
    case 'ANSWER_WRONG':
      return {
        ...state,
        status: 'fail',
      };
    case 'HANDLE_ANSWER':
      return {
        ...state,
        status: 'answer',
        answer: action.payload,
      };
    case 'END_CHALLENGE':
      return {
        ...state,
        status: 'end',
      };
    default:
      return state;
  }
}

import create from 'zustand';

type Labeled = {
  id: number;
  label: string;
};

type Keys =
  | 'token'
  | 'email'
  | 'phone'
  | 'keyAccount'
  | 'city'
  | 'salesPoint'
  | 'name'
  | 'gender'
  | 'password'
  | 'passwordConfirmation'
  | 'birthDate'
  | 'termsChecked'
  | 'pushChecked'
  | 'notificationChecked';

export type RegistrationState = {
  token: string | undefined;
  email: string | null;
  phone: string | null;
  setInvitation: (
    token: string | undefined,
    email: string | null,
    phone: string | null
  ) => void;

  set: (key: Keys, value: any) => void;

  keyAccount?: number;
  salesPoint?: Labeled;
  city?: Labeled;
  name?: string;
  birthDate?: string;
  gender?: string;
  password?: string;
  passwordConfirmation?: string;
  termsChecked?: boolean;
  pushChecked?: boolean;
  notificationChecked?: boolean;

  clear: (key: string) => void;
};

export const useStore = create<RegistrationState>((set) => ({
  token: '',
  email: '',
  phone: '',
  setInvitation: (
    token: string | undefined,
    email: string | null,
    phone: string | null
  ) => set((state) => ({ token: token, email: email, phone: phone })),
  // @ts-expect-error
  set: (key, value) => set((state) => ({ [key]: value })),
  clear: (key) =>
    set((state: any) => {
      delete state[key];
    }),
}));

import React, { useEffect, useState } from 'react';

import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonSlides,
  IonButtons,
  IonBackButton,
  IonSlide,
  IonRouterLink,
  IonIcon,
  IonHeader,
} from '@ionic/react';

import { useTranslation } from 'react-i18next';
import { chevronForward } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router-dom';
import {
  getSalesPoint,
  SalesPoint,
} from '../../../data/salesPoint/salesPoint.api';

type TParams = { id: string };

const SalesPoints: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const { t } = useTranslation();
  const id = match.params.id;
  const [salesPoint, setSalesPoint] = useState<SalesPoint>();

  useEffect(() => {
    getSalesPoint(id).then((response) => setSalesPoint(response));
  }, []);

  const getAverage = () => {
    if (salesPoint && salesPoint.users_points / salesPoint.users_count > 0)
      return salesPoint.users_points / salesPoint.users_count;
    else return 0;
  };

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton mode="md" />
          </IonButtons>
          <IonTitle>{salesPoint?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="h-full px-4 py-6 bg-brown-20">
          <div className="max-w-xl mx-auto">
            <h2 className="text-xl font-bold">{salesPoint?.name}</h2>
            <h3 className="mt-1 text-sm text-gray-50">{salesPoint?.address}</h3>

            <h3 className="mt-8 text-sm text-gray-50">
              {t('salesPoints.single.activity')}
            </h3>
            <div className="grid grid-cols-3 px-4 py-3 mt-2 bg-white rounded-lg shadow">
              <div className="flex flex-col justify-between">
                <span className="text-xs text-gray-50">
                  {t('salesPoints.single.users')}
                </span>
                <span className="mt-1 text-2xl font-bold">
                  {salesPoint?.users_count}
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-xs text-gray-50">
                  {t('salesPoints.single.points')}
                </span>
                <span className="mt-1 text-2xl font-bold">
                  {salesPoint?.users_points}
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <span className="text-xs text-gray-50">
                  {t('salesPoints.single.avgPoints')}
                </span>

                <span className="mt-1 text-2xl font-bold">{getAverage()}</span>
              </div>
            </div>

            <h3 className="mt-8 text-sm text-gray-50">
              {t('salesPoints.single.usersInfo.title')}
            </h3>

            <div className="pb-20 mt-2 space-y-4">
              {salesPoint?.users.map((user) => {
                return (
                  <div
                    key={user.id}
                    className="py-3 bg-white rounded-lg shadow"
                  >
                    <h3 className="px-4 font-medium">{user.name}</h3>
                    <div className="px-4 pt-3 mt-2 border-t border-brown-20">
                      <span className="text-sm font-medium text-gray-50">
                        {t('salesPoints.single.usersInfo.points')}
                      </span>
                      <div className="grid grid-cols-3 mt-2 mb-3 gap-x-6">
                        <span className="text-xs text-gray-50">
                          {t('salesPoints.single.usersInfo.totalPoints')}
                        </span>
                        <span className="text-xs text-gray-50">
                          {t('salesPoints.single.usersInfo.spentPoints')}
                        </span>
                        <span className="text-xs text-gray-50">
                          {t('salesPoints.single.usersInfo.currentPoints')}
                        </span>
                        <span className="text-lg font-bold">
                          {user.points_total}
                        </span>
                        <span className="text-lg font-bold">
                          {user.points_total - user.points}
                        </span>
                        <span className="text-lg font-bold">{user.points}</span>
                      </div>
                      <span className="text-sm font-medium text-gray-50">
                        {t('salesPoints.single.usersInfo.challenges')}
                      </span>
                      <div className="flex items-center mt-2">
                        <IonIcon
                          src="/assets/laurel-orange.svg"
                          className="text-2xl ios hydrated "
                        />
                        <span className="ml-3 text-lg font-bold">
                          {user.challenges_completed}
                        </span>
                        <span className="text-sm font-medium text-gray-50 mx-1.5">
                          od
                        </span>
                        <span className="text-lg font-bold">
                          {user.challenges_total}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SalesPoints;

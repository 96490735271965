import { Api } from '../api';
import qs from 'qs';

export type OrderActions =
  | { type: 'FETCH' }
  | { type: 'CANCEL' }
  | { type: 'REJECT' }
  | { type: 'RESOLVE' };

interface Props {
  filter?: any;
}

export type Order = {
  created_at: Date;
  id: number;
  reward: {
    name: string;
    image: string;
    value: string;
  };
  user: {
    name: string;
  };
  sales_point: {
    name: string;
    address: string;
  };
};

export const postOrder = async (
  id?: number,
  options?: object[]
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: 'order',
      auth: true,
      body: {
        reward_id: id,
        options,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrders = async ({ filter }: Props): Promise<any> => {
  const api = new Api();
  if (filter) {
    filter = '?' + qs.stringify({ filter }, { encode: false });
  }

  try {
    const response = await api.request({
      endpoint: 'order' + filter,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import react, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonToggle,
  IonSelect,
  IonIcon,
  IonSelectOption,
  useIonViewWillLeave,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { language } from 'ionicons/icons';
import { langNames } from '../../auth/Language';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const userSettings: React.FC = () => {
  const [langs, setLangs] = useState<string[]>();
  const { t, i18n } = useTranslation();

  const handleLangChange = (lang: string) => {
    i18n.changeLanguage(lang);
    Storage.set({
      key: 'lang',
      value: JSON.stringify(lang),
    });
  };

  useEffect(() => {
    setLangs(i18n.languages);
    Storage.get({ key: 'lang' }).then((storage) => {
      if (storage.value) {
        const currentLang = JSON.parse(storage.value) ?? [];
        handleLangChange(currentLang);
      }
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/salesman/more"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle>{t('more.settings.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="px-4 mt-6 space-y-6">
          <div className="flex items-center">
            <IonIcon
              icon={language}
              class="ios hydrated text-gray-75 align-middle text-2xl"
            ></IonIcon>
            <span className="flex-grow ml-2 text-base">
              {t('more.settings.lang')}
            </span>
            <IonSelect
              value={i18n.language}
              placeholder={langNames[i18n.language]}
              onIonChange={(e) => handleLangChange(e.detail.value)}
            >
              {langs?.map((lang) => {
                return (
                  <IonSelectOption value={lang} key={lang}>
                    {langNames[lang]}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default userSettings;

// formats date with short (1.jan) or long (1.january)
export const formatDate = (
  date: string | Date,
  month: 'long' | 'short' | 'numeric',
  locale?: string
) => {
  return new Date(date).toLocaleDateString(locale ?? 'sl-SI', {
    month,
    day: 'numeric',
  });
};

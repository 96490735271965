export default {
  tar: {
    title: 'products.filterTar',
    items: [
      {
        title: '9-10 mg',
        text: 'products.tar.strong',
        id: '0',
        value: ['9', '10'],
      },
      {
        title: '6-8 mg',
        text: 'products.tar.moderate',
        id: '1',
        value: ['6', '7', '8'],
      },
      {
        title: '3-5 mg',
        text: 'products.tar.medium',
        id: '2',
        value: ['3', '4', '5'],
      },
      {
        title: '1-2 mg',
        text: 'products.tar.weak',
        id: '3',
        value: ['1', '2'],
      },
    ],
  },
  format: {
    title: 'products.filterFormat',
    items: [
      {
        title: 'King Size',
        text: 'products.format.standard',
        id: '0',
      },
      {
        title: '100’s',
        text: 'products.format.long',
        id: '1',
      },
      {
        title: 'Queen Size',
        text: 'products.format.compact',
        id: '2',
      },
      {
        title: 'Slim',
        text: 'products.format.slim',
        id: '3',
      },
      {
        title: 'Super Slim',
        text: 'products.format.superSlim',
        id: '4',
      },
    ],
  },
  brand: {
    title: 'products.filterBrand',
    items: [],
  },
  tip: {
    title: 'products.filterTip',
    items: [
      {
        image: '/assets/filterTips/Filter-Barva-plute.png',
        text: 'products.tip.brown',
        id: '0',
      },
      {
        image: '/assets/filterTips/Filter-Bel.png',
        text: 'products.tip.white',
        id: '1',
      },
      {
        image: '/assets/filterTips/Filter-Flow.png',
        text: 'products.tip.whiteHole',
        id: '2',
      },
    ],
  },
};

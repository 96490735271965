import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonRouterLink,
  IonToolbar,
  IonTitle,
  IonButton,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { logout } from '../../data/token';
import { useUser, defaultUser } from '../../context/User';
import { useAuth } from '../../context/Auth';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import { getBadges, Badge } from '../../data/badges/badges.api';
import { Article } from '../../data/article/article.api';
import { getLevel, Level } from '../../data/levels/levels';

import {
  chevronForward,
  personCircleOutline,
  settingsOutline,
  mailOutline,
  informationCircleOutline,
  logOutOutline,
  shieldCheckmarkOutline,
  documentTextOutline,
} from 'ionicons/icons';
import { getUser } from '../../data/user';

const More = () => {
  const { t } = useTranslation();
  const { user, setUser, toggleDemo } = useUser();
  const history = useHistory();
  const { setAuthToken } = useAuth();
  const { Storage } = Plugins;
  const [badges, setBadges] = useState<Badge[]>([]);
  const [level, setLevel] = useState<Level>();

  useIonViewWillEnter(() => {
    if ((user.demo ?? false) === true) {
      return;
    }

    let userBadges: string[] = [];

    getUser().then((response) => {
      setUser(response);
      userBadges = response.badges;
    });

    getBadges().then((response) => {
      setBadges(
        response.filter((badge: Badge) => userBadges.includes(badge.id))
      );
    });

    if ((user.demo ?? false) === true) {
      return;
    }
  });

  useEffect(() => {
    const level = getLevel(user.points_total);
    if (level) setLevel(level);
  }, []);

  const handleUndemo = async () => {
    if (user.demo === true) {
      const toggled = await toggleDemo();

      if (!toggled) {
        history.push('/salesman/dashboard');
      }
    }
  };

  const handleLogout = async () => {
    logout().then(() => {
      setUser(defaultUser);
      Storage.remove({ key: 'token' });
      setAuthToken('');
      history.push('/auth/login');
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">
            {t('more.hello')}, {user.name.split(' ')[0]}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="px-4 pt-6 pb-8 bg-brown-20">
          {user.demo === true && (
            <div className="flex flex-col mb-6 overflow-hidden bg-white rounded-lg shadow">
              <div className="px-4 py-1 text-xs font-bold text-white uppercase bg-orange-100">
                {t('more.demoTitle')}
              </div>
              <div className="flex flex-col px-4 py-4 space-y-4">
                <p className="text-sm">{t('more.demoText')}</p>
                <IonButton mode="ios" onClick={handleUndemo}>
                  {t('more.demoCta')}
                </IonButton>
              </div>
            </div>
          )}
          <div className="flex bg-white rounded-lg shadow">
            <div className="px-8 border-r py-9 border-brown-20">
              <span className="text-sm text-gray-50">
                {t('more.currentPoints')}
              </span>
              <div className="flex items-center mt-1">
                <IonIcon
                  src="/assets/coin-stroke.svg"
                  class="ios hydrated text-3xl"
                ></IonIcon>
                <span className="text-2xl font-bold ml-1.5 rtl:mr-1.5">
                  {user?.points}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-col ml-6">
                <span className="text-xs text-gray-50">
                  {t('more.gainedPoints')}
                </span>
                <span className="text-sm font-bold">{user?.points_total}</span>
              </div>
              <div className="flex flex-col ml-5">
                <span className="text-xs text-gray-50">
                  {t('more.usedPoints')}
                </span>
                <span className="text-sm font-bold">
                  {user && user.points_total - user.points}
                </span>
              </div>
            </div>
          </div>

          <h3 className="mt-8 text-sm text-gray-75">
            {t('more.levels.ctaTitle')}
          </h3>
          <IonRouterLink routerLink="/user/more/levels">
            <div className="flex p-3 mt-2 bg-white rounded-lg shadow">
              <div className="flex flex-wrap items-center flex-grow">
                <IonIcon
                  src={level?.img}
                  class="ios hydrated text-2xl"
                ></IonIcon>
                <span className="ml-2 text-base rtl:mr-2 text-gray-75">
                  {t(level?.title ?? '')}
                </span>
                <p className="w-full pl-1 ml-6 text-xs text-gray-75 font-slab">
                  {t('more.levelText')}
                </p>
              </div>
              <IonIcon
                icon={chevronForward}
                class="ios hydrated text-gray-25 text-lg flex-shrink-0 my-auto transform rtl:rotate-180"
              ></IonIcon>
            </div>
          </IonRouterLink>

          <h3 className="mt-8 text-sm text-gray-75">{t('more.badges')}</h3>
          <IonRouterLink routerLink="/user/more/badges">
            <div className="flex p-3 mt-2 bg-white rounded-lg shadow">
              <div className="flex items-center space-x-6 rtl:flex-row-reverse ">
                {badges.slice(0, 4).map((badge) => {
                  return (
                    <img key={badge.id} src={badge.image} className="h-12" />
                  );
                })}
                {badges.length < 4 &&
                  [...Array(4 - badges.length)].map((item, index) => {
                    return (
                      <img
                        key={index}
                        src="/assets/placeholders/badge-empty.png"
                        className="h-12 "
                      />
                    );
                  })}
              </div>
              <IonIcon
                icon={chevronForward}
                class="ios hydrated text-gray-25 text-lg flex-shrink-0 my-auto transform rtl:rotate-180 ml-auto rtl:mr-auto rtl:ml-0"
              ></IonIcon>
            </div>
          </IonRouterLink>
        </div>
        <div className="px-4 py-8 space-y-6">
          <a
            href="https://imb-statamic.stage.cnj.digital/assets/products/Narocniski_list_latest.pdf"
            className="block text-gray-100 "
            target="_blank"
          >
            <IonIcon
              icon={documentTextOutline}
              class="ios hydrated text-gray-75 align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.pricelist')}
            </span>
          </a>

          <IonRouterLink
            routerLink="/user/more/profile"
            className="block text-gray-100 "
          >
            <IonIcon
              icon={personCircleOutline}
              class="ios hydrated text-gray-75 align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.profile.title')}
            </span>
          </IonRouterLink>

          <IonRouterLink
            routerLink="/user/more/contact"
            className="block text-gray-100 "
          >
            <IonIcon
              icon={mailOutline}
              class="ios hydrated text-gray-75 align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.contact.title')}
            </span>
          </IonRouterLink>

          <IonRouterLink
            routerLink="/user/more/settings"
            className="block text-gray-100"
          >
            <IonIcon
              icon={settingsOutline}
              class="ios hydrated text-gray-75 align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.settings.title')}
            </span>
          </IonRouterLink>
        </div>
        <div className="px-4 pt-8 pb-10 space-y-6 border-t border-brown-20">
          {/* about article  */}
          <IonRouterLink
            routerLink="/user/more/about/home"
            className="block text-gray-50"
          >
            <IonIcon
              src="/assets/tobacna.svg"
              class="ios hydrated align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.about')}
            </span>
          </IonRouterLink>
          {/* terms article  */}
          <a
            href="https://imb-statamic.stage.cnj.digital/assets/documents/TobacnaLjubljana-PogojiPoslovanja.pdf"
            className="block text-gray-50"
            target="_blank"
          >
            <IonIcon
              icon={informationCircleOutline}
              class="ios hydrated align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.terms')}
            </span>
          </a>

          {/* privacy article  */}
          <a
            href="https://imb-statamic.stage.cnj.digital/assets/documents/TobacnaLjubljana-PolitikaZasebnosti.pdf"
            className="block text-gray-50"
            target="_blank"
          >
            <IonIcon
              icon={shieldCheckmarkOutline}
              class="ios hydrated align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.privacy')}
            </span>
          </a>

          <div className="text-gray-50" onClick={() => handleLogout()}>
            <IonIcon
              icon={logOutOutline}
              class="ios hydrated align-middle text-2xl"
            ></IonIcon>
            <span className="ml-2 text-base align-middle rtl:mr-2">
              {t('more.logout')}
            </span>
          </div>

          <div className="text-gray-50">
            <span className="text-xs align-middle">
              {t('more.version')}: 1.0.3
            </span>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default More;

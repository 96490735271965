import classNames from 'classnames';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';

interface CardChallengeProps {
  image?: string | null;
  start?: string;
  end?: string;
  points?: number;
  link?: string;
  completed?: boolean;
  className?: string;
}

const CardChallenge: React.FC<CardChallengeProps> = ({
  image,
  start,
  end,
  points,
  link,
  completed = false,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'rounded-lg',
        className,
        completed ? 'opacity-60 shadow ' : 'shadow-lg'
      )}
    >
      <div className="flex px-6 py-3 border-b border-brown-20">
        <div className="">
          <div className="flex items-center">
            <IonIcon src="assets/laurel.svg" />
            <span className="ml-1 text-sm text-gray-50">
              {start} - {end}
            </span>
          </div>
          <div className="flex items-center mt-2">
            <IonIcon src="assets/coin.svg" />
            <span className="ml-1 text-sm text-gray-50">
              {points + ' ' + t('general.points')}
            </span>
          </div>
        </div>

        {image !== null && (
          <img src={image} className="object-contain w-20 ml-auto rounded-md" />
        )}
      </div>
      <div className="py-2.5 rounded-b-lg flex items-center justify-center">
        <IonRouterLink
          class={classNames(
            'ios ion-activatable hydrated text-sm',
            completed ? 'text-gray-75' : 'text-orange-100'
          )}
          href={link}
        >
          {completed
            ? t('dashboard.challenges.done')
            : t('dashboard.challenges.cta') +
              ' ' +
              points +
              ' ' +
              t('general.points')}
        </IonRouterLink>
      </div>
    </div>
  );
};

export default CardChallenge;

import {
  IonContent,
  IonPage,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonFooter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

const PasswordResetCompleted: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start"></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full max-w-xl px-4 py-6 mx-auto">
          <div className="flex flex-col items-center mt-8">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="40" cy="40" r="40" fill="#FEF8F2" />
              <path
                d="M52 40C52 33.375 46.625 28 40 28C33.375 28 28 33.375 28 40C28 46.625 33.375 52 40 52C46.625 52 52 46.625 52 40Z"
                stroke="#F07300"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M39.6412 34.3781L40 42L40.3581 34.3781C40.3603 34.3294 40.3526 34.2807 40.3354 34.2351C40.3181 34.1895 40.2918 34.1478 40.2579 34.1127C40.224 34.0776 40.1834 34.0498 40.1384 34.031C40.0934 34.0121 40.045 34.0026 39.9962 34.0031V34.0031C39.9481 34.0036 39.9004 34.0137 39.8563 34.033C39.8121 34.0523 39.7722 34.0802 39.7391 34.1152C39.706 34.1502 39.6802 34.1915 39.6634 34.2367C39.6466 34.2819 39.6391 34.33 39.6412 34.3781V34.3781Z"
                stroke="#F07300"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M40 46.9944C39.7528 46.9944 39.5111 46.9211 39.3055 46.7837C39.1 46.6464 38.9398 46.4511 38.8452 46.2227C38.7505 45.9943 38.7258 45.743 38.774 45.5005C38.8223 45.258 38.9413 45.0353 39.1161 44.8605C39.2909 44.6857 39.5137 44.5666 39.7561 44.5184C39.9986 44.4702 40.2499 44.4949 40.4784 44.5895C40.7068 44.6841 40.902 44.8444 41.0393 45.0499C41.1767 45.2555 41.25 45.4972 41.25 45.7444C41.25 46.0759 41.1183 46.3938 40.8839 46.6283C40.6495 46.8627 40.3315 46.9944 40 46.9944Z"
                fill="#F07300"
              />
            </svg>

            <h1 className="mt-4 text-3xl font-bold text-center text-gray-100">
              {t('auth.resetCompleted.title')}
            </h1>
            <p className="mt-2 text-base text-center text-gray-75 font-slab">
              {t('auth.resetCompleted.copy')}
            </p>
          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="max-w-xl mx-auto">
        <div className="flex justify-center w-full px-4 py-6 text-center">
          <p className="text-sm text-gray-50">
            {t('auth.resetCompleted.footer')}
          </p>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default PasswordResetCompleted;

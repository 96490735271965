import react, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonToggle,
  IonSelect,
  IonIcon,
  IonSelectOption,
  useIonViewWillLeave,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { saveSettings, getUser } from '../../../data/user';
import { useUser, defaultUser } from '../../../context/User';
import { checkBadge } from '../../../data/badges/badge';
import BadgeToast from '../../../components/BadgeToast';
import { Badge } from '../../../data/badges/badges.api';
import { Plugins } from '@capacitor/core';
import { language } from 'ionicons/icons';
import { langNames } from '../../auth/Language';

const { PushNotifications,Storage } = Plugins;

type userSettingsType = {
  push_notifications: boolean;
  email_notifications: boolean;
  sms_notifications: boolean;
};

const userSettings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [settings, setSettings] = useState<userSettingsType>(
    defaultUser.settings
  );
  const [pushNotification, setPushNotification] = useState<boolean>(false);
  const { user, setUser } = useUser();
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const [langs, setLangs] = useState<string[]>();

  const handleLangChange = (lang: string) => {
    i18n.changeLanguage(lang);
    Storage.set({
      key: 'lang',
      value: JSON.stringify(lang),
    });
  };

  useEffect(() => {
    setSettings(user.settings);
    setLangs(i18n.languages);
    Storage.get({ key: 'lang' }).then((storage) => {
      if (storage.value) {
        const currentLang = (JSON.parse(storage.value) ?? []);
        handleLangChange(currentLang)
      }
    });
    if (Capacitor.isNative) setPushNotification(true);
    toggleCheckBadge();
  }, []);

  const toggleCheckBadge = () => {
    checkBadge('80c9adf9-edc4-4dbd-a69c-c1937582a9cd', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );
  };

  const handleChange = (key: string, value: boolean) => {
    if ((user.demo ?? false) === true) {
      return;
    }

    if (key === 'push_notifications' && value) {
      PushNotifications.requestPermission().then(() => {
        PushNotifications.register();
      });
    }

    if (user.type === 'user' && value) {
      toggleCheckBadge();
    }

    setSettings((prevValues) => {
      return { ...prevValues, [key]: value };
    });

    saveSettings({
      settings: { ...settings, [key]: value },
    })
      .then(() => {
        return getUser();
      })
      .then((response) => setUser(response));
  };

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/more"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle>{t('more.settings.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonContent>
        <div className="px-4 mt-6 space-y-6">
          {pushNotification && (
            <div className="flex items-center">
              <span className="flex-grow text-base">
                {t('more.settings.push')}
              </span>
              <IonToggle
                className="ml-4"
                mode="ios"
                checked={settings?.push_notifications}
                onIonChange={(e) =>
                  handleChange('push_notifications', e.detail.checked)
                }
              />
            </div>
          )}
          <div className="flex items-center">
            <span className="flex-grow text-base">
              {' '}
              {t('more.settings.sms')}
            </span>
            <IonToggle
              className="ml-4"
              mode="ios"
              checked={settings?.sms_notifications}
              onIonChange={(e) =>
                handleChange('sms_notifications', e.detail.checked)
              }
            />
          </div>
          <div className="flex items-center">
            <span className="flex-grow text-base">
              {t('more.settings.email')}
            </span>
            <IonToggle
              className="ml-4"
              mode="ios"
              checked={settings?.email_notifications}
              onIonChange={(e) =>
                handleChange('email_notifications', e.detail.checked)
              }
            />
          </div>
          <div className="flex items-center">
            <IonIcon
              icon={language}
              class="ios hydrated text-gray-75 align-middle text-2xl"
            ></IonIcon>
            <span className="flex-grow ml-2 text-base">
              {t('more.settings.lang')}
            </span>
            <IonSelect
              value={i18n.language}
              placeholder={langNames[i18n.language]}
              onIonChange={(e) => handleLangChange(e.detail.value)}
            >
              {langs?.map((lang) => {
                return (
                  <IonSelectOption value={lang} key={lang}>
                    {langNames[lang]}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default userSettings;

import { Api } from '../api';
import qs from 'qs';

export type Reward = {
  brand_image: string;
  description: string;
  image: string;
  name: string;
  id: number;
  value: number;
  value_sale: number | null;
  notice: string;
  options: {
    key: string;
    label: string;
    list: string[];
  }[];
};

interface Props {
  filter: undefined | any;
}

export const getRewards = async ({ filter }: Props): Promise<any> => {
  const api = new Api();

  if (filter) {
    filter = '?' + qs.stringify({ filter }, { encode: false });
  }

  try {
    const response = await api.request({
      endpoint: 'reward' + filter,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getReward = async (id: string): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      endpoint: `reward/${id}`,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import { ArticleState } from './article.state';
import { ArticleActions } from './article.api';

export function articleReducer(
  state: ArticleState,
  action: ArticleActions
): ArticleState {
  switch (action.type) {
    case 'FETCH':
      return {
        ...state,
        status: 'loading',
      };
    case 'RESOLVE':
      return {
        ...state,
        status: 'success',
        data: action.payload,
      };
    case 'REJECT':
      return {
        ...state,
        status: 'error',
      };
    default:
      return state;
  }
}

import { Api } from './api';

type PasswordResetLinkParams = {
  email: string;
};

type PasswordResetParams = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

export const sendPasswordResetLink = async (
  params: PasswordResetLinkParams
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: 'password-reset-token',
      body: params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const passwordReset = async (
  params: PasswordResetParams
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'DELETE',
      endpoint: 'password-reset-token',
      body: params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import react, { useState, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  IonHeader,
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import CardChallenge from '../../../components/CardChallenge';
import classNames from 'classnames';
import {
  getCampaign,
  Campaign,
  Challenge,
} from '../../../data/challenge/challenge.api';
import { formatDate } from '../../../services/FormatDate';

const Challenges: React.FC = () => {
  const [activeSegment, setActiveSegment] = useState<string>('current');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [activeCampaign, setActiveCampaign] = useState<Campaign>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getCampaign().then((response: Campaign[]) => {
      setActiveCampaign(response[0]);
      response.splice(0, 1);
      setCampaigns(response);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton mode="md" className="transform rtl:rotate-180" />
          </IonButtons>
          <IonTitle>{t('dashboard.challenges.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSegment
          value={activeSegment}
          mode="md"
          onIonChange={(e: any) => {
            setActiveSegment(e.detail.value);
          }}
        >
          <IonSegmentButton value="current" mode="md">
            <span
              className={classNames(
                'text-base normal-case',
                activeSegment === 'current' ? 'text-gray-100' : 'text-gray-50'
              )}
            >
              {t('dashboard.challenges.current')}
            </span>
          </IonSegmentButton>
          <IonSegmentButton value="past" mode="md">
            <span
              className={classNames(
                'text-base normal-case',
                activeSegment === 'past' ? 'text-gray-100' : 'text-gray-50'
              )}
            >
              {t('dashboard.challenges.past')}
            </span>
          </IonSegmentButton>
        </IonSegment>

        {activeSegment === 'current' && (
          <div className="px-4 py-8">
            {activeCampaign && (
              <span className="font-bold">
                {activeCampaign.name} -{' '}
                {formatDate(activeCampaign.start, 'long', i18n.language)} -{' '}
                {formatDate(activeCampaign.end, 'long', i18n.language)}
              </span>
            )}
            <div className="mt-4 space-y-6">
              {activeCampaign?.challenges?.map((challenge, index) => {
                return (
                  <IonRouterLink
                    key={index}
                    routerLink={`/user/challenges/intro/${challenge.id}`}
                    className={classNames('block', {
                      'pointer-events-none': challenge.completed,
                    })}
                  >
                    <CardChallenge
                      image={activeCampaign.brand.logo}
                      points={challenge.points}
                      start={formatDate(challenge.start, 'numeric')}
                      end={formatDate(challenge.end, 'numeric')}
                      completed={challenge.completed}
                    />
                  </IonRouterLink>
                );
              })}
            </div>
          </div>
        )}

        {activeSegment === 'past' &&
          campaigns.map((campaign, index) => {
            return (
              <div className="px-4 py-8">
                <span className="font-bold">
                  {campaign.name} -{' '}
                  {formatDate(campaign.start, 'long', i18n.language)} -{' '}
                  {formatDate(campaign.end, 'long', i18n.language)}
                </span>
                <div className="mt-4 space-y-6">
                  {campaign.challenges?.map((challenge, index) => {
                    return (
                      <IonRouterLink
                        key={index}
                        routerLink={`/user/challenges/intro/${challenge.id}`}
                        className={classNames('block', {
                          'pointer-events-none': challenge.completed,
                        })}
                      >
                        <CardChallenge
                          image={campaign.brand.logo}
                          points={challenge.points}
                          start={formatDate(challenge.start, 'numeric')}
                          end={formatDate(challenge.end, 'numeric')}
                          completed={challenge.completed}
                        />
                      </IonRouterLink>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </IonContent>
    </IonPage>
  );
};

export default Challenges;

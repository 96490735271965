import {
  IonContent,
  IonPage,
  IonButton,
  IonFooter,
  IonHeader,
  IonRouterLink,
  useIonViewWillEnter,
} from '@ionic/react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/Auth';
import { useEffect, useState } from 'react';
import { useUser } from '../context/User';

const Home: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const [list, setList] = useState<{ title: string }[]>([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const { user } = useUser();

  useIonViewWillEnter(() => {
    setList(t('ageGate.list', { returnObjects: true }));
  });

  useEffect(() => {
    if (auth.authToken.length > 0 && user.id !== -1) {
      setLoggedIn(true);
    }
  }, [user, auth]);

  const redirect = () => {
    history.push('/auth/invite-code');
  };

  if (loggedIn) {
    if (user.type === 'salesman') {
      return <Redirect to="/salesman/dashboard" />;
    } else if (user.type === 'user') {
      return <Redirect to="/user/dashboard" />;
    }
  }

  return (
    <IonPage>
      <IonHeader mode="ios"></IonHeader>
      <IonContent>
        <div className="flex flex-col h-full max-w-xl px-4 py-6 mx-auto">
          <div className="flex flex-col justify-center px-4">
            <h1 className="text-3xl font-bold text-gray-100">
              {t('ageGate.title')}
            </h1>
            <div className="mt-3 text-base text-gray-75">
              {/* <ul className="mb-8 ml-8 text-lg text-gray-100 list-disc">
                {t('ageGate.title') !== 'ageGate.title' &&
                  list.map((item, index: number) => {
                    return <li key={index}>{item.title}</li>;
                  })}
              </ul> */}
              <ul className="mb-8 ml-8 text-lg text-gray-100 list-disc">
                <li>{t('ageGate.list.title1')}</li>
                <li>{t('ageGate.list.title2')}</li>
                <li>{t('ageGate.list.title3')}</li>
              </ul>

              <p className="font-slab">{t('ageGate.copy')}</p>
              <a
                className="block mt-2 text-orange-100 font-slab"
                href="https://imb-statamic.stage.cnj.digital/assets/documents/TobacnaLjubljana-PogojiPoslovanja.pdf"
                target="_blank"
              >
                {t('ageGate.terms')}
              </a>
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="max-w-xl mx-auto">
        <div className="flex w-full px-4 py-6">
          <IonButton mode="ios" onClick={redirect} className="w-full">
            {t('ageGate.cta')}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Home;

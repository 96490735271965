import React, { useState, useEffect, useReducer } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonRouterLink,
  IonToast,
  IonFooter,
  IonIcon,
  IonButton,
} from '@ionic/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Heading from '../../../components/Heading';
import CardChallengeBig from '../../../components/CardChallengeBig';
import { getReward } from '../../../data/rewards/rewards.api';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { postOrder } from '../../../data/order/order.api';
import { orderReducer } from '../../../data/order/order.reducer';
import { defaultOrderState } from '../../../data/order/order.state';
import type { Reward } from '../../../data/rewards/rewards.api';
import { useUser } from '../../../context/User';
import {
  getSalesPoint,
  SalesPoint,
} from '../../../data/salesPoint/salesPoint.api';
import { getChallenge, Challenge } from '../../../data/challenge/challenge.api';
import { formatDate } from '../../../services/FormatDate';

type Props = {
  id?: string;
};
type Item = {
  key: string;
  value: string;
};

const SingleReward: React.FC<RouteComponentProps<Props>> = ({ match }) => {
  const { t } = useTranslation();
  const id = match.params.id;
  const [reward, setReward] = useState<Reward>();
  const { user } = useUser();
  const [points, setPoints] = useState<number>(0);
  const [enoughPoints, setEnoughPoints] = useState<Boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [salesPoint, setSalesPoint] = useState<SalesPoint>();
  const [challenge, setChallenge] = useState<Challenge>();
  const [{ status }, dispatch] = useReducer(orderReducer, defaultOrderState);
  const history = useHistory();

  useEffect(() => {
    getReward(id ?? '').then((response) => {
      setReward(response);
    });
    getSalesPoint(user.sales_point_id).then((response) =>
      setSalesPoint(response)
    );
    getChallenge()
      .then((response) => setChallenge(response))
      .catch((error: any) => {
        setChallenge(undefined);
      });
  }, [id]);
  useEffect(() => {
    setPoints(user?.points);
    if (
      (reward !== undefined &&
        reward.value_sale !== null &&
        reward.value_sale <= user.points) ||
      (reward !== undefined &&
        reward.value_sale === null &&
        reward.value <= user.points)
    ) {
      setEnoughPoints(true);
    }
  }, [user, reward]);

  const orderReward = () => {
    if ((user.demo ?? false) === true) {
      dispatch({ type: 'RESOLVE' });
      history.push('/user/success');
      return;
    }

    postOrder(reward?.id, selectedItems.length > 0 ? selectedItems : undefined)
      .then((response) => {
        dispatch({ type: 'RESOLVE' });
        history.push('/user/success');
      })
      .catch((e) => {
        dispatch({ type: 'REJECT' });
      });
  };
  const selectItem = (item: string, key: string) => {
    const items = [...selectedItems];
    const currentKeyId = items.findIndex((item) => item.key === key);
    if (currentKeyId > -1) items.splice(currentKeyId, 1);
    items.push({ key, value: item });
    setSelectedItems(items);
  };

  const calculatePoints = () => {
    if (reward && reward.value_sale !== null) return reward.value_sale - points;
    else if (reward) return reward.value - points;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/academy"
              className="transform rtl:-rotate-180"
            />
          </IonButtons>
          <IonTitle>{reward?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="border-b border-brown-20">
          <img src={reward?.image} alt="" />
        </div>
        {reward?.notice && (
          <div className="bg-orange-100 text-sm text-center py-1.5 text-white">
            {reward?.notice}
          </div>
        )}
        <div className="px-4 mt-5">
          <img className="w-20" src={reward?.brand_image} alt="" />
          <h1 className="mt-3 text-2xl font-bold text-gray-100">
            {reward?.name}
          </h1>
          <div
            className="mt-2 prose-sm prose font-slab"
            dangerouslySetInnerHTML={{ __html: reward?.description || '' }}
          ></div>

          <div className="flex items-center justify-between mt-4">
            <div className="flex space-x-2">
              <IonIcon
                src="/assets/coin-stroke.svg"
                class="ios hydrated text-3xl"
              ></IonIcon>
              <div
                className={cx(
                  'font-medium text-gray-100',
                  reward?.value_sale ? 'line-through' : ''
                )}
              >
                {reward?.value} {t('general.points')}
              </div>
              {reward?.value_sale && (
                <span className="ml-1 font-medium text-orange-100">
                  {reward?.value_sale} {t('general.points')}
                </span>
              )}
            </div>
            <div className="text-sm font-medium text-gray-50">
              {enoughPoints ? (
                <>{t('rewards.enoughPoints')}</>
              ) : (
                <>
                  {t('rewards.notEnoughPoints')} {calculatePoints()}{' '}
                  {t('general.points')}
                </>
              )}
            </div>
          </div>

          {enoughPoints && (
            <>
              {reward?.options.map((option) => {
                return (
                  <div key={option.key} className="mt-10">
                    <h2 className="text-sm font-medium text-gray-50">
                      {option.label}
                    </h2>
                    <div className="flex flex-wrap mt-2 space-x-2">
                      {option.list.map((item) => (
                        <div
                          key={item}
                          className={cx(
                            'flex items-center justify-center w-12 h-12 text-base uppercase rounded-lg text-gray-75 border transition-all duration-300 overflow-hidden',
                            selectedItems.findIndex(
                              (selected) => selected.value === item
                            ) < 0
                              ? 'bg-brown-20 border-transparent'
                              : 'border-orange-100 bg-white shadow'
                          )}
                          onClick={() => selectItem(item, option.key)}
                        >
                          {option.key === 'Color' ? (
                            <div
                              className="inset-0 w-full h-full aboslute"
                              style={{ background: item }}
                            ></div>
                          ) : (
                            item
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
              <div className="px-4 py-3 mt-10 rounded-lg bg-brown-20">
                <div className="text-sm text-center text-gray-75">
                  {t('rewards.delivery')}
                </div>
                <div className="mt-2 text-base font-medium text-center text-gray-100">
                  {salesPoint?.name}, {salesPoint?.address}
                </div>
                {/* <div className="flex justify-center mt-4 space-x-2 text-sm">
                  <div>Nisi več na tej lokaciji?</div>
                  <IonRouterLink className="font-medium">
                    Posodobi prodajno mesto
                  </IonRouterLink>
                </div> */}
              </div>
            </>
          )}
        </div>
        {!enoughPoints && (
          <>
            <div className="mt-10 bg-brown-20">
              <div className="px-4 py-6">
                <Heading> {t('rewards.challengeTitle')}</Heading>
                <div className="mt-2 text-sm text-gray-75">
                  <p>{t('rewards.challengeSubtitle')}</p>
                </div>
                {challenge && (
                  <IonRouterLink
                    routerLink={`/user/challenges/intro/${challenge.id}`}
                    className={cx(
                      'block mt-6',
                      !challenge.completed ? '' : 'pointer-events-none'
                    )}
                  >
                    <CardChallengeBig
                      image={challenge.campaign.image}
                      quote={challenge.campaign.message ?? ''}
                      points={challenge.points}
                      start={formatDate(challenge.start, 'numeric')}
                      end={formatDate(challenge.end, 'numeric')}
                      completed={challenge.completed}
                      className="bg-white"
                    />
                  </IonRouterLink>
                )}
              </div>
            </div>
            <div className="mt-10"></div>
          </>
        )}
        <IonToast
          isOpen={status === 'error'}
          onDidDismiss={() => dispatch({ type: 'CANCEL' })}
          message="Prislo je do napake"
          duration={2000}
        />
      </IonContent>
      {enoughPoints && (
        <IonFooter mode="ios">
          <div className="px-4 py-4">
            <IonButton mode="ios" expand="block" onClick={() => orderReward()}>
              {t('rewards.cta')}
            </IonButton>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default SingleReward;

import {
  IonPage,
  IonIcon,
  IonContent,
  IonFooter,
  IonButton,
  useIonViewWillLeave,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import {
  completeChallenge,
  getChallenge,
  Challenge,
} from '../../../data/challenge/challenge.api';
import Notice from '../../../components/Notice';
import { useUser } from '../../../context/User';
import { getUser } from '../../../data/user';
import { checkBadge } from '../../../data/badges/badge';
import { Badge } from '../../../data/badges/badges.api';
import BadgeToast from '../../../components/BadgeToast';

type TParams = { id: string };

const End: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const id = match.params.id;
  const { t } = useTranslation();
  const [challenge, setChallenge] = useState<Challenge>();
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const { user, setUser } = useUser();
  const [notice, setNotice] = useState<Boolean>(true);
  const history = useHistory();

  const data = {
    notice: {
      title: t('dashboard.notice.title'),
      text: t('dashboard.notice.text'),
      cta: t('dashboard.notice.cta'),
    },
  };

  useEffect(() => {
    getChallenge(id).then((response) => setChallenge(response));

    if ((user.demo ?? false) === true) {
      return;
    }

    if (
      user.settings &&
      ((user.settings.push_notifications && Capacitor.isNative) ||
        user.settings.email_notifications ||
        user.settings.sms_notifications)
    )
      setNotice(false);
    else setNotice(true);

    checkBadge('0e1e31be-cfdf-4557-b104-86ce0d2c0de6', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );
    completeChallenge(id)
      .then(() => {
        return getUser();
      })
      .then((response) => setUser(response))
      .catch((error: any) => {
        history.push('/user/dashboard');
      });
  }, [match.params.id]);

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonContent fullscreen>
        <div
          className="px-4 pt-20 bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: 'url(/assets/leaf-challenge.svg)' }}
        >
          {challenge?.campaign.brand.logo && (
            <img
              src={challenge?.campaign.brand.logo}
              className="h-12 mx-auto"
            />
          )}
          <h2 className="mx-auto mt-12 text-3xl font-bold text-center">
            {t('challenges.end.title')}
          </h2>
          <p className="mt-2 text-center text-gray-75">
            {t('challenges.end.title')}
          </p>
          <div className="flex items-center justify-center mt-6">
            <IonIcon
              src="assets/coin-stroke.svg"
              class="ios hydrated text-5xl"
            />
            <div className="flex items-baseline">
              <span className="ml-1 text-3xl font-bold">
                {challenge?.points + ' '}
              </span>
              <span className="text-gray-50 ml-0.5">{t('general.points')}</span>
            </div>
          </div>
        </div>
        <div className="px-4">
          {notice && (
            <Notice
              title={data.notice.title}
              text={data.notice.text}
              cta={data.notice.cta}
              link="/user/more/settings"
              className="mt-10 bg-white"
              onClose={() => setNotice(false)}
            />
          )}
        </div>
      </IonContent>
      <IonFooter>
        <IonButton
          mode="ios"
          expand="block"
          className="mx-4 my-6"
          routerLink={`/user/dashboard/challenges`}
        >
          {t('challenges.end.cta')}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default End;

import { Route } from 'react-router-dom';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import AuthRoute from './AuthRoute';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility';

/* Pages */
import Home from './pages/Home';
import UserTabs from './pages/user/Tabs';
import Salesman from './pages/salesman/Tabs';
import Dashboard from './pages/user/Dashboard';
import Login from './pages/auth/Login';
import InviteCode from './pages/auth/InviteCode';
import RegisterKeyAccount from './pages/auth/RegisterKeyAccount';
import RegisterLocation from './pages/auth/RegisterLocation';
import RegisterUserInformation from './pages/auth/RegisterUserInformation';
import Onboarding from './pages/auth/Onboarding';
import Single from './pages/user/academy/Single';
import PasswordReset from './pages/auth/PasswordReset';
import PasswordResetLink from './pages/auth/PasswordResetLink';
import PasswordResetSent from './pages/auth/PasswordResetSent';
import RegisterLoginInformation from './pages/auth/RegisterLoginInformation';
import PasswordResetCompleted from './pages/auth/PasswordResetCompleted';
import Language from './pages/auth/Language';

import { AuthContext } from './context/Auth';
import { UserContext, defaultUser } from './context/User';
import { User } from './data/user';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

// /* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

import './theme/tailwind.css';
import './theme/fonts.css';

/* Theme variables */
import './theme/variables.css';
import './theme/ion-components.css';
import { useTranslation } from 'react-i18next';

const { Storage, StatusBar } = Plugins;
export const rtlLangs = ['ar', 'ur'];

const App: React.FC = () => {
  if (Capacitor.isPluginAvailable('StatusBar')) {
    // @ts-expect-error
    StatusBar.setStyle(StatusBarStyle.Dark);
  }

  MobileAccessibility.usePreferredTextZoom(false);

  const [authToken, setAuthToken] = useState('');
  const [user, setUser] = useState(defaultUser);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (rtlLangs.includes(i18n.language))
      document.documentElement.style.setProperty('direction', 'rtl');

    Storage.get({ key: 'lang' }).then((storage) => {
      if (storage.value) {
        const currentLang = JSON.parse(storage.value) ?? [];
        i18n.changeLanguage(currentLang);
      }
    });

    async function fetch() {
      await Storage.get({ key: 'token' }).then((storage) => {
        setAuthToken(storage.value ?? '');
      });

      await Storage.get({ key: 'user' }).then((storage) => {
        if (storage.value) {
          setUser(JSON.parse(storage.value));
        }
      });
    }

    fetch();
  }, []);

  const setToken = (data: string) => {
    Storage.set({
      key: 'token',
      value: data,
    });
    setAuthToken(data);
  };

  const setUserData = (data: User) => {
    data = { ...data, demo: false };

    Storage.set({
      key: 'user',
      value: JSON.stringify(data),
    });
    setUser(data);
  };

  const handleToggleDemo = async (): Promise<boolean> => {
    // toggle between demo mode
    // We have to copy the current user credentials into a different key

    const storageUser = await Storage.get({ key: 'user' });

    if (!storageUser.value) {
      return false;
    }

    const currentUser: User = JSON.parse(storageUser.value);

    if (currentUser.demo === false) {
      const demoUser = {
        email: 'prodajalec@example.org',
        points: 7000,
        points_total: 10000,
        type: 'user',
        name: 'Janez Novak',
        id: 1,
        sales_point_id: 3,
        challenges_completed: 0,
        challenges_total: 0,
        demo: true,
        phone: '',
        badges: [],
        salesman: {
          name: 'Example',
          phone: '0123123',
        },
        settings: {
          push_notifications: false,
          email_notifications: false,
          sms_notifications: false,
        },
      };

      Storage.set({
        key: 'currentUser',
        value: storageUser.value,
      });

      Storage.set({
        key: 'user',
        value: JSON.stringify(demoUser),
      });

      setUser(demoUser);

      return true;
    } else {
      const storageCurrentUser = await Storage.get({ key: 'currentUser' });

      if (!storageCurrentUser.value) {
        return false;
      }

      const currentUser: User = JSON.parse(storageCurrentUser.value);

      Storage.set({
        key: 'user',
        value: storageCurrentUser.value,
      });

      setUser(currentUser);

      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      <UserContext.Provider
        value={{
          user,
          setUser: setUserData,
          toggleDemo: handleToggleDemo,
        }}
      >
        <IonApp dir={rtlLangs.includes(i18n.language) ? 'rtl' : 'ltr'}>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/auth/login" component={Login} />
              <Route
                path="/auth/password-reset-link"
                component={PasswordResetLink}
              />
              <Route
                path="/auth/password-reset-sent"
                component={PasswordResetSent}
              />
              <Route
                path="/auth/password-reset-completed"
                component={PasswordResetCompleted}
              />
              <Route path="/auth/password-reset" component={PasswordReset} />
              <Route path="/auth/onboarding" component={Onboarding} />
              <Route path="/auth/invite-code" component={InviteCode} />
              <Route
                path="/auth/register-key-account"
                component={RegisterKeyAccount}
              />
              <Route
                path="/auth/register-location"
                component={RegisterLocation}
              />
              <Route
                path="/auth/register-user-information"
                component={RegisterUserInformation}
              />
              <Route
                path="/auth/register-login-information"
                component={RegisterLoginInformation}
              />
              <AuthRoute path="/user" component={UserTabs} />
              <AuthRoute path="/salesman" component={Salesman} />
              <Route exact path="/" component={Language} />
              <Route exact path="/home" component={Home} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;

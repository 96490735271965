import cx from 'classnames';
import { IonIcon, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

interface MessageProp {
  image?: string;
  text?: string;
  className?: string;
}

const Message: React.FC<MessageProp> = ({ image, text, className }) => {
  const { t } = useTranslation();
  const [writtingMessage, setWrittingMessage] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setWrittingMessage(false), 1500);
  }, []);
  return (
    <div className="flex px-4 py-2.5">
      <img src="/assets/challenge/person.png" className="w-10 h-10" />
      <div
        className={cx(
          'ml-3 rounded-lg bg-gray-5',
          writtingMessage ? 'flex px-3 py-2 items-center' : 'p-3'
        )}
      >
        {writtingMessage ? (
          <IonSpinner name="dots" />
        ) : (
          <p
            className="text-base"
            dangerouslySetInnerHTML={{ __html: text || '' }}
          ></p>
        )}
      </div>
    </div>
  );
};

export default Message;

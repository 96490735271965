import { Api } from '../api';

export type SupportActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

export const defaultSupportState = { status: 'idle' };

export type SupportState = {
  status: string;
};

type messageInput = {
  message: string;
};

export const sendMessage = async (message: messageInput): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      type: 'POST',
      endpoint: 'support',
      auth: true,
      body: message,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import {
  IonPage,
  IonIcon,
  IonContent,
  IonFooter,
  IonButton,
  useIonViewDidEnter,
} from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../context/User';
import { getUser } from '../../../data/user';

const Success = () => {
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  useIonViewDidEnter(() => {
    if (!(user.demo ?? false)) {
      getUser().then((response) => setUser(response));
    }
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="px-8 pt-16">
          <div className="flex items-center justify-center w-20 h-20 mx-auto rounded-full bg-orange-5">
            <IonIcon src="assets/confetti.svg" class="ion hydrated text-4xl " />
          </div>
          <h1 className="mt-4 text-3xl font-bold text-center">
            {t('rewards.success.title')}
          </h1>
          <p className="mt-2 text-base text-center text-gray-75 font-slab">
            {t('rewards.success.text')}
          </p>
        </div>
      </IonContent>
      <IonFooter className="px-4">
        <IonButton routerLink="/user/rewards" mode="ios" expand="block">
          {t('rewards.success.cta')}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Success;

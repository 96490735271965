import react, { useEffect, useState } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonRouterLink,
  IonHeader,
  IonButtons,
  IonBackButton,
  useIonViewWillLeave,
  useIonViewDidEnter,
} from '@ionic/react';
import Notification from '../../../components/Notification';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, RouteComponentProps } from 'react-router-dom';
import filters from '../../../data/products/filters';
import { getProduct } from '../../../data/products/products.api';
import { checkBadge } from '../../../data/badges/badge';
import { Badge } from '../../../data/badges/badges.api';
import BadgeToast from '../../../components/BadgeToast';

import type { Product } from '../../../data/products/products.api';
import { useUser } from '../../../context/User';

type Props = {
  id?: string;
};

const ProductSingle: React.FC<RouteComponentProps<Props>> = ({ match }) => {
  const { t } = useTranslation();
  const id = match.params.id;
  const history = useHistory<{ product?: any }>();
  const [product, setProduct] = useState<Product>();
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);
  const { user } = useUser();

  useEffect(() => {
    if (id) getProduct(id).then((response) => setProduct(response));
  }, [id]);

  useEffect(() => {
    checkBadge('5d940ec5-d247-4513-8c66-3ff049735205', user.badges).then(
      (response) => {
        if (response) {
          setBadge(response);
          setBadgeToastVisible(true);
        }
      }
    );
  }, []);

  const getFormatName = (id: string | undefined) => {
    return filters.format.items.find((item) => item.id === id)?.title;
  };

  const getFilterImage = (id: string | undefined) => {
    return filters.tip.items.find((item) => item.id === id)?.image;
  };

  const getFilterName = (id: string | undefined) => {
    return filters.tip.items.find((item) => item.id === id)?.text;
  };

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton mode="md" className="transform rtl:rotate-180" />
          </IonButtons>
          <IonTitle>{product?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-xl mx-auto">
          <img src={product?.image} />
          <div className="px-4 border-t border-brown-20">
            <h1 className="mt-4 text-2xl font-bold">{product?.name}</h1>
            <p
              className="mt-1 text-sm font-slab"
              dangerouslySetInnerHTML={{ __html: product?.description || '' }}
            ></p>
            <div className="flex flex-wrap mt-4 mb-8 space-y-6">
              {typeof product?.price === 'number' && product.price > 0 && (
                <div className="flex flex-col w-full mr-6">
                  <span className="text-sm text-gray-50">
                    {t('products.single.price')}
                  </span>
                  <span className="mt-1 text-xl font-bold text-orange-100">
                    {product?.price.toLocaleString('sl-SI', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' €'}
                  </span>
                </div>
              )}
              {product?.options.tar && (
                <div className="flex flex-col mr-4">
                  <span className="text-sm text-gray-50">
                    {t('products.single.tar')}
                  </span>
                  <span className="mt-1 text-xl font-bold text-gray-100">
                    {product?.options.tar + ' mg'}
                  </span>
                </div>
              )}
              {product?.options.nicotine && (
                <div className="flex flex-col mx-4">
                  <span className="text-sm text-gray-50">
                    {t('products.single.nicotine')}
                  </span>
                  <span className="mt-1 text-xl text-gray-100">
                    {product?.options.nicotine + ' mg'}
                  </span>
                </div>
              )}
              {product?.options.co && (
                <div className="flex flex-col mx-4">
                  <span className="text-sm text-gray-50">
                    {t('products.single.co')}
                  </span>
                  <span className="mt-1 text-xl text-gray-100">
                    {product?.options.co + ' mg'}
                  </span>
                </div>
              )}
              {product?.options.format && (
                <div className="flex flex-col w-full">
                  <span className="text-sm text-gray-50">
                    {t('products.single.format')}
                  </span>
                  <span className="mt-1 text-xl text-gray-100 ">
                    {getFormatName(product?.options.format)}
                  </span>
                </div>
              )}
              {product?.options.filter && (
                <div className="flex flex-col w-full">
                  <span className="text-sm text-gray-50">
                    {t('products.single.filter')}
                  </span>
                  <span className="flex items-center mt-2 text-sm text-gray-100 ">
                    <img
                      src={getFilterImage(product?.options.filter)}
                      className="h-4 mr-3"
                    />
                    {t(getFilterName(product?.options.filter) ?? '')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProductSingle;

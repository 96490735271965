import react, { useState, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonIcon,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonRouterLink,
  useIonViewWillLeave,
} from '@ionic/react';
import Card from '../../../components/Card';
import Heading from '../../../components/Heading';
import { useTranslation } from 'react-i18next';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { chevronForward } from 'ionicons/icons';
import { getProducts } from '../../../data/products/products.api';
import filters from '../../../data/products/filters';
import { getBrand, getBrands } from '../../../data/brands/brands.api';

import type { Brand } from '../../../data/brands/brands.api';
import type { Product } from '../../../data/products/products.api';
import { useUser } from '../../../context/User';
import { Plugins } from '@capacitor/core';
import BadgeToast from '../../../components/BadgeToast';
import { Badge } from '../../../data/badges/badges.api';
import { checkBadge } from '../../../data/badges/badge';

type Props = {
  id?: string;
};

const SingleBrand: React.FC<RouteComponentProps<Props>> = ({ match }) => {
  const history = useHistory<{ brand?: any }>();
  const { t } = useTranslation();
  const id = match.params.id;
  const [brand, setBrand] = useState<Brand>();
  const [products, setProducts] = useState<Product[]>([]);
  const { user } = useUser();
  const { Storage } = Plugins;
  const [badge, setBadge] = useState<Badge>();
  const [badgeToastVisible, setBadgeToastVisible] = useState<Boolean>(false);

  useEffect(() => {
    if (id) {
      getBrand(id)
        .then((response: Brand) => {
          setBrand(response);
          return getProducts({
            filter: { brand_id: response.id },
          });
        })
        .then((response: Product[]) => {
          setProducts(response);
        });

      /// visited brands
      Storage.get({ key: 'visitedBrands' }).then(async (storage) => {
        const brands = await getBrands();
        const visitedBrands = storage.value ? JSON.parse(storage.value) : [];
        if (!visitedBrands.includes(id)) {
          Storage.set({
            key: 'visitedBrands',
            value: JSON.stringify([...visitedBrands, id]),
          });
        } else if (visitedBrands.length === brands.length) {
          checkBadge('69b74854-11fc-41a9-a6d6-5eb031893775', user.badges).then(
            (response) => {
              if (response) {
                setBadge(response);
                setBadgeToastVisible(true);
              }
            }
          );
        }
      });
    }
  }, [id]);

  const getFormat = (id: string | undefined) => {
    return filters.format.items.find((item) => item.id === id)?.title;
  };

  useIonViewWillLeave(() => {
    setBadgeToastVisible(false);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              mode="md"
              defaultHref="/user/academy"
              className="transform rtl:rotate-180"
            />
          </IonButtons>
          <IonTitle>{brand?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <BadgeToast
        badgeName={badge?.name}
        title={badge?.name}
        text={badge?.description}
        image={badge?.image}
        visible={badgeToastVisible}
        id={badge?.id}
        onClose={() => setBadgeToastVisible(false)}
      />
      <IonContent className="px-4">
        <div className="max-w-xl mx-auto">
          <div className="px-4 mt-6">
            <div className="relative overflow-hidden rounded-lg">
              <img src={brand?.background} alt="" />
            </div>
          </div>
          <div className="flex flex-col items-center px-8 mt-6 text-center">
            <div className="text-orange-100">
              <img className="w-8 h-8" src="/assets/laurel.svg" alt="" />
            </div>
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{ __html: brand?.lead || '' }}
            ></div>
          </div>
          <div className="flex justify-around px-8 mt-4">
            {brand?.list?.map((item, index) => {
              return (
                <div key={index} className="flex space-x-2">
                  <div className="">
                    <img src={item.icon} />
                  </div>
                  <div className="">{item.label}</div>
                </div>
              );
            })}
          </div>
          {user.type === 'user' && (
            <IonRouterLink routerLink="/user/dashboard/challenges">
              <div className="px-4 mt-10 ">
                <div className="flex overflow-hidden rounded-lg bg-brown-20">
                  <img
                    className="w-1/4 h-full"
                    src="/assets/placeholders/nagrajujmo_znanje.png"
                  />
                  <div className="py-5 pl-6 pr-4">
                    <h3 className="font-bold text-brown-100">
                      {t('academy.brand.challangeTitle')}
                    </h3>
                    <p className="text-sm text-gray-75 font-slab">
                      {t('academy.brand.challangeText')}
                    </p>
                  </div>
                  <div className="flex items-center pr-3 text-gray-25">
                    <IonIcon
                      icon={chevronForward}
                      class="ios hydrated text-gray-25 flex-shrink-0 rtl:rotate-180 transform"
                    ></IonIcon>
                  </div>
                </div>
              </div>
            </IonRouterLink>
          )}

          <div className="px-4 mt-10">
            <Heading>{t('academy.brand.productsTitle')}</Heading>
            <div className="grid grid-cols-2 mt-4 gap-x-2 gap-y-6">
              {products.map((product, index) => {
                return (
                  <IonRouterLink
                    key={product.id}
                    routerLink={`/${user.type}/products/${product.id}`}
                  >
                    <Card
                      image={product.image}
                      title={product.name}
                      price={product.price}
                      options={{
                        tar: product.options.tar,
                        format: getFormat(product.options.format),
                      }}
                      type="product"
                    />
                  </IonRouterLink>
                );
              })}
            </div>
          </div>

          {brand?.content && (
            <div className="px-4 mt-10">
              <Heading>{t('academy.brand.triviaTitle')}</Heading>
              <div
                className="mt-2 prose-sm prose font-slab"
                dangerouslySetInnerHTML={{ __html: brand?.content || '' }}
              ></div>
            </div>
          )}
          <div className="mb-10"></div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SingleBrand;

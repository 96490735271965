import React, { useEffect, useRef, useState } from 'react';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { Route, Redirect, useLocation, useHistory } from 'react-router';
import {
  home,
  bagHandle,
  search,
  book,
  grid,
  homeOutline,
  bagHandleOutline,
  bookOutline,
  searchOutline,
  gridOutline,
} from 'ionicons/icons';
import Dashboard from './Dashboard';
import Rewards from './Rewards';
import SingleReward from './rewards/Single';
import Academy from './Academy';
import Brand from './academy/Brand';
import News from './academy/News';
import Single from './academy/Single';
import Products from './Products';
import ProductSingle from './product/Single';
import Notifications from './dashboard/Notifications';
import Challenges from './dashboard/Challenges';
import More from './More';
import Levels from './more/Levels';
import Badges from './more/Badges';
import Contact from './more/Contact';
import Profile from './more/Profile';
import { useTranslation } from 'react-i18next';
import Challenge from './challenges/Challenge';
import Intro from './challenges/Intro';
import End from './challenges/End';
import Success from './rewards/Success';
import BadgeSingle from './more/BadgeSingle';
import classNames from 'classnames';
import Settings from './more/Settings';
import Wheel from './dashboard/Wheel';
import cx from 'classnames';

interface UserProps {}

const User: React.FC<UserProps> = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('dashboard');
  const [tabsHidden, setTabsHidden] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const tabs = useRef<IonTabs>(null);

  useEffect(() => {
    setSelectedTab(tabs.current?.ionTabContextState.activeTab ?? '');
    // history.replace(`/${tabs.current?.ionTabContextState.activeTab ?? ''}`);
    if (
      location.pathname.includes('/wheel') ||
      location.pathname.includes('/user/challenges')
    ) {
      setTabsHidden(true);
    } else setTabsHidden(false);
  }, [location]);

  const handleTabWillChange = (e: any) => {
    if (e.detail.tab === 'dashboard') {
      // onClick stuff
      history.push('/user/dashboard');
    }
  };

  return (
    <IonTabs ref={tabs}>
      <IonRouterOutlet>
        <Redirect exact={true} path="/user" to="/user/dashboard" />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        <Route
          path="/user/dashboard"
          render={() => <Dashboard />}
          exact={true}
        />
        <Route
          path="/user/dashboard/notifications"
          render={() => <Notifications />}
          exact={true}
        />
        <Route path="/user/wheel" render={() => <Wheel />} exact={true} />
        <Route
          path="/user/dashboard/challenges"
          render={() => <Challenges />}
          exact={true}
        />
        <Route
          path="/user/challenges/intro/:id"
          component={Intro}
          exact={true}
        />
        <Route path="/user/challenges/end/:id" component={End} exact={true} />
        <Route
          path="/user/challenges/challenge/:id"
          component={Challenge}
          exact={true}
        />
        <Route path="/user/rewards" render={() => <Rewards />} exact={true} />
        <Route path="/user/success" render={() => <Success />} exact={true} />
        <Route path="/user/rewards/:id" component={SingleReward} exact={true} />
        <Route path="/user/academy" render={() => <Academy />} exact={true} />
        <Route path="/user/academy/brand/:id" component={Brand} exact={true} />
        <Route path="/user/academy/news/:id" component={Single} exact={true} />
        <Route path="/user/academy/news" render={() => <News />} exact={true} />
        <Route path="/user/products" render={() => <Products />} exact={true} />
        <Route
          path="/user/products/:id"
          component={ProductSingle}
          exact={true}
        />
        <Route path="/user/more" render={() => <More />} exact={true} />
        <Route
          path="/user/more/levels"
          render={() => <Levels />}
          exact={true}
        />
        <Route path="/user/more/badges" component={Badges} exact={true} />
        <Route
          path="/user/more/settings"
          render={() => <Settings />}
          exact={true}
        />
        <Route path="/user/more/about/:id" component={Single} exact={true} />
        <Route
          path="/user/more/badges/:id"
          component={BadgeSingle}
          exact={true}
        />
        <Route
          path="/user/more/contact"
          render={() => <Contact />}
          exact={true}
        />
        <Route
          path="/user/more/profile"
          render={() => <Profile />}
          exact={true}
        />
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        className={cx('shadow-sm', tabsHidden ? 'hidden' : '')}
        onIonTabsDidChange={handleTabWillChange}
      >
        <IonTabButton
          tab="dashboard"
          href="/user/dashboard"
          onClick={() => console.log('hee')}
        >
          <IonIcon icon={selectedTab === 'dashboard' ? home : homeOutline} />
          <IonLabel>{t('dashboard.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="rewards" href="/user/rewards">
          <IonIcon
            icon={selectedTab === 'rewards' ? bagHandle : bagHandleOutline}
          />
          <IonLabel>{t('rewards.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="academy" href="/user/academy">
          <IonIcon icon={selectedTab === 'academy' ? book : bookOutline} />
          <IonLabel>{t('academy.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="products" href="/user/products">
          <IonIcon icon={selectedTab === 'products' ? search : searchOutline} />
          <IonLabel>{t('products.tabTitle')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="more" href="/user/more">
          <IonIcon icon={selectedTab === 'more' ? grid : gridOutline} />
          <IonLabel>{t('more.tabTitle')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default User;

import { Api } from '../api';
import qs from 'qs';
import { User } from '../user';

export type SalesPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'RESOLVE'; payload: any };

export type SalesPoint = {
  id: number;
  name: string;
  address: string;
  users_count: number;
  users_points: number;
  users: User[];
};

interface Props {
  filter?: any;
}

export const getSalesPoints = async ({ filter }: Props): Promise<any> => {
  const api = new Api();

  if (filter) {
    filter = '?' + qs.stringify({ filter }, { encode: false });
  }

  try {
    const response = await api.request({ endpoint: 'sales-point' + filter });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSalesPoint = async (id: string | number): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request({
      endpoint: `sales-point/${id}`,
      auth: true,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

import React, { useReducer, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  IonContent,
  IonPage,
  IonSpinner,
  IonFooter,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { useStore } from '../../store/Registration';
import CodeVerification from '../../components/form/CodeVerification';
import { useHistory } from 'react-router-dom';
import { getInvite, GetInviteResponse } from '../../data/invite/invite.api';
import { useTranslation } from 'react-i18next';

import { inviteReducer } from '../../data/invite/invite.reducer';
import { defaultInviteState } from '../../data/invite/invite.state';

const InviteCode: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [{ status, data }, dispatch] = useReducer(
    inviteReducer,
    defaultInviteState
  );

  const setInvitation = useStore((state) => state.setInvitation);

  useEffect(() => {
    if (data) {
      setInvitation(data?.token, data.email, data.phone);
    }
  }, [data]);

  const redirectToLogin = () => {
    history.push('/auth/login');
  };

  const handleInvite = (value: string) => {
    dispatch({ type: 'FETCH' });

    getInvite({ token: value })
      .then((response: GetInviteResponse) => {
        dispatch({ type: 'RESOLVE', payload: response });
      })
      .catch(() => {
        dispatch({ type: 'REJECT' });
      });
  };

  if (status === 'success') {
    return <Redirect to="/auth/register-key-account" />;
  }

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios"></IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full px-4 py-6">
          <div className="flex flex-col justify-end flex-grow px-4">
            <h1 className="text-xl font-bold text-center text-gray-100">
              {t('auth.inviteCode.title')}
            </h1>
            <div className="mx-auto mt-8 invite-code">
              <CodeVerification
                fields={6}
                onComplete={(value) => handleInvite(value)}
                onChange={() => {
                  if (status === 'error' || status === 'loading') {
                    dispatch({ type: 'CANCEL' });
                  }
                }}
              />
            </div>
          </div>

          <div className="h-3/5">
            {status == 'loading' && (
              <IonSpinner
                name="crescent"
                className="w-full mx-auto my-8 invite"
              />
            )}
            {status == 'error' && (
              <div className="py-3 my-8 text-base text-center rounded-lg bg-red-50">
                <div className="font-bold text-red-100 ">
                  {t('auth.inviteCode.error.title')}
                </div>
                <div className="mt-1 text-red-100">
                  {t('auth.inviteCode.error.copy')}
                </div>
              </div>
            )}

            <div className="flex justify-center px-4 mt-10 space-x-2 text-base">
              <span className="text-gray-50">
                {t('auth.inviteCode.existingAccount')}
              </span>
              <span
                className="text-orange-100 cursor-pointer"
                onClick={redirectToLogin}
              >
                {t('auth.inviteCode.existingAccountCta')}
              </span>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default InviteCode;

import {
  IonPage,
  IonIcon,
  IonContent,
  IonHeader,
  IonRouterLink,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card';
import { useEffect, useState } from 'react';
import { getRewards } from '../../data/rewards/rewards.api';
import { useUser } from '../../context/User';

type Reward = {
  brand_image: string;
  description: string;
  image: string;
  name: string;
  id: number;
  value: number;
  value_sale: number | null;
  notice: string;
};

const Store = () => {
  const [rewards, setRewards] = useState<Reward[]>([]);
  const { user } = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    getRewards({ filter: '' }).then((response) => {
      setRewards(response);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <div className="py-3">
            <h1 className="pl-4 text-3xl font-bold rtl:pr-4">
              {t('rewards.tabTitle')}
            </h1>
            <div className="flex items-center pl-4 rtl:pr-4">
              <IonIcon
                src="/assets/coin-stroke.svg"
                class="ios hydrated text-3xl"
              ></IonIcon>
              <div className="ml-1 rtl:mr-1">
                <span className="font-bold">{user?.points}</span>
                <span className="ml-0.5 rtl:mr-0.5 text-sm text-gray-50">
                  {t('general.points')}
                </span>
              </div>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" fullscreen>
        <div className="px-4">
          {/* <div className="flex items-center justify-between mt-5 mb-3 ">
            <span className="text-sm text-gray-75">Razvrsti po točkah</span>
          </div> */}
          <div className="grid grid-cols-2 gap-x-2 gap-y-6">
            {rewards.map((reward, index) => {
              return (
                <IonRouterLink
                  key={reward.id}
                  routerLink={'/user/rewards/' + reward.id}
                >
                  <Card
                    image={reward?.image}
                    logo={reward?.brand_image}
                    title={reward?.name}
                    value={reward?.value}
                    userPoints={user?.points}
                    notice={reward?.notice}
                    valueSale={reward?.value_sale}
                    type="reward"
                  />
                </IonRouterLink>
              );
            })}
          </div>
        </div>

        <div className="mt-10 mb-4 text-sm text-center text-gray-50">
          {t('rewards.footerNotice')}
        </div>
        <div
          style={{
            background:
              'linear-gradient(0deg, #F7F5F3 0%, rgba(247, 245, 243, 0) 100%)',
          }}
        >
          <img src="/assets/imperial-logo.svg" className="pb-3 mx-auto mt-16" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Store;

import React, { useState, useEffect, useReducer } from 'react';

import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonPage,
  IonTitle,
  IonSlides,
  IonSlide,
  IonRouterLink,
  IonFooter,
  IonIcon,
  IonHeader,
  IonModal,
  IonToast,
  IonSpinner,
} from '@ionic/react';
import Card from '../../components/Card';
import Input from '../../components/form/Input';
import Statistic from '../../components/Statistic';
import { useTranslation } from 'react-i18next';
import { notifications, personAdd, arrowBack } from 'ionicons/icons';
import { Article, getArticles } from '../../data/article/article.api';
import { Order, getOrders } from '../../data/order/order.api';
import { useUser } from '../../context/User';
import { getInviteCode } from '../../data/invite/invite.api';
import { inviteReducer } from '../../data/invite/invite.reducer';
import { defaultInviteState } from '../../data/invite/invite.state';
import { getStats } from '../../data/stats/stats.api';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({ email: [], phone: [] });
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const [{ status }, dispatch] = useReducer(inviteReducer, defaultInviteState);
  const [stats, setStats] = useState();

  useEffect(() => {
    getArticles().then((response) => setArticles(response.splice(0, 4)));
    getStats().then((response) => setStats(response));
    getOrders({ filter: { salesman_id: user?.id } }).then((response) =>
      setOrders(response)
    );
  }, []);

  const submitInvite = () => {
    getInviteCode({ ...(email ? { email } : {}), ...(phone ? { phone } : {}) })
      .then(() => {
        dispatch({ type: 'RESOLVE' });
        setEmail('');
        setPhone('');
      })
      .catch((error) => {
        dispatch({ type: 'REJECT' });
        setErrors(error.response.errors);
      });
  };

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonTitle slot="start">
            {t('more.hello')}, {user.name.split(' ')[0]}!
          </IonTitle>
          <div slot="end" className="px-4 pt-3 pb-2">
            <IonRouterLink routerLink="/salesman/notifications">
              <IonIcon
                icon={notifications}
                class="ios hydrated text-gray-75 text-2xl"
              ></IonIcon>
            </IonRouterLink>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col h-full">
          <div className="flex-grow w-full max-w-xl mx-auto">
            <div className="px-4">
              <Statistic stats={stats} className="mt-6" />
              <div
                className="py-4 mt-6 text-center rounded-lg cursor-pointer bg-brown-20"
                onClick={(e) => setShowModal(true)}
              >
                <IonIcon
                  icon={personAdd}
                  class="ios hydrated text-orange-100 align-middle"
                ></IonIcon>
                <span className="ml-3 text-base align-middle text-brown-100">
                  {t('dashboard.register.cta')}
                </span>
              </div>

              {orders.length > 0 && (
                <div className="flex justify-between mt-10 mb-3 ">
                  <h2 className="text-xl font-bold">
                    {t('dashboard.orders.title')}
                  </h2>
                  <IonRouterLink
                    routerLink="/salesman/dashboard/orders"
                    class="ios ion-activatable hydrated rounded-md text-sm text-orange-100 bg-orange-5 py-1.5 px-2 font-medium"
                  >
                    {t('dashboard.orders.cta')}
                  </IonRouterLink>
                </div>
              )}

              <IonSlides
                options={{
                  freeMode: true,
                  slidesPerView: 2.1,
                  spaceBetween: 8,
                }}
                className="my-4 overflow-visible"
              >
                {orders.map((order, index) => {
                  return (
                    <IonSlide key={index}>
                      <Card
                        image={order.reward.image}
                        title={order.reward.name}
                        name={order.user.name}
                        address={order.sales_point.name}
                        dateOrder={new Date(
                          order.created_at
                        ).toLocaleDateString(i18n.language, {
                          month: 'long',
                          day: 'numeric',
                        })}
                        type="order"
                      />
                    </IonSlide>
                  );
                })}
              </IonSlides>

              <div className="flex justify-between mt-10 mb-3 ">
                <h2 className="text-xl font-bold">
                  {t('dashboard.news.title')}
                </h2>
                <IonRouterLink
                  class="ios ion-activatable hydrated rounded-md text-sm text-orange-100 bg-orange-5 py-1.5 px-2 font-medium"
                  routerLink="/salesman/academy/news"
                >
                  {t('dashboard.news.cta')}
                </IonRouterLink>
              </div>
              <IonSlides
                options={{
                  freeMode: true,
                  slidesPerView: 2.1,
                  spaceBetween: 8,
                }}
                className="my-4 overflow-visible"
              >
                {articles?.map((article) => {
                  return (
                    <IonSlide key={article.id} className="items-stretch h-auto">
                      <IonRouterLink
                        routerLink={`/salesman/academy/news/${article.id}`}
                      >
                        <Card
                          image={article.image}
                          title={article.title}
                          date={new Date(article.date).toLocaleDateString(
                            i18n.language,
                            {
                              month: 'long',
                              day: 'numeric',
                            }
                          )}
                          className="h-full"
                          type="article"
                        />
                      </IonRouterLink>
                    </IonSlide>
                  );
                })}
              </IonSlides>
            </div>
          </div>
          <div
            style={{
              background:
                'linear-gradient(0deg, #F7F5F3 0%, rgba(247, 245, 243, 0) 100%)',
            }}
          >
            <img
              src="/assets/imperial-logo.svg"
              className="pb-3 mx-auto mt-16"
            />
          </div>
        </div>
        <IonModal isOpen={showModal} onWillDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonIcon
                  icon={arrowBack}
                  size="large"
                  className="h-6 mr-2"
                  onClick={() => setShowModal(false)}
                ></IonIcon>
              </IonButtons>
              <IonTitle>{t('dashboard.register.title')}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="px-4 py-6">
              <Input
                value={phone}
                type="tel"
                label={t('dashboard.register.telLabel')}
                error={errors && errors.phone ? errors.phone : []}
                onChange={(e: any) => {
                  setPhone(e.target.value);
                  setErrors({ email: [], phone: [] });
                }}
              />
              <div className="flex items-center">
                <div className="flex-grow h-px bg-brown-35"></div>
                <span className="p-4 text-sm text-gray-50">
                  {t('dashboard.register.or')}
                </span>
                <div className="flex-grow h-px bg-brown-35"></div>
              </div>
              <Input
                type="email"
                value={email}
                label={t('dashboard.register.emailLabel')}
                error={errors && errors.email ? errors.email : []}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  setErrors({ email: [], phone: [] });
                }}
              />
            </div>
            <IonToast
              isOpen={status === 'error' || status === 'success'}
              onDidDismiss={() => dispatch({ type: 'CANCEL' })}
              message={status === 'error' ? 'Napaka' : 'Unikatna koda poslana'}
              duration={2000}
            />
          </IonContent>
          <IonFooter className="p-4">
            <IonButton mode="ios" expand="block" onClick={() => submitInvite()}>
              {status === 'loading' ? (
                <IonSpinner name="lines" />
              ) : (
                t('dashboard.register.submit')
              )}
            </IonButton>
          </IonFooter>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;

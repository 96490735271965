import { useHistory } from 'react-router-dom';
import { Plugins, Capacitor } from '@capacitor/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonHeader,
  IonFooter,
  IonTitle,
  IonRouterLink,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/Registration';
import Input from '../../components/form/Input';
import Checkbox from '../../components/form/Checkbox';
import { createUser } from '../../data/user';
import { login, loginResult } from '../../data/token';
import { getUser } from '../../data/user';
import { useAuth } from '../../context/Auth';
import { useUser } from '../../context/User';

const { PushNotifications } = Plugins;

const RegisterLoginInformation: React.FC = () => {
  const { Device } = Plugins;
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthToken } = useAuth();
  const { setUser } = useUser();

  const UserLoginSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('validation.email'))
      .required(t('validation.required')),
    password: Yup.string()
      .min(6, t('validation.shortPassword'))
      .max(250, t('validation.long'))
      .required(t('validation.required')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation.passwordMatch'))
      .required(t('validation.required')),
    termsChecked: Yup.boolean().oneOf([true], t('validation.required')),
  });

  const {
    token,
    phone,
    name,
    salesPoint,
    gender,
    birthDate,
    email,
    password,
    passwordConfirmation,
    termsChecked,
    pushChecked,
    notificationChecked,
    set,
  } = useStore();

  return (
    <Formik
      initialValues={{
        email: email ?? '',
        password: password ?? '',
        passwordConfirmation: passwordConfirmation ?? '',
        termsChecked: termsChecked ?? false,
      }}
      validationSchema={UserLoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);

        let formattedDate;
        if (birthDate) {
          const date = new Date(birthDate);
          formattedDate = `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`;
        }

        createUser({
          token,
          email: email ? email.trim() : email,
          name,
          phone,
          password,
          password_confirmation: passwordConfirmation,
          sales_point_id: salesPoint?.id,
          date_of_birth: formattedDate,
          push_notifications: pushChecked,
          notifications: notificationChecked,
          gender,
        })
          .then(async (response) => {
            // on successful user response, if he has selected that he wants to receive notifications
            // we show the notification dialog
            // if he accpets we register the device

            // Store notification preferences online, so we can retrieve them
            // on every login we can try and register (for new devices, new installs etc)
            if (pushChecked && Capacitor.isNative) {
              await PushNotifications.requestPermission();
              await PushNotifications.register();
            }
            if (email && password) {
              // Login the user
              const info = await Device.getInfo();
              login({
                email,
                password,
                device_name: info.model,
              })
                .then((response: loginResult) => {
                  setAuthToken(response.token);
                  return getUser();
                })
                .then((response) => {
                  setUser(response);
                })
                .finally(() => {
                  history.push('/auth/login');
                });
            } else {
              history.push('/auth/login');
            }
          })
          .catch((error) => {
            // TODO Handle server error, show a toast message
            console.log(error);
          });
      }}
    >
      {(formik) => (
        <Form>
          <IonPage>
            <IonHeader>
              <IonToolbar mode="ios">
                <IonButtons slot="start">
                  <IonBackButton
                    mode="md"
                    defaultHref="/auth/register-location"
                  />
                </IonButtons>
                <IonTitle>{t('auth.register.title')}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="flex flex-col h-full px-4 py-6">
                <h2 className="text-xl font-bold">
                  {t('auth.register.login.title')}
                </h2>
                <div>
                  <Input
                    type="text"
                    label={t('auth.register.login.emailLabel')}
                    className="mt-4"
                    error={
                      formik.touched.email && formik.errors.email
                        ? [formik.errors.email]
                        : []
                    }
                    {...formik.getFieldProps('email')}
                    onChange={(event: any) => {
                      set('email', event.target.value);
                      formik.handleChange(event);
                    }}
                  />

                  <Input
                    type="password"
                    label={t('auth.register.login.passwordLabel')}
                    className="mt-4"
                    error={
                      formik.touched.password && formik.errors.password
                        ? [formik.errors.password]
                        : []
                    }
                    {...formik.getFieldProps('password')}
                    onChange={(event: any) => {
                      set('password', event.target.value);
                      formik.handleChange(event);
                    }}
                  />

                  <Input
                    type="password"
                    label={t('auth.register.login.passwordConfirmationLabel')}
                    className="mt-4"
                    error={
                      formik.touched.passwordConfirmation &&
                      formik.errors.passwordConfirmation
                        ? [formik.errors.passwordConfirmation]
                        : []
                    }
                    {...formik.getFieldProps('passwordConfirmation')}
                    onChange={(event: any) => {
                      set('passwordConfirmation', event.target.value);
                      formik.handleChange(event);
                    }}
                  />

                  <div className="flex flex-col mt-6 space-y-5">
                    <div className="flex items-center">
                      <Checkbox
                        label="Sprejemam"
                        name="termsChecked"
                        checked={termsChecked}
                        error={
                          formik.errors.termsChecked
                            ? [formik.errors.termsChecked]
                            : []
                        }
                        onClick={() => {
                          set('termsChecked', !termsChecked);
                        }}
                        onChange={(event: any) => {
                          formik.handleChange(event);
                        }}
                      />
                      <a
                        className="ml-1 text-sm text-orange-100 "
                        href="https://imb-statamic.stage.cnj.digital/assets/documents/TobacnaLjubljana-PogojiPoslovanja.pdf"
                        target="_blank"
                      >
                        pogoje poslovanja
                      </a>
                    </div>

                    <Checkbox
                      label="Vklopi občasno potisno obveščanje"
                      name="secondaryChecked"
                      checked={pushChecked}
                      onClick={() => {
                        set('pushChecked', !pushChecked);
                      }}
                    />
                    <Checkbox
                      label="Želim prejemati SMS in/ali e-mail obvestila."
                      name="secondaryChecked"
                      checked={notificationChecked}
                      onClick={() => {
                        set('notificationChecked', !notificationChecked);
                      }}
                    />
                    {formik.errors.termsChecked && (
                      <div className="mt-2 text-sm font-bold text-red-100">
                        {formik.errors.termsChecked}
                      </div>
                    )}
                  </div>
                </div>
                <div className="pb-4" />
              </div>
            </IonContent>
            <IonFooter mode="ios">
              <div className="flex w-full px-4 py-6 bg-white">
                <IonButton
                  disabled={formik.isSubmitting}
                  onClick={() => formik.submitForm()}
                  mode="ios"
                  className="w-full"
                >
                  {t('auth.register.login.createAccountCta')}
                </IonButton>
              </div>
            </IonFooter>
          </IonPage>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterLoginInformation;
